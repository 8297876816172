<template>
  <BaseAlert title="Declined" class="tw-block" theme="danger">
    {{ event.asNotice(activeEmployment) }}
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
import OvertimeDeclinedEvent from '@/components/requests/overtime/events/OvertimeDeclinedEvent'

export default {
  name: 'DeclinedAlert',

  components: { BaseAlert },

  props: {
    overtime: {
      type: Object,
      required: true,
    },

    event: {
      type: OvertimeDeclinedEvent,
      required: true,
    },
  },
}
</script>
