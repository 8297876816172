import { flatMap, filter } from 'lodash-es'

export default {
  $_veeValidate: {
    validator: 'new',
  },

  computed: {
    valid() {
      return Object.keys(this.fields).every(field => {
        return this.fields[field] && this.fields[field].valid
      })
    },
  },

  methods: {
    async validate(scope = null) {
      const result = await this.$validator.validateAll(scope)

      return result
    },

    isClientError(status) {
      return `${status}`.startsWith('4')
    },

    validateFromResponse(content, withNotification = true) {
      if (
        !content ||
        content === 'undefined' ||
        typeof content === 'undefined'
      ) {
        if (withNotification) {
          this.serverError()
        }

        return
      }

      const { data, status } = content

      if (this.isClientError(status)) {
        this.errors.add(
          flatMap(data.errors, (value, key) => {
            return {
              field: key,
              msg: value[0],
            }
          })
        )

        if (withNotification && data.message) {
          this.error(data.message)
        }
      }
    },

    validateGraphQLFromResponse(error, withNotification = true) {
      if (error.networkError) {
        if (withNotification) {
          this.serverError()
        }

        return
      }

      const content = flatMap(error.graphQLErrors, 'extensions')
      const data = filter(content, error => error.category === 'validation')

      if (data.length === 0) {
        if (withNotification) {
          this.serverError()
        }

        return
      }

      this.errors.add(
        flatMap(data[0].validation, (value, key) => {
          return {
            field: key,
            msg: value[0],
          }
        })
      )

      if (withNotification) {
        this.validationErrors(data[0].validation)
      }
    },
  },
}
