import http from '@/plugins/http'
import FileSaver from 'file-saver'
import PaginatedDetailedAllowances from '@/models/reporting/PaginatedDetailedAllowances'
import PaginatedSummarisedAllowances from '@/models/reporting/PaginatedSummarisedAllowances'

export default class Allowances {
  static async getDetailsAllowances(params) {
    const { data } = await http.get('reports/detail-allowances', {
      params: params,
    })

    return new PaginatedDetailedAllowances(data)
  }

  static async getSummarisedAllowances(params) {
    const { data } = await http.get('reports/summary-allowances', {
      params: params,
    })

    return new PaginatedSummarisedAllowances(data)
  }

  static downloadDetailedAllowanceReport(params, fileName) {
    return this.downloadAllowanceReport(
      'reports/downloads/detail-allowances',
      params,
      fileName
    )
  }

  static downloadSummarisedAllowanceReport(params, fileName) {
    return this.downloadAllowanceReport(
      'reports/downloads/summary-allowances',
      params,
      fileName
    )
  }

  static async downloadAllowanceReport(path, params, fileName) {
    const { data } = await http.get(path, {
      params: params,
      responseType: 'blob',
    })

    return FileSaver.saveAs(new Blob([data]), fileName)
  }
}
