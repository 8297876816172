<template>
  <div class="tw-flex tw-flex-col tw-flex-1 tw-h-full">
    <div
      class="tw-p-4 tw-border tw-rounded-lg tw-bg-gray-102 tw-border-gray-220 tw-flex tw-flex-col tw-flex-1"
    >
      <ScrollableContent>
        <div class="tw-text-base tw-font-medium tw-text-black">Description</div>

        <p class="tw-mt-3 tw-text-gray-660">{{ overtime.reason }}</p>

        <OvertimeRecentUpdates
          :timeline="timeline"
          @show-more-details="$emit('show-more-details')"
        />

        <div class="tw-mt-6 tw-mb-3 tw-text-base tw-font-medium tw-text-black">
          Overtime Request Details
        </div>

        <div class="tw-space-y-2">
          <OvertimeBreakdownWrapper
            v-for="(overtimeBreakdown, index) in breakdowns"
            :key="index"
            :overtime-breakdown="overtimeBreakdown"
            :minutes-per-working-day="minutesPerWorkingDay"
            :show-edit-button="showEditButton"
            @breakdown-unit-changed="breakdownUnitChanged"
            @breakdown-amount-changed="breakdownAmountChanged"
            @close-breakdown-editor="closeBreakdownEditor"
            @update-overtime-breakdown="clickOnUpdateOvertimeBreakdown"
          />
        </div>
      </ScrollableContent>

      <div
        v-if="overtime.isReasonVisible()"
        class="tw-flex tw-flex-col tw-items-end tw-justify-end tw-mt-4"
      >
        <OvertimeBreakdownDetails
          :breakdowns="breakdowns"
          :minutes-per-working-day="minutesPerWorkingDay"
          class="tw-text-right"
        />

        <div class="tw-text-thin tw-text-gray-880">
          {{ overtime.title }}
        </div>
      </div>
    </div>

    <AllowanceIncrementMonitor
      v-if="overtime.isReasonVisible() && !isAllowanceCalculating"
      :allowances="overtimeAllowances.summary"
      class="tw-mt-6 tw-mb-4"
      :status="overtime.status"
    />
  </div>
</template>

<script>
import { sortBy } from 'lodash-es'
import ScrollableContent from '@/components/ScrollableContent'
import OvertimeAllowance from '@/models/overtime/OvertimeAllowance'
import Timeline from '@/components/requests/overtime/events/Timeline'
import OvertimeRecentUpdates from '@/components/requests/overtime/OvertimeRecentUpdates'
import EmploymentAllowanceCollection from '@/models/reporting/EmploymentAllowanceCollection'
import OvertimeBreakdownDetails from '@/components/requests/overtime/OvertimeBreakdownDetails'
import OvertimeBreakdownWrapper from '@/components/requests/overtime/OvertimeBreakdownWrapper'
import AllowanceIncrementMonitor from '@/components/requests/overtime/AllowanceIncrementMonitor'

export default {
  name: 'OvertimeSummaryTab',

  components: {
    ScrollableContent,
    OvertimeBreakdownWrapper,
    AllowanceIncrementMonitor,
    OvertimeRecentUpdates,
    OvertimeBreakdownDetails,
  },

  props: {
    overtime: {
      type: Object,
      required: true,
    },

    timeline: {
      type: Timeline,
      required: true,
    },

    employmentAllowances: {
      type: EmploymentAllowanceCollection,
      required: true,
    },

    showEditButton: {
      type: Boolean,
      default: false,
    },

    isAllowanceCalculating: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    breakdowns() {
      return sortBy(this.overtime.breakdowns, 'date')
    },

    overtimeAllowances() {
      return new OvertimeAllowance([this.overtime], this.employmentAllowances)
    },

    minutesPerWorkingDay() {
      return this.overtime.minutes_per_working_day
    },
  },

  methods: {
    breakdownUnitChanged(editedBreakdown) {
      this.updateBreakdown(editedBreakdown)
    },

    breakdownAmountChanged(editedBreakdown) {
      this.updateBreakdown(editedBreakdown)
    },

    closeBreakdownEditor(previousBreakdown) {
      this.updateBreakdown(previousBreakdown)
    },

    updateBreakdown(editedBreakdown) {
      let oldBreakdownIndex = this.overtime.breakdowns.findIndex(breakdown =>
        breakdown.date.isSame(editedBreakdown.date)
      )

      this.overtime.breakdowns.splice(oldBreakdownIndex, 1)
      this.overtime.breakdowns = [...this.overtime.breakdowns, editedBreakdown]
    },

    async clickOnUpdateOvertimeBreakdown(previousBreakdown) {
      const confirmed = await this.confirm(
        'Are you sure you want to update overtime?',
        'Confirm update'
      )

      if (!confirmed) {
        this.updateBreakdown(previousBreakdown)
        return
      }

      this.updateOvertimeBreakdown()
    },

    updateOvertimeBreakdown() {
      this.$emit('update-overtime-breakdown', this.overtime.breakdowns)
    },
  },
}
</script>
