<template>
  <div
    class="tw-border tw-rounded-lg tw-flex tw-px-4 tw-py-3"
    :class="themeClasses"
  >
    <div class="tw-w-full tw-flex tw-items-center tw-space-x-3">
      <div
        :class="title ? 'tw-mt-2' : 'tw-items-center'"
        class="tw-flex tw-h-full"
      >
        <SvgIcon
          class="tw-w-5 tw-h-5"
          :class="iconClasses"
          :name="iconName"
          data-cy="alert-icon-image"
        />
      </div>

      <div class="tw-flex tw-flex-1">
        <div>
          <div
            v-if="title"
            class="tw-font-medium tw-mb-2 tw-mt-1"
            data-cy="alert-title"
            :class="titleClasses"
          >
            {{ title }}
          </div>

          <div :class="bodyClasses" data-cy="alert-message">
            <slot></slot>
          </div>
        </div>
      </div>

      <slot name="right-content"></slot>
    </div>
  </div>
</template>

<script>
const THEMES = {
  success: {
    icon: 'icon-check-circle',
    background: ['tw-bg-green-100'],
    border: ['tw-border-green-400'],
    iconColor: ['tw-text-green-600'],
    title: ['tw-text-green-600'],
    body: ['tw-text-green-600'],
  },

  danger: {
    icon: 'icon-info-circle',
    background: ['tw-bg-red-100'],
    border: ['tw-border-red-300'],
    iconColor: ['tw-text-red-700'],
    title: ['tw-text-red-800'],
    body: ['tw-text-red-800'],
  },

  warning: {
    icon: 'icon-exclamation-triangle',
    background: ['tw-bg-yellow-100'],
    border: ['tw-border-yellow-550'],
    iconColor: ['tw-text-brown-500'],
    title: ['tw-text-brown-500'],
    body: ['tw-text-brown-500'],
  },

  neutral: {
    icon: 'icon-exclamation-triangle',
    background: ['tw-bg-gray-100'],
    border: ['tw-border-gray-300'],
    iconColor: ['tw-text-gray-600'],
    title: ['tw-text-gray-700'],
    body: ['tw-text-gray-600'],
  },

  info: {
    icon: 'icon-info-circle',
    background: ['tw-bg-purple-100'],
    border: ['tw-border-purple-300'],
    iconColor: ['tw-text-purple-600'],
    title: ['tw-text-purple-700'],
    body: ['tw-text-purple-700'],
  },
}

export default {
  name: 'BaseAlert',

  props: {
    icon: {
      type: String,
      default: null,
    },

    theme: {
      type: String,
      default: 'success',
    },

    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    iconName() {
      return this.icon || THEMES[this.theme].icon
    },

    themeClasses() {
      return [...THEMES[this.theme].background, ...THEMES[this.theme].border]
    },

    iconClasses() {
      return THEMES[this.theme].iconColor
    },

    titleClasses() {
      return THEMES[this.theme].title
    },

    bodyClasses() {
      return THEMES[this.theme].body
    },
  },
}
</script>
