<template>
  <BaseAlert class="tw-mt-2 tw-px-3 tw-py-3" theme="warning">
    You are requesting days that are in the past.
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'

export default {
  name: 'RequestLeaveOnPastDateWarning',
  components: {
    BaseAlert,
  },
}
</script>
