<template>
  <div class="tw-flex tw-items-top">
    <div class="tw-space-x-1 tw-mt-1">
      <span v-if="locale === 'en_US'">
        {{ date.format('ddd MMM DD YYYY') }}
      </span>

      <span v-else>
        {{ date.format('ddd DD MMM YYYY') }}
      </span>

      <span v-if="in24HourClock"> {{ date.format('HH:mm') }} </span>

      <span v-else> {{ date.format('LT') }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttachmentDate',

  props: {
    date: {
      type: Object,
      required: true,
    },
  },

  computed: {
    locale() {
      return this.authUser.locale
    },

    in24HourClock() {
      return this.authUser.settings.in_24_hour_clock
    },
  },
}
</script>
