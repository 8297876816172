import moment from 'moment-timezone'

export default {
  methods: {
    canCancel(leave, employment) {
      return (
        !['CANCELED', 'REJECTED'].includes(leave.status) &&
        (employment.is_admin ||
          (employment.id === leave.owner_id && this.isFuture(leave)) ||
          this.canApprove(leave, employment))
      )
    },

    canApprove(leave, employment) {
      return (
        !!employment.subordinates &&
        employment.subordinates.some(employment => {
          return employment.id == leave.owner_id
        })
      )
    },

    isFollower(leave, employment) {
      return employment.followings.some(employment => {
        return employment.id === leave.owner.id
      })
    },

    isFuture(leave) {
      return moment.utc(leave.from).isAfter(moment().utc(true))
    },
  },
}
