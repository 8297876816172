import http from '@/plugins/http'
import ProratedAllowanceReport from '@/models/employment/ProratedAllowanceReport'

export default class Allowances {
  static recalculateCarryover(params) {
    return http.post(`carryover/recalculate`, params)
  }

  static async prorate(params) {
    const { data } = await http.post(`allowances/prorate`, params)

    return new ProratedAllowanceReport(data)
  }

  static updateEmploymentAllowanceBreakdown(breakdown, params) {
    return http.put('employment-allowance-breakdowns/' + breakdown.id, params)
  }
}
