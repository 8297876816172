<template>
  <BaseAlert theme="info" class="tw-mt-2">
    <!-- eslint-disable vue/no-v-html -->
    <span v-html="employmentAllowanceHasNoEffectText"></span>
    <!-- eslint-enable -->
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
import EmploymentAllowanceHasNoEffectText from '@/components/requests/EmploymentAllowanceHasNoEffectText'

export default {
  name: 'UnlimitedAllowanceTypeInfo',
  components: {
    BaseAlert,
  },

  props: {
    leaveType: {
      type: Object,
      required: true,
    },
  },

  computed: {
    employmentAllowanceHasNoEffectText() {
      const text = new EmploymentAllowanceHasNoEffectText(this.leaveType)
        .getText()
        .split('**')

      return `${text[0]} <span class="tw-font-semibold">${text[1]}</span> ${text[2]}`
    },
  },
}
</script>
