export default class CancelConfirmation {
  constructor(request, actor) {
    this.request = request
    this.actor = actor
  }

  get message() {
    const action = this.request.canApprove(this.actor) ? 'decline' : 'cancel'

    return {
      title: `Confirm ${action}`,
      body: `Are you sure you want to ${action} ${this._requestOwnerName()} request?`,
    }
  }

  _requestOwnerName() {
    if (this.request.owner_id === this.actor.id) {
      return 'your'
    }

    return `${this.request.getOwnerFullName()}'s`
  }
}
