import RequestMicrocopy from '@/components/requests/RequestMicrocopy'

export default class OvertimeRequestMicrocopy extends RequestMicrocopy {
  constructor(requester, owners) {
    super(owners, requester)
  }

  get microcopy() {
    if (this._canRequestBeAutomaticallyApproved()) {
      return 'Request will be automatically approved'
    }

    if (this._isRequesterIsSuperiorOfSomeOwners()) {
      return 'Request will be sent to the appropriate approvers'
    }

    return `Request will be sent to **${this._superiorNames()}** for approval`
  }
}
