const RESET_STATES = 'RESET_STATES'

const SET_ALLOWANCES = 'SET_ALLOWANCES'
const SET_CURRENT_ALLOWANCE = 'SET_CURRENT_ALLOWANCE'

const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'

export default {
  RESET_STATES,
  SET_ALLOWANCES,
  SET_CURRENT_ALLOWANCE,
  SET_NOTIFICATIONS,
}
