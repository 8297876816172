<template>
  <BaseAlert icon="time" theme="info" class="tw-flex-1 tw-mr-2">
    {{ deductionString }}
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
import pluralize from 'pluralize'

export default {
  name: 'LeaveBreakdownDeductionInfo',

  components: {
    BaseAlert,
  },

  props: {
    deduction: {
      type: Object,
      required: true,
    },

    interval: {
      type: Object,
      required: true,
    },
  },

  computed: {
    deductionString() {
      if (this.isNoDeduction) {
        return '0 Minutes (Break)'
      }

      let deductionTexts = []
      if (this.deduction.hours > 0) {
        deductionTexts.push(
          `${this.deduction.hours} ${pluralize('hrs', this.deduction.hours)}`
        )
      }

      if (this.deduction.minutes > 0) {
        deductionTexts.push(`${this.deduction.minutes} mins`)
      }

      let deductionText = deductionTexts.join(' and ').trim()

      if (this.hasInterval) {
        deductionText += ' (+ Break)'
      }

      return deductionText
    },

    isNoDeduction() {
      return this.deduction.hours === 0 && this.deduction.minutes === 0
    },

    hasInterval() {
      return this.interval.hours > 0 || this.interval.minutes > 0
    },
  },
}
</script>
