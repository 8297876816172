<template>
  <div
    class="tw-border tw-border-gray-220 tw-bg-white tw-rounded-lg tw-p-3 tw-mb-2"
  >
    <div class="tw-flex tw-items-center tw-justify-between">
      <RequestBreakdownDate :date="breakdown.date" />

      <NonWorkingDayInfo v-if="breakdown.isNonWorkingDay" />

      <HolidayInfo
        v-if="breakdown.isHoliday"
        :holiday="breakdown.holidayInformation"
      />

      <div v-if="breakdown.isWorkingDay">
        <vSelect
          v-model="selectedDuration"
          class="ld_v-select rtl"
          :options="selectableDurations"
          :multiple="false"
          :searchable="false"
          :show-labels="false"
          :allow-empty="false"
          track-by="key"
          label="label"
          placeholder=""
        />
      </div>
    </div>

    <LeaveBreakdownCustomOptions
      v-if="breakdown.isWorkingDay && breakdown.activeDuration.isCustom"
      :from="breakdown.activeDuration.from"
      :to="breakdown.activeDuration.to"
      :employments="employmentsWithWorkingSchedule"
      :is-single-employment-request="true"
      :breakdown-duration="breakdown.rawBreakdown.duration"
      class="tw-mt-2"
      @time-updated="handleTimeUpdate"
    />

    <div
      v-for="(message, index) in breakdownMessages"
      :key="index"
      :ref="message.isLeaveLimitReached ? 'leaveLimitExceededMessage' : ''"
    >
      <ArrowAlert class="tw-mt-2" :theme="message.theme" :text="message.text">
        <UserAvatarList
          v-if="message.isLeaveLimitReached"
          class="tw-ml-3"
          :users="message.owners"
        />
      </ArrowAlert>
    </div>

    <div ref="overlapLeavePanel" class="tw-hidden">
      <div
        class="tw-items-start tw-p-3 tw-border tw-rounded-md tw-border-gray-660  tw-bg-gray-660 leave-container"
      >
        <OverlapLeave
          :overlap-leaves="overlapLeaves"
          @show-overlap-leave-details="showOverlapLeaveDetails"
        />
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import vSelect from 'vue-multiselect'
import FormatDate from '@/mixins/FormatDate'
import ArrowAlert from '@/components/ArrowAlert'
import UserAvatarList from '@/components/UserAvatarList'
import HolidayInfo from '@/components/requests/leave/HolidayInfo'
import OverlapLeave from '@/components/requests/leave/OverlapLeave'
import NonWorkingDayInfo from '@/components/requests/leave/NonWorkingDayInfo'
import RequestBreakdownDate from '@/components/requests/RequestBreakdownDate'
import LeaveBreakdownCustomOptions from '@/components/requests/leave/LeaveBreakdownCustomOptions'
import Breakdown from '@/models/leave/drafting/breakdowns/Breakdown'

const DURATION_MAP = {
  full_day: {
    key: 'full_day',
    label: 'All day',
  },

  first_session: {
    key: 'first_session',
    label: 'Half day - Morning',
  },

  second_session: {
    key: 'second_session',
    label: 'Half day - Afternoon',
  },

  custom: {
    key: 'custom',
    label: 'Other',
  },
}

export default {
  name: 'EditLeaveBreakdown',

  components: {
    vSelect,
    ArrowAlert,
    HolidayInfo,
    OverlapLeave,
    UserAvatarList,
    NonWorkingDayInfo,
    RequestBreakdownDate,
    LeaveBreakdownCustomOptions,
  },

  mixins: [FormatDate],

  props: {
    breakdown: {
      type: Breakdown,
      required: true,
    },

    owner: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedDuration: DURATION_MAP[this.breakdown.activeDuration.name],
      overlapTooltip: null,
    }
  },

  computed: {
    employmentsWithWorkingSchedule() {
      return [
        {
          id: this.owner.id,
          workingSchedule: {
            id: this.owner.available_working_schedule.id,
            breakdowns: this.owner.available_working_schedule
              .schedule_breakdowns,
          },
        },
      ]
    },

    breakdownMessages() {
      return this.breakdown.messages.filter(
        m => !m.isHoliday && !m.isInvalidBreakdown
      )
    },

    overlapLeaves() {
      return (
        this.breakdownMessages.find(message => message.isLeaveLimitReached)
          ?.leaves ?? []
      )
    },

    selectableDurations() {
      return this.breakdown.availableDurations.map(
        duration => DURATION_MAP[duration.name]
      )
    },
  },

  watch: {
    selectedDuration(selectedDuration) {
      const breakdown = this.breakdown.clone()
      breakdown.activeDuration = selectedDuration.key

      this.$emit('duration-selected', breakdown)
    },
  },

  mounted() {
    if (this.$refs.leaveLimitExceededMessage) {
      this.overlapTooltip = tippy.one(this.$refs.leaveLimitExceededMessage, {
        html: this.$refs.overlapLeavePanel.firstChild,
        theme: 'dark',
        arrow: true,
        interactive: true,
        placement: 'top',
        delay: [400, 0],
        distance: 1,
        interactiveBorder: 15,
      })

      this.$once('hook:beforeDestroy', () => {
        this.overlapTooltip?.destroy()
      })
    }
  },

  methods: {
    showOverlapLeaveDetails(leaveKey) {
      this.overlapTooltip?.hide()

      this.$emit('show-overlap-leave-details', leaveKey)
    },

    handleTimeUpdate(updatedTime) {
      const breakdown = this.breakdown.clone()
      breakdown.updateCustomDuration(updatedTime)

      this.$emit('duration-selected', breakdown)
    },
  },
}
</script>
