import moment from 'moment/moment'
import { groupBy, orderBy } from 'lodash-es'
import CommentAddedEvent from '@/components/requests/overtime/events/CommentAddedEvent'
import CommentDeletedEvent from '@/components/requests/overtime/events/CommentDeletedEvent'
import AttachmentAddedEvent from '@/components/requests/overtime/events/AttachmentAddedEvent'
import OvertimeUpdatedEvent from '@/components/requests/overtime/events/OvertimeUpdatedEvent'
import OvertimeApprovedEvent from '@/components/requests/overtime/events/OvertimeApprovedEvent'
import OvertimeDeclinedEvent from '@/components/requests/overtime/events/OvertimeDeclinedEvent'
import OvertimeCancelledEvent from '@/components/requests/overtime/events/OvertimeCancelledEvent'
import OvertimeRequestedEvent from '@/components/requests/overtime/events/OvertimeRequestedEvent'
import CommentRestoredEvent from '@/components/requests/overtime/events/CommentRestoredEvent'

export default class Timeline {
  constructor(timeline) {
    this.timeline = timeline

    this._createEvents()
  }

  getLastEvent() {
    return this.events[this.eventCount - 1]
  }

  getRecentEvents(count) {
    return this.events.slice(count * -1)
  }

  get eventCount() {
    return this.events.length
  }

  getEventsGroupedByDate() {
    return Object.entries(
      groupBy(this.events, event =>
        moment(event.createdAt).format('dddd DD MMMM YYYY')
      )
    ).map(([date, events]) => {
      return {
        date: date,
        events: orderBy(events, ['createdAt'], ['asc']),
      }
    })
  }

  getLatestOvertimeEvent() {
    const overtimeEvents = this.getOvertimeEvents()

    return overtimeEvents[overtimeEvents.length - 1]
  }

  getOvertimeEvents() {
    return this.events.filter(event => event.getResourceType() === 'overtime')
  }

  getApprovedEvent() {
    return this.events.find(
      event =>
        event.getName() === 'OvertimeWasApproved' ||
        event.getName() === 'SoleSuperiorOvertimeRequested'
    )
  }

  _createEvents() {
    const eventMap = {
      overtime_was_requested: OvertimeRequestedEvent,
      one_of_bulk_overtime_was_requested: OvertimeRequestedEvent,
      overtime_was_updated: OvertimeUpdatedEvent,
      overtime_comment_was_added: CommentAddedEvent,
      overtime_was_approved: OvertimeApprovedEvent,
      one_of_bulk_overtime_was_approved: OvertimeApprovedEvent,
      overtime_attachment_was_added: AttachmentAddedEvent,
      overtime_was_cancelled: OvertimeCancelledEvent,
      overtime_was_rejected: OvertimeDeclinedEvent,
      overtime_comment_was_deleted: CommentDeletedEvent,
      overtime_comment_was_restored: CommentRestoredEvent,
    }

    this.events = []

    this.timeline.forEach(event => {
      this.events.push(new eventMap[event.event_class](event))
    })
  }
}
