<template>
  <div class="tw-flex tw-flex-col tw-flex-1">
    <div class="tw-flex tw-flex-1 tw-text-gray-700">
      <ScrollableContent>
        <OvertimeBreakdownInput
          v-for="breakdown in overtimeBreakdowns"
          :key="breakdown.key"
          class="tw-mb-3"
          :breakdown="breakdown"
          :allowance-unit="allowanceUnit"
          :minutes-per-working-day="minutesPerWorkingDay"
          @breakdown-unit-changed="breakdownUnitChanged"
          @breakdown-amount-changed="breakdownAmountChanged"
        />
      </ScrollableContent>
    </div>

    <div class="tw-mt-4 tw-flex tw-flex-col tw-items-end tw-justify-end">
      <OvertimeBreakdownDetails
        :breakdowns="overtimeBreakdowns"
        class="tw-text-right"
      />

      <div class="tw-text-thin tw-text-gray-880">
        {{ activeCompany.toilTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import OvertimeBreakdownInput from '@/components/requests/overtime/OvertimeBreakdownInput'
import { uniqueId, sortBy } from 'lodash-es'
import OvertimeBreakdownDetails from '@/components/requests/overtime/OvertimeBreakdownDetails'
import ScrollableContent from '@/components/ScrollableContent'

export default {
  name: 'OvertimeBreakdowns',

  components: {
    ScrollableContent,
    OvertimeBreakdownInput,
    OvertimeBreakdownDetails,
  },

  props: {
    breakdowns: {
      type: Array,
      required: true,
    },

    allowanceUnit: {
      type: String,
      required: true,
    },

    minutesPerWorkingDay: {
      type: Number,
      required: true,
    },

    isTabsVisible: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    sortedBreakdowns() {
      return sortBy(this.breakdowns, 'date')
    },

    overtimeBreakdowns() {
      return this.sortedBreakdowns.map(breakdown => {
        breakdown.key = uniqueId('overtime_breakdown')

        return breakdown
      })
    },
  },

  methods: {
    breakdownUnitChanged(breakdown) {
      this.$emit('breakdown-unit-changed', breakdown)
    },

    breakdownAmountChanged(breakdown) {
      this.$emit('breakdown-amount-changed', breakdown)
    },
  },
}
</script>
