import Notification from '@/components/notifications/Notification'

export default class LeaveNotification extends Notification {
  constructor(notification) {
    super(notification)
  }

  navigate(router, query) {
    router.push({
      query: {
        ...query,
        ...this.routeQuery(),
      },
    })
  }

  getCommenter() {
    return this.notification.data.commentator
  }

  getType() {
    return 'leave-request'
  }

  _getResourceKey() {
    return this.notification.data.leave_id
  }
}
