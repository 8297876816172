<template>
  <div class="tw-flex tw-items-center">
    <BackgroundIcon :name="icon" />

    <div
      class="tw-ml-4 tw-flex tw-items-center tw-font-medium tw-text-xl tw-text-gray-660"
    >
      <span>{{ title }}</span>

      <span
        v-if="isMultipleEmploymentRequest || !activeEmploymentIsRequestOwner"
        class="tw-ml-1"
      >
        for
      </span>

      <template
        v-if="isSingleEmploymentRequest && !activeEmploymentIsRequestOwner"
      >
        <span class="tw-font-semibold tw-ml-1">
          {{ selectedEmploymentFullName }}
        </span>
      </template>

      <UserAvatarList
        v-if="isMultipleEmploymentRequest"
        class="tw-ml-2"
        :users="selectedEmployments"
        @show-more-users="$emit('show-more-employments')"
      />
    </div>
  </div>
</template>

<script>
import BackgroundIcon from '@/components/BackgroundIcon'
import UserAvatarList from '@/components/UserAvatarList'
import { head } from 'lodash-es'

export default {
  name: 'RequestPanelHeader',

  components: { BackgroundIcon, UserAvatarList },

  props: {
    icon: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    selectedEmployments: {
      type: Array,
      required: true,
    },
  },

  computed: {
    selectedEmployment() {
      return head(this.selectedEmployments)
    },

    selectedEmploymentFullName() {
      return this.selectedEmployment?.full_name
    },

    isSingleEmploymentRequest() {
      return this.selectedEmployments.length === 1
    },

    isMultipleEmploymentRequest() {
      return this.selectedEmployments.length > 1
    },

    activeEmploymentIsRequestOwner() {
      if (!this.selectedEmployment) {
        return false
      }

      return this.selectedEmployment.id === this.activeEmployment.id
    },
  },
}
</script>
