import {
  HTTP_STATUS_AUTHENTICATION_EXPIRED,
  HTTP_STATUS_UNAUTHORIZED,
} from '@/plugins/http'

export class SentryLogger {
  static handle(env, event) {
    if (env.NODE_ENV !== 'production') {
      return null
    }

    const lastBreadcrumb = event.breadcrumbs[event.breadcrumbs.length - 1]

    if (
      lastBreadcrumb.category === 'xhr' &&
      [HTTP_STATUS_UNAUTHORIZED, HTTP_STATUS_AUTHENTICATION_EXPIRED].includes(
        lastBreadcrumb.data.status_code
      )
    ) {
      return null
    }

    return event
  }
}
