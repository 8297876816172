import LimitedAllowance from '@/models/employment/LimitedAllowance'
import UnlimitedAllowance from '@/models/employment/UnlimitedAllowance'
import OvertimeAllowanceSummary from '@/models/overtime/OvertimeAllowanceSummary'

export default class OvertimeAllowance {
  constructor(overtimes, employmentAllowances) {
    this.overtimes = overtimes
    this.employmentAllowances = employmentAllowances

    this._mapEmploymentAllowances()
    this._setAllowanceSummary()
  }

  _mapEmploymentAllowances() {
    this.items = this.overtimes.map(overtime => {
      let employmentAllowance = this.employmentAllowances
        .all()
        .find(
          employmentAllowance =>
            employmentAllowance.employment().id === overtime.owner.id
        )

      if (employmentAllowance) {
        return new LimitedAllowance(
          overtime,
          employmentAllowance.remainingToBook()
        )
      }

      return new UnlimitedAllowance(overtime)
    })
  }

  get all() {
    return this.items
  }

  _setAllowanceSummary() {
    this.summary = new OvertimeAllowanceSummary(this.all)
  }
}
