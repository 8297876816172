import moment from 'moment-timezone'
import dateLocaliseFormatMap from './localiseDateFormats.js'

export default {
  data() {
    return {
      dateFormatMap: {
        ...dateLocaliseFormatMap,
      },
    }
  },

  methods: {
    formatDateFromIsoToDayReadableDay(dateString, timezone = null) {
      return this.toMoment(dateString, timezone).format('dddd')
    },

    formatDateFromIsoToDayReadableNumberMonthYear(dateString, timezone = null) {
      return this.toMoment(dateString, timezone).format(
        this.toLocaleFormat('dayReadableNumberMonthYear')
      )
    },

    formatDateFromIsoToDayReadableDayNumberMonthYear(
      dateString,
      timezone = null
    ) {
      return this.toMoment(dateString, timezone).format('dddd DD MMMM YYYY')
    },

    formatDateFromIsoToDayReadableDayNumberShortMonthYear(
      dateString,
      timezone = null
    ) {
      return this.toMoment(dateString, timezone).format(
        this.toLocaleFormat('dayReadableDayNumberShortMonthYear')
      )
    },

    formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
      dateString,
      timezone = null
    ) {
      return this.toMoment(dateString, timezone).format(
        this.toLocaleFormat('dayReadableShortDayNumberShortMonthYear')
      )
    },

    formatDateFromIsoToDayReadableShortDayNumberShortMonth(
      dateString,
      timezone = null
    ) {
      return this.toMoment(dateString, timezone).format(
        this.toLocaleFormat('dayReadableShortDayNumberShortMonth')
      )
    },

    formatDateFromIsoToDayReadableShortDayNumberWithPlaceMonth(
      dateString,
      timezone = null
    ) {
      return this.toMoment(dateString, timezone).format(
        this.toLocaleFormat('dayReadableShortDayNumberWithPlaceMonth')
      )
    },

    formatDateFromIsoToNumberDayNumberMonthYearWithSlashAndReadableDay(
      dateString,
      timezone = null
    ) {
      return this.toMoment(dateString, timezone).format(
        this.toLocaleFormat('numberDayNumberMonthYearWithSlashAndReadableDay')
      )
    },

    formatDateFromIsoToDayReadableShortDayNumberShortMonthNumberYearNumber(
      dateString,
      timezone = null
    ) {
      return this.toMoment(dateString, timezone).format('DD-MM-YYYY')
    },

    formatDateFromIsoToDayReadableDayNumberShortMonth(
      dateString,
      timezone = null
    ) {
      return this.toMoment(dateString, timezone).format('ddd DD MMM')
    },

    formatDateFromIsoToDayReadableDayNumberMonthNumberYear(
      dateString,
      timezone = null
    ) {
      return this.toMoment(dateString, timezone).format(
        'dddd [-] DD[-]M[-]YYYY'
      )
    },

    formatDateFromIsoToHourMinutes(dateString, timezone = null) {
      return this.toMoment(dateString, timezone).format(
        this.toLocaleFormat('hourMinutes')
      )
    },

    formatShortDateFromIsoToHumanReadable(dateString, timezone = null) {
      return this.toMoment(dateString, timezone).format(
        this.toLocaleFormat('shortDateHumanReadable')
      )
    },

    formatShortDateFromIsoToHumanReadableWithAt(dateString, timezone = null) {
      return this.toMoment(dateString, timezone).format(
        this.toLocaleFormat('shortDateHumanReadableWithAt')
      )
    },

    isoStringToWeekday(dateString, timezone = null) {
      return this.toMoment(dateString, timezone).format('dddd')
    },

    formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
      dateString,
      timezone = null
    ) {
      return this.toMoment(dateString, timezone).format(
        this.toLocaleFormat(
          'formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash'
        )
      )
    },

    getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash() {
      return this.toLocaleFormat(
        'formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash'
      )
    },

    getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithDash() {
      return this.toLocaleFormat(
        'formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithDash'
      )
    },

    getFormatOfDayReadableDayNumberShortMonthYear() {
      return this.toLocaleFormat('dayReadableDayNumberShortMonthYear')
    },

    toMoment(date, timezone = null) {
      if (timezone == null) return moment(date)

      if (this.inActiveEmploymentTimezone) {
        return moment.tz(date, this.activeEmployment.timezone)
      }

      return moment.tz(date, timezone)
    },

    momentParseZone(date, timezone) {
      if (this.inActiveEmploymentTimezone) {
        return moment.parseZone(date, this.activeEmployment.timezone)
      }
      return moment.parseZone(date, timezone)
    },

    toDateTimeString(moment, format = 'YYYY-MM-DDTHH:mm:ss') {
      return moment.format(format)
    },

    toLocaleFormat(format) {
      const locale = this.authUser.locale === 'en_US' ? 'us' : 'row'

      const clockType = this.authUser.settings.in_24_hour_clock
        ? 'in24Hour'
        : 'in12Hour'

      const dateOnly = this.dateFormatMap[format]['dateOnly']

      if (dateOnly) {
        return this.dateFormatMap[format][locale]
      }

      return this.dateFormatMap[format][locale][clockType]
    },
  },
}
