import WorkingSchedule from '@/components/working-schedule/WorkingSchedule'

export default class Company {
  constructor(rawCompany) {
    Object.keys(rawCompany).forEach(property => {
      this[property] = rawCompany[property]
    })
  }

  getKey() {
    return this.id
  }

  get minutesPerWorkingDay() {
    return this.minutes_per_working_day
  }

  get allowanceUnitIsDays() {
    return this.allowance_unit_is_days
  }

  get activeWorkingSchedule() {
    if (this.hasOwnProperty('active_working_schedule')) {
      return new WorkingSchedule(this.active_working_schedule)
    }

    return null
  }

  get toilTitle() {
    return (
      this.time_off_in_lieu_visibility_settings?.title ||
      'Earned Time Off In Lieu'
    )
  }

  get hasProperSubscription() {
    return (
      this.employments_count <= 5 ||
      (this.isSubscribed &&
        this.subscription.available_to_use &&
        this.employments_count <= this.numberOfSeats)
    )
  }

  get isSubscribed() {
    return this.subscription !== null
  }

  get subscription() {
    return this.current_subscription
  }

  get numberOfSeats() {
    if (!this.isSubscribed) return 5
    return this.isSubscribed && this.subscription.quantity
  }

  get allowanceAccrualEnabled() {
    return this.allowance_accrual_enabled
  }
}
