import vSelect from 'vue-multiselect'
import { find } from 'lodash-es'

export default {
  components: { vSelect },

  props: {
    value: {
      type: [Object, String],
      default: null,
    },

    options: {
      type: Array,
      default: () => [],
    },

    reduce: {
      type: Function,
      default: option => option,
    },
    cypressAttribute: {
      type: String,
      default: 'employee',
    },
  },

  computed: {
    selectedOption: {
      get() {
        let id = this.value

        if (id instanceof Object) {
          id = this.value.id
        }

        return find(this.options, { id: id })
      },

      set(value) {
        this.$emit('input', this.reduce(value))
      },
    },
  },
}
