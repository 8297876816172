import http from '@/plugins/http'

export default class Approvers {
  static find(params) {
    return http.get('employments', {
      params: {
        ...params,
        is_approver: true,
      },
    })
  }
}
