<template>
  <div>
    <KeyboardShortcuts />
  </div>
</template>

<script>
import KeyboardShortcuts from '@/components/help/KeyboardShortcuts'

export default {
  name: 'HelpModal',

  components: { KeyboardShortcuts },
}
</script>
