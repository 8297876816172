import { uniqBy } from 'lodash-es'

export default class Warning {
  constructor(warning) {
    this.warning = this._removeDraftedLeaveFromLeaveLimitReachedWarning(warning)
  }

  get theme() {
    return 'warning'
  }

  get isHoursPerWorkingDayExceeded() {
    return this.warning.code === 'hours_per_working_day_exceeded'
  }

  get isLeaveLimitReached() {
    return this.warning.code === 'leave_limit_reached'
  }

  get text() {
    if (this.isHoursPerWorkingDayExceeded) {
      return 'Exceeds hours per working day.'
    }

    if (this.isLeaveLimitReached) {
      return 'Exceeds number of people allowed on leave.'
    }

    return ''
  }

  get hasOwners() {
    return this.owners.length
  }

  get owners() {
    return uniqBy(
      this.leaves.map(leave => leave.owner),
      'id'
    )
  }

  get leaves() {
    return this.warning.leaves
  }

  get nonvisibleEmploymentCount() {
    return this.warning.nonvisible_employment_count
  }

  _removeDraftedLeaveFromLeaveLimitReachedWarning(warning) {
    if (warning.code !== 'leave_limit_reached') {
      return warning
    }

    const nonDraftedLeave = warning.leaves.filter(
      leave => leave.status !== 'drafted'
    )

    return {
      ...warning,
      leaves: nonDraftedLeave,
    }
  }
}
