<template>
  <div>
    <transition name="page" mode="out-in">
      <router-view :key="$route.fullPath" />
    </transition>
    <v-dialog :class="'prompt-dialog'" :click-to-close="false" />
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
}
</script>
