import http from '@/plugins/http'
import Timeline from '@/components/requests/overtime/events/Timeline'
import Overtime from '@/models/overtime/Overtime'

export default class Overtimes {
  static request(payload) {
    return http.post('overtimes', payload)
  }

  static draft(payload) {
    return http.post('overtimes/draft', payload)
  }

  static saveAttachment(key, payload) {
    return http.post(`overtimes/${key}/attachments`, payload)
  }

  static saveComment(overtime, payload) {
    const overtimeCommentLink = this._getLink(overtime, 'add_comments')

    const method = overtimeCommentLink.type.toLowerCase()

    return http[method](overtimeCommentLink.href, payload)
  }

  static async fetch(overtimeKey, company) {
    const overtimeSelfLink = this._getLink(overtimeKey, 'self')

    const method = overtimeSelfLink.type.toLowerCase()

    const { data } = await http[method](overtimeSelfLink.href, {
      params: {
        company: company.id,
      },
    })

    return new Overtime(data, company)
  }

  static async fetchTimeLineEvents(overtime, companyKey) {
    const overtimeTimelineLink = this._getLink(overtime, 'timeline')

    const method = overtimeTimelineLink.type.toLowerCase()

    const { data } = await http[method](overtimeTimelineLink.href, {
      params: {
        company: companyKey,
        overtime: overtime.id,
      },
    })

    return new Timeline(data)
  }

  static approve(overtime, companyKey) {
    const overtimeApproveLink = this._getLink(overtime, 'approve')

    const method = overtimeApproveLink.type.toLowerCase()

    return http[method](overtimeApproveLink.href, {
      company: companyKey,
    })
  }

  static update(overtime, companyKey, overtimeBreakdowns) {
    const overtimeUpdateLink = this._getLink(overtime, 'update')

    const method = overtimeUpdateLink.type.toLowerCase()

    return http[method](overtimeUpdateLink.href, {
      company_id: companyKey,
      overtime_breakdowns: overtimeBreakdowns,
    })
  }

  static cancel(overtime, companyKey) {
    const overtimeCancelLink = this._getLink(overtime, 'cancel')

    const method = overtimeCancelLink.type.toLowerCase()

    return http[method](overtimeCancelLink.href, {
      company: companyKey,
    })
  }

  static _getLink(overtime, type) {
    if (this._isString(overtime)) {
      return {
        type: this._guessType(type),
        href: `overtimes/${overtime}`,
      }
    }

    return overtime._links.find(link => link.rel === type)
  }

  static _isString(overtime) {
    return typeof overtime === 'string' || overtime instanceof String
  }

  static _guessType(type) {
    if (type === 'self') {
      return 'GET'
    }

    throw new Error(`Unsupported type: ${type}`)
  }
}
