import { uniqueId } from 'lodash-es'
import moment from 'moment-timezone'

export default class Breakdown {
  constructor(breakdown, date = null) {
    this.id = breakdown.id ?? uniqueId()
    this.iso_week_day = breakdown.iso_week_day
    this.session = breakdown.session
    this.active = true

    this._setStartTime(breakdown, date)
    this._setEndTime(breakdown, date)
  }

  isActive() {
    return this.active
  }

  activate() {
    this.active = true
  }

  deactivate() {
    this.active = false
  }

  isOverlap(breakdown) {
    return this.endTime.isAfter(breakdown.startTime)
  }

  isFirstSession() {
    return this.session === 1
  }

  isSecondSession() {
    return this.session === 2
  }

  isSameSession(breakdown) {
    return this.session === breakdown.session
  }

  isSameDay(breakdown) {
    return this.iso_week_day === breakdown.iso_week_day
  }

  isValid() {
    return this.startTime.isBefore(this.endTime)
  }

  setTimePeriodFrom(breakdown) {
    this.startTime = breakdown.startTime
    this.endTime = breakdown.endTime
  }

  toJson() {
    return {
      id: this.id,
      iso_week_day: this.iso_week_day,
      session: this.session,
      start_time: this.startTime.format('HH:mm:ss'),
      end_time: this.endTime.format('HH:mm:ss'),
    }
  }

  _setStartTime(breakdown, date) {
    this.startTime = this._makeTime(breakdown.start_time, date)
  }

  _setEndTime(breakdown, date) {
    this.endTime = this._makeTime(breakdown.end_time, date)
  }

  _makeTime(time, date) {
    return date
      ? moment.utc(`${date.format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm')
      : moment.utc(time, 'HH:mm')
  }
}
