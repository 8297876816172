import http from '@/plugins/http'
import CalendarCollection from '@/models/company/CalendarCollection'

export default class Calendars {
  static async all(params) {
    const { data } = await http.get('calendars', {
      params: params,
    })

    return new CalendarCollection(data)
  }

  static update(calendar) {
    return http.put(`calendars/${calendar.id}`, calendar)
  }

  static create(params) {
    return http.post(`calendars`, params)
  }

  static delete(calendar) {
    return http.delete(`calendars/${calendar.id}`, {
      data: { company_id: calendar.company_id },
    })
  }
}
