<template>
  <nav
    class="tw-w-full tw-t-0 tw-z-40 tw-bg-white tw-shadow tw-overflow-hidden"
  >
    <div class="tw-flex tw-justify-between tw-flex-wrap tw-px-4">
      <div class="tw-flex tw-items-center tw-justify-start tw-flex-no-shrink">
        <RouterLink :to="{ name: 'leave-summary' }">
          <SvgIcon name="logo" class="tw-mt-1 header-logo tw-w-full" />
        </RouterLink>
      </div>

      <div class="tw-block md:tw-hidden">
        <button
          class="tw-flex tw-items-center tw-text-blue-500"
          @click="show = !show"
        >
          <svg
            class="tw-mx-3 tw-my-6 tw-fill-current tw-h-4 tw-w-4"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>

      <div
        :class="[show ? 'tw-block' : 'tw-hidden']"
        class="tw-w-full tw-flex-grow md:tw-flex md:tw-items-center tw-self-center md:tw-w-auto"
      >
        <div
          class="tw-text-sm md:tw-flex-grow navbar-items tw-h-full tw-mb-4 md:tw-mb-0"
        >
          <RouterLink
            :to="{ name: 'leave-summary' }"
            class="nav-item-link tw-h-full tw-block md:tw-inline-block tw-no-underline tw-uppercase tw-text-thin tw-font-normal tw-text-black tw-mt-2 md:tw-mt-0 md:tw-mx-6 tw-py-1 tw-text-right md:tw-text-left"
            data-cy="summary"
          >
            Summary
          </RouterLink>
          <RouterLink
            :to="wallChartRoute"
            class="nav-item-link tw-h-full tw-block md:tw-inline-block tw-no-underline tw-uppercase tw-text-thin tw-font-normal tw-text-black tw-mt-6 md:tw-mt-0 md:tw-mx-6 tw-py-1 tw-text-right md:tw-text-left"
            data-cy="wall-chart"
          >
            Wall Chart
          </RouterLink>
          <RouterLink
            id="my-year-link"
            :to="{ name: 'my-year' }"
            class="nav-item-link tw-h-full tw-block md:tw-inline-block tw-no-underline tw-uppercase tw-text-thin tw-font-normal tw-text-black tw-mt-6 md:tw-mt-0 md:tw-mx-6 tw-py-1 tw-text-right md:tw-text-left"
            data-cy="my-year"
          >
            My Year
          </RouterLink>
          <RouterLink
            v-if="
              activeEmployment &&
                (activeEmployment.is_admin || activeEmployment.is_approver)
            "
            :to="{ name: 'reports-employee' }"
            :class="{ 'router-link-active': subIsActive(reportRoutes) }"
            class="nav-item-link tw-h-full tw-block md:tw-inline-block tw-no-underline tw-uppercase tw-text-thin tw-font-normal tw-text-black tw-mt-6 md:tw-mt-0 md:tw-mx-6 tw-py-1 tw-text-right md:tw-text-left"
            data-cy="reports"
          >
            Reports
          </RouterLink>
          <div class="tw-inline-block md:tw-mx-6">
            <SpinnerButton
              data-cy="book-leave-btn"
              type="button"
              @click="showRequestForm"
            >
              <span class="tw-uppercase tw-text-thin tw-font-normal">Book</span>
            </SpinnerButton>
          </div>
        </div>

        <div
          class="tw-flex tw-content-center tw-items-center tw-justify-end md:tw-justify-start tw-flex-wrap tw--mr-5 tw--ml-5"
        >
          <div v-if="activeCompany">
            <template v-if="canShowCompanySwitcher">
              <CompanySwitcher
                @default-company-changed="updateDefaultCompany"
              />
            </template>
            <template v-else>
              <span
                class="tw-text-gray-800 tw-px-6 tw-py-5 tw-font-normal tw-truncate"
                style="max-width: 270px;"
                v-text="activeCompany.name"
              />
            </template>
          </div>
          <div v-else>
            <RouterLink
              :to="{ name: 'companies' }"
              class="tw-block tw-mt-4 md:tw-inline-block tw-no-underline tw-text-base tw-font-semibold tw-text-blue-300 hover:tw-text-gray-800 md:tw-mt-0 md:tw-px-4 tw-py-1 md:tw-py-6"
              data-cy="manage-companies"
            >
              Manage Companies
            </RouterLink>
          </div>
          <div>
            <NewsFeed />
          </div>
          <div id="settings-link">
            <RouterLink
              v-if="activeEmployment && activeEmployment.is_admin"
              :to="{ name: 'company' }"
              data-cy="settings"
              class="tw-flex tw-no-underline tw-text-base tw-font-semibold tw-text-gray-800 tw-leading-none tw-px-6 tw-py-5"
            >
              <SvgIcon name="cog-stroke" class="tw-w-6 tw-h-6" />
            </RouterLink>
          </div>
          <div>
            <HelpMenu />
          </div>
          <div>
            <NotificationMenu />
          </div>
          <div class="tw-mr-4">
            <UserCircle />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import routes from '@/router/routes'
import UserCircle from '@/components/nav/UserCircle'
import CompanySwitcher from '@/components/nav/CompanySwitcher'
import NotificationMenu from '@/components/nav/NotificationMenu'
import HelpMenu from '@/components/nav/HelpMenu'
import { mapActions, mapGetters } from 'vuex'
import RequestForm from '@/components/requests/RequestForm'
import NewsFeed from '@/components/nav/NewsFeed'
import SpinnerButton from '@/components/SpinnerButton'

export default {
  name: 'AppNavbar',

  components: {
    SpinnerButton,
    NewsFeed,
    UserCircle,
    NotificationMenu,
    HelpMenu,
    CompanySwitcher,
  },

  data: () => ({
    show: false,
  }),

  computed: {
    ...mapGetters('auth', ['companies']),

    reportRoutes() {
      return routes
        .find(route => route.path === '/reports')
        .children.map(route => '/reports/' + route.path)
    },

    wallChartRoute() {
      let query =
        this.$route.query['group-by'] === 'department'
          ? { 'group-by': 'department' }
          : {}

      return {
        name: 'wall-chart',
        query,
      }
    },

    canShowCompanySwitcher() {
      return (
        this.isActiveEmploymentAnAdmin ||
        (this.companies && this.companies.length > 1)
      )
    },
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]

      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0
      })
    },

    async updateDefaultCompany(companyId = null) {
      try {
        const method = companyId ? 'post' : 'delete'

        await this.$http[method]('me/default-companies', {
          default_company: companyId,
        })
      } catch (e) {
        this.serverError()
      }

      this.fetchUser()
    },

    showRequestForm() {
      const form = new RequestForm({
        success: panel => this.$showPanel(panel),
        failed: async message => {
          const confirmed = await this.confirm(message)

          if (!confirmed) return

          return this.$router.push({ name: 'billing' }, () => {})
        },
      })

      form.open(this.activeCompany, this.activeEmployment)
    },
  },
}
</script>
