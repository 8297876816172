<template>
  <div class="tw-ml-2">
    <button
      ref="filterButton"
      class="tw-border tw-border-gray-330 tw-px-3 tw-py-2 tw-space-x-1 tw-flex tw-items-center tw-rounded hover:tw-bg-gray-100 focus:tw-border-gray-330"
      type="button"
      title="Filter"
      data-cy="filter-leave-overlap"
    >
      <SvgIcon name="filter" class="tw-w-5 tw-h-5 tw-text-gray-770" />
      <span class="tw-text-gray-555">Filter</span>
    </button>

    <div v-show="false" ref="filterContainer">
      <div class="tw-rounded tw-overflow-auto" style="min-width: 163px;">
        <template v-for="type of filterTypes">
          <div
            :key="type.name"
            class="tw-pt-4 tw-pb-1 tw-px-6 tw-border-b tw-border-gray-300"
          >
            <div class="tw-mb-5 tw-text-gray-600">
              {{ type.heading }}
            </div>

            <template v-for="scope of scopeFilters(type.name)">
              <div :key="scope.key" class="tw-mb-4 tw-flex tw-items-center">
                <input
                  :id="scope.key"
                  v-model="selectedFilters[type.model]"
                  class="magic-radio"
                  :name="scope.name"
                  type="radio"
                  :value="scope.key"
                  @change="$emit('change-filters', selectedFilters)"
                />
                <label
                  :for="scope.key"
                  class="tw-select-none magic-radio-label tw-w-full tw-font-normal"
                >
                  {{ scope.label }}
                </label>
              </div>
            </template>
          </div>
        </template>

        <div class="tw-pt-4 tw-pb-1 tw-px-6 tw-border-t tw-border-gray-300">
          <div class="tw-mb-5 tw-text-gray-600">
            Show conflicts:
          </div>

          <div class="tw-mb-4 tw-flex tw-items-center">
            <input
              id="conflicts"
              v-model="selectedFilters.conflicts"
              class="magic-checkbox"
              name="conflicts"
              type="checkbox"
              @change="$emit('change-filters', selectedFilters)"
            />
            <label
              for="conflicts"
              class="tw-select-none magic-checkbox-label tw-w-full tw-font-normal"
            >
              Conflicts
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'

const FILTER_TYPES = [
  { name: 'overlap', model: 'overlapScope', heading: 'Show overlaps with:' },
  { name: 'day', model: 'dayScope', heading: 'Display:' },
]

const SCOPE_FILTERS = [
  { key: 'company', label: 'Entire Company', name: 'overlap' },
  { key: 'department', label: 'Department', name: 'overlap' },
  { key: 'all_days', label: 'All days', name: 'day' },
  { key: 'working_days', label: 'Working days', name: 'day' },
]

export default {
  name: 'LeaveDetailsFilter',

  props: {
    selectedFilters: {
      type: Object,
      required: true,
    },

    containerClass: {
      type: String,
      required: true,
    },
  },

  computed: {
    filterTypes() {
      return FILTER_TYPES
    },
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.filterButton, {
      html: this.$refs.filterContainer.firstChild,
      trigger: 'click',
      distance: -5,
      placement: 'bottom-end',
      offset: '0,8',
      interactive: true,
      theme: 'light',
      animation: 'scale',
      inertia: true,
      arrow: true,
      onShow: instance => {
        const eventListener = e => {
          if (!(this.$el === e.target || this.$el.contains(e.target))) {
            instance.hide()
          }
        }

        document
          .querySelector(this.containerClass)
          .addEventListener('click', eventListener)

        this.$once('hook:beforeDestroy', () => {
          document.removeEventListener('click', eventListener)
        })
      },
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },

  methods: {
    scopeFilters(type) {
      return SCOPE_FILTERS.filter(scope => scope.name === type)
    },
  },
}
</script>
