var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OvertimeBreakdown',{attrs:{"minutes-per-working-day":_vm.minutesPerWorkingDay,"breakdown":_vm.breakdown},on:{"breakdown-unit-changed":_vm.breakdownUnitChanged,"breakdown-amount-changed":_vm.breakdownAmountChanged},scopedSlots:_vm._u([{key:"default",fn:function({
      overtimeUnits,
      selectedOvertimeUnit,
      changeAmount,
      changeOvertimeUnit,
      displayAmount,
    }){return [_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-w-full tw-bg-white tw-rounded-lg"},[_c('RequestBreakdownDate',{attrs:{"date":_vm.breakdown.date}}),_c('div',{staticClass:"tw--mr-1 tw-py-2 tw-flex tw-items-center tw-justify-end tw-space-x-4"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-center tw-space-x-2"},[_c('input',{staticClass:"form-control",staticStyle:{"width":"74px"},attrs:{"type":"number","min":"0","step":"any","data-cy":""},domProps:{"value":displayAmount},on:{"input":changeAmount}}),_c('VSelect',{staticClass:"tw-w-24",attrs:{"id":"overtime_unit_type","value":selectedOvertimeUnit,"options":overtimeUnits,"multiple":false,"searchable":false,"show-labels":false,"allow-empty":false,"track-by":"key","label":"label","placeholder":""},on:{"input":changeOvertimeUnit}})],1),_c('div',{staticClass:"tw-space-x-2 tw-flex tw-items-center tw-justify-center"},[_c('button',{staticClass:"tw-flex tw-items-center tw-justify-center tw-rounded tw-bg-blue-550 tw-text-white",staticStyle:{"padding":"0.914rem"},attrs:{"type":"button"},on:{"click":_vm.updateBreakdown}},[_c('svg-icon',{staticClass:"tw-w-5 tw-h-5 tw-text-white",attrs:{"name":"checkmark"}})],1),_c('button',{staticClass:"tw-flex tw-items-center tw-justify-center tw-border tw-border-gray-330 tw-rounded tw-bg-white tw-text-gray-660",staticStyle:{"padding":"0.837rem"},attrs:{"type":"button"},on:{"click":_vm.closeBreakdownEditor}},[_c('svg-icon',{staticClass:"tw-w-5 tw-h-5 tw-text-gray-660",attrs:{"name":"close"}})],1)])])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }