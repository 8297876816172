export default {
  methods: {
    success(text, title = 'Great...') {
      this.$notify({ group: 'app', type: 'success', title: title, text: text })
    },

    error(text, title = 'Oops...', options) {
      this.$notify({
        group: 'app',
        type: 'error',
        title: title,
        text: text,
        ...options,
      })
    },

    serverError() {
      this.error('We are having some issues, please try again.')
    },

    validationErrors(errors, options) {
      this.error(Object.values(errors).join('<br />'), 'Oops...', options)
    },
  },
}
