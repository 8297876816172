import http from '@/plugins/http'
import Company from '@/models/company/Company'
import { flatMap, filter, sortBy } from 'lodash-es'
import Employment from '@/models/employment/Employment'

const initialState = {
  user: {
    id: null,
    verified_at: null,
    employments: [],
  },
  company: null,
  inActiveEmploymentTimezone: false,
}

export const state = {
  ...initialState,
  initialState() {
    return initialState
  },
}

export const getters = {
  check({ user }) {
    return user.id !== null
  },

  verified({ user }) {
    return user.id && !!user.verified_at
  },

  activeCompany(state, { companies }, { route }) {
    const company =
      companies.find(company => company.id === route.query.company) ||
      state.company ||
      companies.find(
        company => company.id === state.user.settings.default_company
      ) ||
      companies[0]

    return company ? new Company(company) : null
  },

  activeEmployment({ user }, getters) {
    const employment = user.employments.find(
      employment => employment.company_id === getters.activeCompany.id
    )

    return new Employment(employment)
  },

  companies({ user }) {
    const companies = flatMap(
      user.employments,
      employment => employment.company
    )

    return sortBy(companies, ['name'])
  },

  employments({ user }) {
    return user.employments
  },
}

export const mutations = {
  setAuthUser(state, user) {
    state.user = {
      ...user,
      employments: user.employments.map(employment => ({
        ...employment,
        failed_calendar_services: employment.failed_calendar_services.map(
          service => ({
            ...service,
            isDismissed: false,
          })
        ),
      })),
    }
  },

  setActiveCompany(state, company) {
    state.company = company
  },

  useActiveEmploymentTimezone(state, status) {
    state.inActiveEmploymentTimezone = status
  },

  markCalendarErrorAsDismissed(
    state,
    { activeEmployment, dismissingCalendar }
  ) {
    const otherEmployments = filter(
      state.user.employments,
      employment => employment.id !== activeEmployment.id
    )

    const otherFailedCalendarServices = filter(
      activeEmployment.failed_calendar_services,
      calendar => calendar.id !== dismissingCalendar.id
    )

    state.user = {
      ...state.user,
      employments: [
        ...otherEmployments,
        {
          ...activeEmployment,
          failed_calendar_services: [
            ...otherFailedCalendarServices,
            {
              ...dismissingCalendar,
              isDismissed: true,
            },
          ],
        },
      ],
    }
  },
}

export const actions = {
  async fetchUser({ commit }) {
    const { data: user } = await http.get('me')

    commit('setAuthUser', user)
  },
}
