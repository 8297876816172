<template>
  <v-tour
    :steps="tourSteps"
    :callbacks="customCallbacks"
    data-cy="leave-dates-tour"
    name="leave-dates-tour"
  />
</template>

<script>
export default {
  name: 'LeaveDatesTour',

  data() {
    return {
      commonTourSteps: [
        {
          target: 'body',
          duration: 1100,
          content:
            '<p>Welcome to <strong>Leave Dates</strong>!</p><p>Would you like a quick tour?</p>',
          params: {
            placement: 'none',
            modifiers: {
              computeStyle: {
                enabled: true,
                fn(data) {
                  data.styles = {
                    ...data.styles,
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }

                  return data
                },
              },
            },
          },
        },
        {
          target: '#full-calendar',
          duration: 1100,
          content:
            'This is your <strong>Wall Chart</strong> where you can see all your team’s time off in one place.',
        },
        {
          target: '#full-calendar',
          duration: 1100,
          content:
            'To <strong>request leave</strong> you can simply drag over the Wall Chart. Try it.',
          params: {
            placement: 'bottom',
            modifiers: {
              computeStyle: {
                enabled: true,
                fn(data) {
                  data.styles = {
                    ...data.styles,
                    left: '20%',
                    top: '50%',
                  }

                  return data
                },
              },
            },
          },
        },
        {
          target: '#my-year-link',
          duration: 1100,
          content:
            'Click <strong>My Year</strong> to view your personal calendar.',
        },
        {
          target: 'body',
          duration: 1100,
          content:
            'This is the <strong>My Year</strong> screen where you can see all your leave for the year as well as your remaining balance.',
          params: {
            placement: 'none',
            modifiers: {
              computeStyle: {
                enabled: true,
                fn(data) {
                  data.styles = {
                    ...data.styles,
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }

                  return data
                },
              },
            },
          },
        },
      ],

      adminTourSteps: [
        {
          target: '#settings-link',
          duration: 1100,
          content:
            'Everything in Leave Dates can be configured to suit your needs. Click the <strong>Cog</strong> icon to configure your calendar.',
        },
      ],

      customCallbacks: {
        onNextStep: this.customNextStep,
        onPreviousStep: this.customPreviousStep,
        onStop: this.customStopStep,
      },
    }
  },

  computed: {
    tourSteps() {
      if (this.isAdmin) {
        return [...this.commonTourSteps, ...this.adminTourSteps]
      }

      return this.commonTourSteps
    },

    isAdmin() {
      if (!this.activeEmployment) {
        return false
      }

      return this.activeEmployment.is_admin
    },
  },

  methods: {
    customNextStep(currentStep) {
      if (currentStep == 3) {
        this.$router.push('my-year', () => {})
      }
    },

    customPreviousStep(currentStep) {
      if (currentStep == 4) {
        this.$router.push('wall-chart', () => {})
      }
    },

    customStopStep() {
      let query = { ...this.$route.query }

      delete query.tour

      this.$router.replace({ query }, () => {})
    },
  },
}
</script>
