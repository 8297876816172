<template>
  <div class="leave-breakdown-item" data-cy="leave-breakdown-item">
    <div class="tw-flex tw-items-center tw-justify-between">
      <RequestBreakdownDate :date="breakdown.date" />

      <VSelect
        v-if="showDurationSelector"
        id="allowance_unit"
        v-model="selectedDuration"
        class="ld_v-select rtl"
        :options="selectableDurations"
        :multiple="false"
        :searchable="false"
        :show-labels="false"
        :allow-empty="false"
        track-by="key"
        label="label"
        placeholder=""
        data-cy="select-duration"
      />
      <NonWorkingDayInfo v-else-if="breakdown.isNonWorkingDay" />
      <HolidayInfo
        v-else-if="breakdown.isHoliday"
        :holiday="breakdown.holidayInformation"
      />
    </div>

    <LeaveBreakdownCustomOptions
      v-if="canShowBreakdownCustomOptions"
      :from="breakdown.activeDuration.from"
      :to="breakdown.activeDuration.to"
      :breakdown-duration="breakdown.rawBreakdown.duration"
      :show-custom-duration="showCustomDuration"
      :employments="employments"
      :is-single-employment-request="isSingleEmploymentRequest"
      class="tw-mt-2"
      @time-updated="handleTimeUpdated"
    />

    <div v-for="(message, index) in breakdownMessages" :key="index">
      <ArrowAlert class="tw-mt-1" :theme="message.theme" :text="message.text" />
    </div>

    <div v-if="isLeaveLimitExceeded">
      <div ref="leaveLimitExceedMessage">
        <ArrowAlert
          class="tw-mt-1"
          :theme="leaveLimitExceededWarning.theme"
          :text="leaveLimitExceededWarning.text"
        >
          <UserAvatarList
            class="tw-ml-3"
            :nonvisible-user-count="
              leaveLimitExceededWarning.nonvisibleEmploymentCount
            "
            :users="leaveLimitExceededWarning.owners"
          />
        </ArrowAlert>

        <div v-if="leaveLimitExceededWarning.hasOwners" ref="overlapLeavePanel">
          <div
            class="tw-items-start tw-p-3 tw-border tw-rounded-md tw-border-gray-660  tw-bg-gray-660 leave-container"
          >
            <OverlapLeave
              :overlap-leaves="leaveLimitExceededWarning.leaves"
              @show-overlap-leave-details="showOverlapLeaveDetails"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="isOverlapLeaveExists">
      <div ref="overlapLeaveExistsMessage">
        <ArrowAlert
          class="tw-mt-1"
          :theme="existingOverlapLeave.theme"
          :text="existingOverlapLeave.text"
        >
          <template v-if="existingOverlapLeave.hasOwners">
            <UserAvatarList
              class="tw-ml-3"
              :users="existingOverlapLeave.owners"
            />
          </template>
        </ArrowAlert>

        <div
          v-if="existingOverlapLeave.hasOwners"
          ref="existingOverlapLeavePanel"
        >
          <div
            class="tw-items-start tw-p-3 tw-border tw-rounded-md tw-border-gray-660 tw-bg-gray-660 leave-container"
          >
            <OverlapLeave
              :overlap-leaves="existingOverlapLeave.leaves"
              @show-overlap-leave-details="showOverlapLeaveDetails"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestBreakdownDate from '@/components/requests/RequestBreakdownDate'
import ArrowAlert from '@/components/ArrowAlert'
import UserAvatarList from '@/components/UserAvatarList'
import tippy from 'tippy.js'
import OverlapLeave from '@/components/requests/leave/OverlapLeave'
import HolidayInfo from '@/components/requests/leave/HolidayInfo'
import NonWorkingDayInfo from '@/components/requests/leave/NonWorkingDayInfo'
import VSelect from 'vue-multiselect'
import LeaveBreakdownCustomOptions from '@/components/requests/leave/LeaveBreakdownCustomOptions'

const DURATION_MAP = {
  full_day: {
    key: 'full_day',
    label: 'All day',
  },

  first_session: {
    key: 'first_session',
    label: 'Half day - Morning',
  },

  second_session: {
    key: 'second_session',
    label: 'Half day - Afternoon',
  },

  custom: {
    key: 'custom',
    label: 'Other',
  },
}

export default {
  name: 'LeaveRequestBreakdown',

  components: {
    NonWorkingDayInfo,
    HolidayInfo,
    RequestBreakdownDate,
    ArrowAlert,
    UserAvatarList,
    VSelect,
    LeaveBreakdownCustomOptions,
    OverlapLeave,
  },

  props: {
    breakdown: {
      type: Object,
      required: true,
    },

    showCustomDuration: {
      type: Boolean,
      default: true,
    },

    isSingleEmploymentRequest: {
      type: Boolean,
      required: true,
    },

    employments: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedDuration: DURATION_MAP[this.breakdown.activeDuration.name],
      overlapTooltip: null,
      existingOverlapTooltip: null,
    }
  },

  computed: {
    canShowBreakdownCustomOptions() {
      if (
        this.isSingleEmploymentRequest &&
        this.breakdown.isWorkingDay &&
        this.breakdown.activeDuration.isCustom
      ) {
        return true
      }

      return (
        !this.isSingleEmploymentRequest &&
        this.breakdown.activeDuration.isCustom
      )
    },

    showDurationSelector() {
      return this.breakdown.availableDurations.length > 1
    },

    breakdownMessages() {
      return [
        ...this.breakdown.errors.filter(
          error => !error.isOverlapWithExistingLeave
        ),
        ...this.breakdown.warnings.filter(
          warning => !warning.isLeaveLimitReached
        ),
      ]
    },

    isLeaveLimitExceeded() {
      return this.leaveLimitExceededWarning
    },

    leaveLimitExceededWarning() {
      return this.breakdown.warnings.find(
        warning => warning.isLeaveLimitReached
      )
    },

    isOverlapLeaveExists() {
      return this.existingOverlapLeave
    },

    existingOverlapLeave() {
      return this.breakdown.errors.find(
        error => error.isOverlapWithExistingLeave
      )
    },

    selectableDurations() {
      return this.breakdown.availableDurations.map(
        duration => DURATION_MAP[duration.name]
      )
    },
  },

  watch: {
    selectedDuration(selectedDuration) {
      const breakdown = this.breakdown.clone()
      breakdown.activeDuration = selectedDuration.key

      this.$emit('duration-selected', breakdown)
    },
  },

  mounted() {
    if (this.isLeaveLimitExceeded && this.leaveLimitExceededWarning.hasOwners) {
      this.overlapTooltip = tippy.one(this.$refs.leaveLimitExceedMessage, {
        html: this.$refs.overlapLeavePanel.firstChild,
        ...this.getCommonTooltipOptions(),
      })
    }

    if (this.isOverlapLeaveExists && this.existingOverlapLeave.hasOwners) {
      this.existingOverlapTooltip = tippy.one(
        this.$refs.overlapLeaveExistsMessage,
        {
          html: this.$refs.existingOverlapLeavePanel.firstChild,
          ...this.getCommonTooltipOptions(),
        }
      )
    }

    this.$once('hook:beforeDestroy', this.destroyTooltips)
  },

  methods: {
    destroyTooltips() {
      this.existingOverlapTooltip?.destroy()
      this.overlapTooltip?.destroy()
    },

    getCommonTooltipOptions() {
      return {
        theme: 'dark',
        arrow: true,
        interactive: true,
        placement: 'top',
        delay: [400, 0],
        distance: 1,
        interactiveBorder: 15,
      }
    },

    showOverlapLeaveDetails(leaveKey) {
      this.hideTooltips()

      this.$router.push({
        query: {
          ...this.$route.query,
          'leave-request': leaveKey,
        },
      })

      this.$emit('show-overlap-leave-details')
    },

    hideTooltips() {
      this.overlapTooltip?.hide()
      this.existingOverlapTooltip?.hide()
    },

    handleTimeUpdated(updatedTime) {
      const breakdown = this.breakdown.clone()
      breakdown.updateCustomDuration(updatedTime)

      this.$emit('duration-selected', breakdown)
    },
  },
}
</script>

<style scoped>
.leave-container {
  overflow-y: auto;
  max-height: 200px;
}
</style>
