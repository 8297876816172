import Vue from 'vue'
import http from './http'
import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

const echo = new Echo({
  encrypted: true,
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  authorizer: channel => {
    return {
      authorize: (socketId, callback) => {
        http
          .post('broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then(response => {
            callback(false, response.data)
          })
          .catch(error => {
            callback(true, error)
          })
      },
    }
  },
})

Vue.prototype.$echo = echo

export default echo
