import LeaveEvent from '@/components/requests/leave/events/LeaveEvent'

export default class RetractedEvent extends LeaveEvent {
  constructor(event) {
    super(event)
  }

  asText(viewer) {
    return `
      **${this.getActorName(viewer)}**
      retracted the
      ${this.getActionText()}
    `
  }

  getActionText() {
    let action = this.getRetractedAction()
    return `${action}`
  }

  getRetractedAction() {
    switch (this.retractedLeaveStatus()) {
      case 'approved':
        return 'approval'
      case 'rejected':
        return 'decline'
      case 'cancelled':
        return 'cancel'
    }
  }

  retractedLeaveStatus() {
    return this.event.event_properties.retracted_leave_status
  }
}
