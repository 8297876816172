import OvertimeRequest from '@/models/overtime/drafting/OvertimeRequest'
import Days from '@/models/overtime/Days'
import Hours from '@/models/overtime/Hours'
import Employment from '@/models/employment/Employment'
import moment from 'moment-timezone'

export default class Overtime extends OvertimeRequest {
  constructor(overtime, company) {
    overtime.owner = new Employment(overtime.owner, company)

    super(overtime.owner, overtime.breakdowns)

    overtime.breakdowns = this._mapOvertimeBreakdowns(overtime)

    Object.keys(overtime).forEach(property => {
      this[property] = overtime[property]
    })
  }

  get isRequested() {
    return this.status === 'REQUESTED'
  }

  get isApproved() {
    return this.status === 'APPROVED'
  }

  get isCancelled() {
    return this.status === 'CANCELLED'
  }

  get isDeclined() {
    return this.status === 'REJECTED'
  }

  getCurrentAllowance(remainingAllowance) {
    if (this.isApproved) {
      return remainingAllowance - this.duration_in_allowance_unit
    }

    return remainingAllowance
  }

  getNewAllowance(remainingAllowance) {
    return (
      this.getCurrentAllowance(remainingAllowance) + this.getChangedAllowance()
    )
  }

  get minutesPerWorkingDay() {
    return this.minutes_per_working_day
  }

  get allowanceUnit() {
    return this.allowanceUnitIsDays ? 'days' : 'hours'
  }

  get allowanceUnitIsDays() {
    return this.allowance_unit_is_days
  }

  isReasonVisible() {
    return this.reason !== null
  }

  areAttachmentsVisible() {
    return this.attachments !== null
  }

  _mapOvertimeBreakdowns(overtime) {
    return overtime.breakdowns.map(breakdown => {
      if (breakdown.unit === 'days') {
        return new Days(
          breakdown.date,
          breakdown.amount_in_minutes / overtime.minutes_per_working_day
        )
      } else {
        return new Hours(breakdown.date, breakdown.amount_in_minutes / 60)
      }
    })
  }

  canApprove() {
    const approveLink = this._links.find(link => link.rel === 'approve')

    return !!approveLink
  }

  canCancel() {
    const cancelLink = this._links.find(link => link.rel === 'cancel')

    return !!cancelLink
  }

  getPeriod() {
    return [this._toDate(this.start), this._toDate(this.end)].join(',')
  }

  get start() {
    return this._toMoment(this.from)
  }

  get end() {
    return this._toMoment(this.to)
  }

  getOwnerFullName() {
    return this.owner.full_name
  }

  _toMoment(time) {
    return moment.utc(time).tz(this.timezone)
  }

  _toDate(date) {
    return date.format('YYYY-MM-DD')
  }
}
