<template>
  <BaseAlert theme="danger" class="tw-mt-2">
    {{ exceedsRemainingEmploymentAllowanceText }}
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
import ExceedsRemainingEmploymentAllowanceText from '@/components/requests/ExceedsRemainingEmploymentAllowanceText'

export default {
  name: 'RequestLeaveOnPastDateWarning',
  components: {
    BaseAlert,
  },

  props: {
    owner: {
      type: Object,
      required: true,
    },
    viewer: {
      type: Object,
      required: true,
    },
  },

  computed: {
    exceedsRemainingEmploymentAllowanceText() {
      return new ExceedsRemainingEmploymentAllowanceText(
        this.viewer,
        this.owner
      ).getText()
    },
  },
}
</script>
