<template>
  <p class="tw-text-red-700 tw-text-sm">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'BaseInputError',
}
</script>
