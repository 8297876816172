import store from '@/store'

export default (to, from, next) => {
  if (useCompany(from, to)) {
    if (!hasCompany(from)) {
      next({ name: 'setup-company', query: { ...to.query } })
    } else {
      next({ ...to, query: { ...to.query, company: getCompanyID(from) } })
    }
  } else {
    next()
  }
}

function useCompany(from, to) {
  return store.getters['auth/check'] && to.meta.company && !to.query.company
}

function hasCompany(from) {
  return !!from.query.company || !!store.getters['auth/activeCompany']
}

function getCompanyID(from) {
  return from.query.company || store.getters['auth/activeCompany'].id
}
