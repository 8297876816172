<template>
  <button
    id="submit-button"
    :data-cy="cypressAttribute"
    :class="classes"
    @click.stop="$emit('click')"
  >
    <span v-if="loading">
      <SvgIcon
        name="spinner-circle"
        class="tw-animate-spin tw-h-5 tw-w-5 tw-text-white"
        :class="spinnerClasses"
      />

      <span v-if="!spinnerOnly" class="tw-ml-3">{{ loadingText }}</span>
    </span>

    <span v-else>
      <slot></slot>
    </span>
  </button>
</template>

<script>
const THEMES = {
  button: {
    default: ['btn-blue tw-text-white'],

    red: ['btn-red tw-text-white'],

    green: ['btn-green tw-text-white'],

    white: ['btn-white tw-text-770'],

    transparent: [],
  },

  link: {
    default: ['tw-text-blue-500 '],

    red: ['tw-text-red-500 '],

    transparent: [],
  },
}

export default {
  name: 'SpinnerButton',

  props: {
    as: {
      type: String,
      default: 'button',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    loadingText: {
      type: String,
      default: 'Saving',
    },

    theme: {
      type: String,
      default: 'default',
    },

    spinnerClasses: {
      type: Array,
      default: () => [],
    },

    spinnerOnly: {
      type: Boolean,
      default: false,
    },
    cypressAttribute: {
      type: String,
      default: 'button',
    },
  },

  computed: {
    classes() {
      return [...this.typeClasses, ...this.themeClasses]
    },

    typeClasses() {
      if (this.theme === 'transparent') {
        return []
      }

      if (this.as === 'link') {
        return [
          'tw-px-6 tw-py-3 tw-flex tw-items-center tw-justify-center tw-font-medium tw-transition tw-transition-all tw-ease-in-out tw-duration-500 btn-link',
        ]
      }

      return [
        'tw-px-6 tw-py-3 tw-flex tw-items-center tw-justify-center tw-font-medium tw-leading-default tw-transition tw-transition-all tw-ease-in-out tw-duration-500 tw-rounded-lg',
      ]
    },

    themeClasses() {
      return THEMES[this.as][this.theme]
    },
  },
}
</script>

<style lang="scss" scoped>
#submit-button:disabled {
  pointer-events: auto;
  cursor: not-allowed;
}
</style>
