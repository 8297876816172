<template>
  <modal
    :classes="['tw-shadow-md', 'tw-bg-white', 'tw-rounded-lg']"
    :max-width="520"
    name="app-modal"
    width="95%"
    height="auto"
    adaptive
    scrollable
  >
    <portal-target name="app-modal-content" slim></portal-target>
  </modal>
</template>

<script>
export default {
  name: 'AppModal',
}
</script>
