<template>
  <div
    class="allowance-stats tw-grid tw-grid-cols-3"
    :class="{
      'danger-current': allowances.hasRemainingAllowancesExceeded,
    }"
  >
    <CurrentAllowance :allowances="allowances" :title="currentAllowanceTitle" />

    <NewAllowance :allowances="allowances" :title="newAllowanceTitle" />

    <ChangedAllowance
      :allowances="allowances"
      icon-classes="tw-bg-green-100 tw-text-green-550 tw-transform tw--rotate-90"
    />
  </div>
</template>

<script>
import CurrentAllowance from '@/components/requests/CurrentAllowance'
import NewAllowance from '@/components/requests/NewAllowance'
import ChangedAllowance from '@/components/requests/ChangedAllowance'

export default {
  name: 'AllowanceIncrementMonitor',

  components: {
    CurrentAllowance,
    NewAllowance,
    ChangedAllowance,
  },

  props: {
    allowances: {
      type: Object,
      required: true,
    },

    status: {
      type: String,
      default: null,
    },
  },

  computed: {
    currentAllowanceTitle() {
      const MAPPER = {
        APPROVED: 'Before',
      }

      return MAPPER[this.status?.toUpperCase()] ?? 'Current'
    },

    newAllowanceTitle() {
      const MAPPER = {
        APPROVED: 'After',
      }

      return MAPPER[this.status?.toUpperCase()] ?? 'New'
    },
  },
}
</script>

<style lang="scss">
.allowance-stats > {
  div {
    @apply bg-blue-200;

    &:nth-child(1) {
      @apply border-r-0;
      border-radius: 0.5rem 0 0 0.5rem;
    }

    &:nth-child(3) {
      @apply border-l-0;
      border-radius: 0 0.5rem 0.5rem 0;
    }

    &.danger {
      @apply border-red-250;
      @apply bg-red-200;
    }
  }
}

.allowance-stats {
  &.danger-current {
    div {
      &:nth-child(2) {
        border-left-color: theme('colors.red.250');
      }
    }
  }

  &.danger-change {
    div {
      &:nth-child(2) {
        border-right-color: theme('colors.red.250');
      }
    }
  }
}
</style>
