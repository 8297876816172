<template>
  <BaseAlert class="tw-mt-2 tw-px-3 tw-py-3" theme="danger">
    You are trying to request overtime outside of the employment start/end date.
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'

export default {
  name: 'RequestingOutsideOfEmploymentPeriodError',
  components: {
    BaseAlert,
  },
}
</script>
