import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('auth', {
      authUser: 'user',
    }),

    ...mapGetters('auth', {
      emailVerified: 'verified',
      activeCompany: 'activeCompany',
      activeEmployment: 'activeEmployment',
    }),

    inActiveEmploymentTimezone: {
      get() {
        return this.$store.state.auth.inActiveEmploymentTimezone
      },
      set(value) {
        this.$store.commit('auth/useActiveEmploymentTimezone', value)
      },
    },

    isActiveEmploymentAnAdmin() {
      return this.activeEmployment && this.activeEmployment.is_admin
    },
  },
}
