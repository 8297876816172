<template>
  <div class="tabs-component tw-flex tw-flex-col tw-flex-1">
    <ul role="tablist" class="tabs-component-tabs">
      <li
        v-for="(tab, i) in tabs"
        v-show="tab.isVisible"
        :key="i"
        :class="{ 'is-active': tab.isActive, 'is-disabled': tab.isDisabled }"
        class="tabs-component-tab"
        role="presentation"
      >
        <a
          :aria-controls="tab.hash"
          :aria-selected="tab.isActive"
          :href="tab.hash"
          class="tabs-component-tab-a"
          role="tab"
          @click="selectTab(tab.hash, $event)"
        >
          <SvgIcon
            v-if="tab.prefixIcon"
            :name="tab.prefixIcon"
            class="tw-w-5 tw-h-5 tw-mr-3"
          />

          <portal-target v-if="tab.id" :name="tab.name" class="tw-flex" />

          <SvgIcon
            v-if="tab.suffixIcon"
            :name="tab.suffixIcon"
            class="tw-w-5 tw-h-5 tw-ml-3"
          />
        </a>
      </li>
    </ul>

    <div class="tabs-component-panels tw-flex tw-flex-col tw-flex-1">
      <slot />
    </div>
  </div>
</template>

<script>
import expiringStorage from '@/support/expiringStorage'

export default {
  name: 'TabsWrapper',

  props: {
    cacheLifetime: {
      type: Number,
      default: 0,
    },

    options: {
      type: Object,
      required: false,
      default: () => ({
        useUrlFragment: false,
        defaultTabHash: null,
      }),
    },
  },

  data: () => ({
    tabs: [],
    activeTabHash: '',
    activeTabIndex: 0,
    lastActiveTabHash: '',
  }),

  computed: {
    storageKey() {
      return `vue-tabs-component.cache.${window.location.host}${window.location.pathname}`
    },
  },

  created() {
    this.tabs = this.$children
  },

  mounted() {
    window.addEventListener('hashchange', () =>
      this.selectTab(window.location.hash)
    )

    if (this.findTab(window.location.hash)) {
      this.selectTab(window.location.hash)
      return
    }

    const previousSelectedTabHash = expiringStorage.get(this.storageKey)

    if (this.findTab(previousSelectedTabHash)) {
      this.selectTab(previousSelectedTabHash)
      return
    }

    if (
      this.options.defaultTabHash !== null &&
      this.findTab('#' + this.options.defaultTabHash)
    ) {
      this.selectTab('#' + this.options.defaultTabHash)
      return
    }

    if (this.tabs.length) {
      this.selectTab(this.tabs[0].hash)
    }
  },

  methods: {
    findTab(hash) {
      return this.tabs.find(tab => tab.hash === hash)
    },

    selectTab(selectedTabHash, event) {
      // See if we should store the hash in the url fragment.
      if (event && !this.options.useUrlFragment) {
        event.preventDefault()
      }

      const selectedTab = this.findTab(selectedTabHash)

      if (!selectedTab) {
        return
      }

      if (selectedTab.isDisabled) {
        event.preventDefault()
        return
      }

      if (this.lastActiveTabHash === selectedTab.hash) {
        this.$emit('clicked', { tab: selectedTab })
        return
      }

      this.tabs.forEach(tab => {
        tab.isActive = tab.hash === selectedTab.hash
      })

      this.$emit('changed', { tab: selectedTab })

      this.activeTabHash = selectedTab.hash
      this.activeTabIndex = this.getTabIndex(selectedTabHash)

      this.lastActiveTabHash = this.activeTabHash = selectedTab.hash

      expiringStorage.set(this.storageKey, selectedTab.hash, this.cacheLifetime)
    },

    setTabVisible(hash, visible) {
      const existingTab = this.findTab(hash)

      if (!existingTab) {
        return
      }

      existingTab.isVisible = visible

      if (existingTab.isActive) {
        // If tab is active, set a different one as active.
        existingTab.isActive = visible

        this.tabs.every(tab => {
          if (tab.isVisible) {
            tab.isActive = true

            return false
          }

          return true
        })
      }
    },

    getTabIndex(hash) {
      const tab = this.findTab(hash)

      return this.tabs.indexOf(tab)
    },

    getTabHash(index) {
      const existingTab = this.tabs.find(
        tab => this.tabs.indexOf(tab) === index
      )

      if (!existingTab) {
        return
      }

      return existingTab.hash
    },

    getActiveTab() {
      return this.findTab(this.activeTabHash)
    },

    getActiveTabIndex() {
      return this.getTabIndex(this.activeTabHash)
    },
  },
}
</script>

<style lang="scss">
.tabs-component {
  @apply .mt-0;
}

.tabs-component-tabs {
  @apply .border;
  @apply .border-0;
  @apply .border-b;
  @apply .border-gray-350;
  @apply .flex;
  @apply .items-stretch;
  @apply .justify-start;
}

.tabs-component-tab {
  top: 1px;
  @apply .relative;
  @apply .border-b-2;
  @apply .border-transparent;
  @apply .text-gray-555;
  @apply .text-base;
  @apply .font-medium;
  @apply .list-none;

  &:hover {
    @apply .text-gray-770;
  }

  &.is-active {
    @apply .border-b-2;
    @apply .border-blue-700;
    @apply .bg-blue-200;
    @apply .text-blue-700;
  }

  &.is-disabled * {
    @apply .text-gray-400;
    @apply .cursor-not-allowed;
  }
}

.tabs-component-tab-a {
  @apply .h-full;
  @apply .px-5;
  @apply .py-3;
  @apply .flex;
  @apply .items-center;
  @apply .no-underline;
  @apply .font-medium;
}

.tabs-component-panels {
  @apply .pt-4;
  @apply .px-0;
}
</style>
