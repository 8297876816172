export default class LeaveEditMicrocopy {
  constructor(owner, requester, leaveType, hasErrors) {
    this.owner = owner
    this.requester = requester
    this.leaveType = leaveType
    this.hasErrors = hasErrors
  }

  microcopy() {
    if (this.hasErrors) {
      return this._getErrorMessage()
    }

    return this._getApprovalText()
  }

  _getErrorMessage() {
    return 'Details cannot be saved as there is an error.'
  }

  _getApprovalText() {
    if (
      this._requesterIsApprover() ||
      this._leaveTypeDoesNotRequireApproval()
    ) {
      return 'Request will be automatically approved.'
    }

    return `Saving details will not approve the request. **${this._getSuperiorNames()}** will be notified of the update.`
  }

  _requesterIsApprover() {
    return this.owner.superiors.some(
      superior => superior.id === this.requester.id
    )
  }

  _getSuperiorNames() {
    const lf = new Intl.ListFormat('en', { style: 'short' })

    return lf.format(this.owner.superiors.map(s => s.full_name))
  }

  _leaveTypeDoesNotRequireApproval() {
    return !this.leaveType.approval
  }
}
