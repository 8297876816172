import Notification from '@/components/notifications/Notification'

export default class PaymentNotification extends Notification {
  constructor(notification) {
    super(notification)
  }

  // eslint-disable-next-line no-unused-vars
  navigate(router, query) {}

  getType() {
    return 'payment'
  }

  getRedirectUrl() {
    return this.notification.data.redirect_url
  }
}
