import { flatMap, groupBy, sortBy, values } from 'lodash-es'
import Breakdown from '@/components/working-schedule/Breakdown'
import moment from 'moment-timezone'
import DailySchedule from '@/components/working-schedule/DailySchedule'

export default class WorkingSchedule {
  constructor(schedule) {
    this.schedule = schedule
  }

  get isCompanyWorkingSchedule() {
    return this.schedule.schedulable_type === 'company'
  }

  getDailySchedules() {
    return this._getWeekDays().map(
      day => new DailySchedule(day, this._getScheduleBreakdowns())
    )
  }

  findDaySchedule(date) {
    const daySchedule = this.getDailySchedules().find(
      schedule => schedule.day === date.format('dddd')
    )

    return daySchedule.shiftDate(date)
  }

  getSameSessionBreakdowns(copiedBreakdown) {
    return this._getScheduleBreakdowns()
      .filter(breakdown => breakdown.isActive())
      .filter(breakdown => {
        return breakdown.isSameSession(copiedBreakdown)
      })
  }

  getNotUpdatedBreakdowns(updatedBreakdowns) {
    return this._getScheduleBreakdowns().filter(breakdown => {
      return !updatedBreakdowns.some(
        updateBreakdown => updateBreakdown.id === breakdown.id
      )
    })
  }

  isValid() {
    return this.getDailySchedules().every(dailySchedule =>
      dailySchedule.isValid()
    )
  }

  calculateDurationInMinutes(from, to) {
    return this.findDaySchedule(from).calculateDurationInMinutes(from, to)
  }

  _getWeekDays() {
    moment.updateLocale('en', {
      week: {
        dow: 1,
      },
    })

    return moment.weekdays(true)
  }

  _getScheduleBreakdowns() {
    const groupedBreakdownsByIsoWeekDay = groupBy(
      this.schedule.schedule_breakdowns,
      'iso_week_day'
    )

    return flatMap(values(groupedBreakdownsByIsoWeekDay), breakdowns => {
      let sortedBreakdowns = sortBy(breakdowns, 'session')

      return sortedBreakdowns.map(breakdown => {
        return new Breakdown(breakdown)
      })
    })
  }
}
