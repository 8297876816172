import LeaveEvent from '@/components/requests/leave/events/LeaveEvent'

export default class UpdatedEvent extends LeaveEvent {
  constructor(event) {
    super(event)
  }

  asText(viewer) {
    if (
      this.isActorApprover() ||
      this.previousEventLeaveStatus() === 'requested'
    ) {
      return `
        **${this.getActorName(viewer)}**
        updated the request
      `
    }

    return `
        **Leave Dates** automatically retracted the approval because
        ${this.getActorName(viewer)} updated the request
      `
  }

  isActorApprover() {
    return this.event.event_properties.is_actor_approver
  }

  previousEventLeaveStatus() {
    return this.event.event_properties.previous_event_leave_status
  }

  asNotice(authUser) {
    if (this._viewerIsOwnerAndActor(authUser)) {
      return `
        Leave was updated by you on
        ${this._createdAtText(authUser)}.
      `
    }

    return `
        Leave for
        ${this._getLeaveOwnerName(authUser)}
        was updated by
        ${this.getActorName(authUser)}
        on
        ${this._createdAtText(authUser)}.
      `
  }
}
