<template>
  <span>
    <RouterLink
      v-if="canNavigate"
      :to="myYearPageLink"
      class="tw-no-underline hover:tw-underline avatar-link"
    >
      <slot></slot>
    </RouterLink>
    <span v-else class="tw-font-semibold">
      <slot></slot>
    </span>
  </span>
</template>

<script>
import Employment from '@/models/employment/Employment'

export default {
  name: 'MyYearPageLink',
  props: {
    employee: {
      type: Employment,
      required: true,
    },

    period: {
      type: String,
      default: null,
    },
  },

  computed: {
    myYearPageLink() {
      let query = {
        employee: this.employee.getKey(),
        company: this.employee.company.getKey(),
      }

      if (this.period) {
        query.period = this.period
      }

      return {
        name: 'my-year',
        query,
      }
    },

    canNavigate() {
      return this.activeEmployment.canViewAllowanceYear(this.employee)
    },
  },
}
</script>
