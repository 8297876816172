import { round } from 'lodash-es'

export default class ProratedAllowance {
  constructor(data) {
    this.id = data.id
    this.name = data.name
    this.allowanceInMinutes = data.allowance_in_minutes
    this.allowanceInAllowanceUnit = data.allowance_in_allowance_unit
    this.yearlyAllowanceInMinutes = data.yearly_allowance_in_minutes
    this.yearlyAllowanceInAllowanceUnit =
      data.yearly_allowance_in_allowance_unit
    this.allowanceUnit = data.allowance_unit
    this.minutesPerWorkingDay = data.minutes_per_working_day
    this.isProrated =
      data.allowance_in_minutes < data.yearly_allowance_in_minutes
  }

  get allowanceUnitIsDays() {
    return this.allowanceUnit === 'days'
  }

  updateAllowanceInMinutes() {
    const multiplier = this.allowanceUnitIsDays ? this.minutesPerWorkingDay : 60

    this.allowanceInMinutes = round(
      this.allowanceInAllowanceUnit * multiplier,
      2
    )
  }
}
