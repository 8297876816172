<template>
  <div
    class="tw-flex tw-items-center tw-text-base tw-font-medium tw-text-black"
  >
    {{ formattedDate }}
  </div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'RequestBreakdownDate',

  mixins: [FormatDate],

  props: {
    date: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formattedDate() {
      return this.date.format(
        this.getFormatOfDayReadableDayNumberShortMonthYear()
      )
    },
  },
}
</script>
