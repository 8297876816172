<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-flex tw-flex-col tw-flex-1">
      <ScrollableContent>
        <PortalTarget name="attachments"></PortalTarget>
      </ScrollableContent>
    </div>

    <div class="tw-my-4 tw-border-t">
      <AttachmentInput
        class="tw-mt-4 tw-flex tw-flex-col tw-justify-end"
        :current-attachments="overtime.attachments"
        :direct-upload="true"
        :unuploaded-attachments="unuploadedAttachments"
        :uploaded-attachments="uploadedAttachments"
        attachments-portal-target="attachments"
        @save-attachment="attachment => $emit('save-attachment', attachment)"
        @attachment-removed="$emit('attachment-removed')"
        @attachment-count-decreased="$emit('attachment-count-decreased')"
      />
    </div>
  </div>
</template>

<script>
import AttachmentInput from '@/components/AttachmentInput'
import ScrollableContent from '@/components/ScrollableContent'

export default {
  name: 'OvertimeAttachmentsTab',

  components: {
    ScrollableContent,
    AttachmentInput,
  },

  props: {
    overtime: {
      type: Object,
      required: true,
    },

    unuploadedAttachments: {
      type: Array,
      default: () => [],
    },

    uploadedAttachments: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
