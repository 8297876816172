<template>
  <div v-if="hasNewFeed" data-test="news-feed">
    <a
      href="https://leavedates.com/articles/whats-new"
      data-test="feed-url"
      target="_blank"
      class="tw-flex tw-no-underline tw-text-base tw-font-semibold tw-text-gray-800 tw-leading-none tw-px-6 tw-py-5"
      rel="noopener"
      @click="readFeed"
    >
      <SvgIcon name="gift" class="tw-w-6 tw-h-6" />
    </a>
  </div>
</template>

<script>
import moment from 'moment'
import EventBus from '@/plugins/event-bus'
import { Feed } from '@/api'
import { mapActions } from 'vuex'

export default {
  name: 'NewsFeed',

  data: () => ({
    hasNewFeed: false,
  }),

  mounted() {
    EventBus.$on('feed-updated', date => this.checkNewFeeds(date))
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    async checkNewFeeds(date) {
      await this.fetchUser()

      const feedLastReadAt = this.authUser.feed_last_read_at

      if (feedLastReadAt === null) {
        this.hasNewFeed = true
        return
      }

      if (date.isSameOrBefore(moment.utc(feedLastReadAt))) {
        this.hasNewFeed = false
        return
      }

      this.hasNewFeed = true
    },

    async readFeed() {
      try {
        await Feed.read()
        await this.fetchUser()

        this.hasNewFeed = false
      } catch ({ response }) {
        this.hasNewFeed = true
      }
    },
  },
}
</script>
