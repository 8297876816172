import { GrowthBook } from '@growthbook/growthbook'

const getFeaturesJson = featuresEndpoint => {
  return fetch(featuresEndpoint).then(response => {
    return response.json()
  })
}

export const GrowthBookVuePlugin = {
  install: function(Vue, { featuresEndpoint, enableDevMode = false }) {
    let growthBook = null

    growthBook = getFeaturesJson(featuresEndpoint)
      .then(json => {
        growthBook = new GrowthBook({
          enableDevMode: enableDevMode,
        })

        growthBook.setFeatures(json.features)

        return growthBook
      })
      .catch(() => {
        return null
      })

    Vue.prototype.getGrowthBookInstance = function() {
      if (growthBook) {
        return Promise.resolve(growthBook)
      }
    }
  },
}
