import http from '@/plugins/http'

export default class AllowanceTypes {
  static async get(params) {
    const { data } = await http.get('allowance-types', {
      params: params,
    })

    return data
  }
}
