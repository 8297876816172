<template>
  <div class="tw-mt-4">
    <div class="tw-relative tw-flex tw-flex-col tw-justify-end">
      <textarea
        ref="commentInput"
        v-model="comment"
        type="text"
        maxlength="2000"
        class="tw-p-4 tw-pb-12 tw-border tw-border-gray-330 focus:tw-border-blue-500 tw-w-full tw-rounded-lg tw-mb-4"
        rows="5"
        placeholder="Send a message"
        data-cy="comment-area"
        @keydown="handleCommentTypingStatus"
      />

      <button
        :disabled="commenting || !isValid"
        class="tw-mx-3 tw-mb-4 tw-px-2 tw-py-1 tw-absolute tw-bottom-0 tw-right-0 tw-bg-white tw-text-blue-500 tw-rounded"
        type="button"
        data-cy="btn-send-comment"
        @click="addComment"
      >
        Send
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentInput',

  props: {
    focus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      comment: '',
      commenting: false,
    }
  },

  computed: {
    isEmpty() {
      return this.comment.trim() === ''
    },

    isValid() {
      if (this.isEmpty) {
        return false
      }

      return this.comment.length < 2000
    },
  },

  watch: {
    focus: {
      immediate: true,
      handler(value) {
        if (value) {
          this.focusToCommentInput()
        }
      },
    },
  },

  methods: {
    handleCommentTypingStatus(e) {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 13 && this.isValid) {
        this.addComment()
      }
    },

    async addComment() {
      this.$emit('add-comment', this.comment.trim())
      this.reset()
      this.focusToCommentInput()
    },

    reset() {
      this.comment = ''
    },

    focusToCommentInput() {
      this.$refs.commentInput.focus()
    },
  },
}
</script>
