var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseFooter',{scopedSlots:_vm._u([{key:"default",fn:function({
      privacyUrl,
      termsUrl,
      cookieUrl,
      currentYear,
      helpCenterUrl,
    }){return _c('div',{staticClass:"tw-w-full"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-flex-wrap tw-px-4 tw-my-1"},[_c('span',{staticClass:"tw-text-gray-900 tw-text-sm tw-leading-loose sm:tw-leading-normal"},[_vm._v(" Copyright © "+_vm._s(currentYear)+" Leave Dates. All Rights Reserved. ")]),_c('img',{staticClass:"leavedates-whoop-strapline-horizontal",attrs:{"src":require("./../assets/img/leavedates-whoop-strapline-horizontal.svg")}}),_c('div',{staticClass:"tw-text-sm tw-space-x-8"},[_c('BaseLink',{staticClass:"tw-leading-loose sm:tw-leading-normal",attrs:{"theme":"darkerGray","href":helpCenterUrl}},[_vm._v(" Help ")]),_c('BaseLink',{staticClass:"tw-leading-loose sm:tw-leading-normal",attrs:{"theme":"darkerGray","href":cookieUrl}},[_vm._v(" Cookie Policy ")]),_c('BaseLink',{staticClass:"tw-leading-loose sm:tw-leading-normal",attrs:{"theme":"darkerGray","href":termsUrl}},[_vm._v(" Our Terms of Use ")]),_c('BaseLink',{staticClass:"tw-leading-loose sm:tw-leading-normal",attrs:{"theme":"darkerGray","href":privacyUrl}},[_vm._v(" Privacy ")])],1)])])}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }