import Vue from 'vue'
import pluralize from 'pluralize'
import { parseVulgars } from 'vulgar-fractions'

Vue.filter('pluralize', function(value, count) {
  return pluralize(value, Number(count))
})

Vue.filter('number', function(value) {
  return Number(value)
})

Vue.filter('toFraction', function(value) {
  let allowedFractions = [0.25, 0.5, 0.75]
  let decimal = value - Math.floor(value)

  if (allowedFractions.includes(decimal)) {
    return parseVulgars(value.toString()).replace(/\s/, '')
  }

  return value
})
