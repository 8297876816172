import http from '@/plugins/http'

export default class Employments {
  static get(employmentKey, params) {
    return http.get(`employments/${employmentKey}`, {
      params: params,
    })
  }

  static getCalendarServices(employmentKey, params) {
    return http.get(`employments/${employmentKey}/synchronizations`, {
      params: params,
    })
  }

  static create(payload) {
    return http.post('employments', payload)
  }

  static invite(employmentKey, email, recaptchaToken) {
    return http.post(`employments/${employmentKey}/invitations`, {
      email: email,
      'g-recaptcha-response': recaptchaToken,
    })
  }

  static find(params) {
    return http.get('employments', {
      params: params,
    })
  }

  static update(employmentKey, payload) {
    return http.put(`employments/${employmentKey}`, payload)
  }
}
