import LeaveEvent from '@/components/requests/leave/events/LeaveEvent'

export default class ApprovedEvent extends LeaveEvent {
  asNotice(viewer) {
    if (this._viewerIsOwnerAndActor(viewer)) {
      return `
        Leave request was automatically approved on
        ${this._createdAtText(viewer)}.
      `
    }

    return `
      Leave request was approved by
      ${this.getActorName(viewer)}
      on
      ${this._createdAtText(viewer)}.
      `
  }

  asText(viewer) {
    if (!this.wasAutomaticallyApproved()) {
      return `
        **${this.getActorName(viewer)}**
        approved the request
      `
    }

    if (!this._isApprovalRequired()) {
      return `
        **Leave Dates**
        automatically approved the request because
        ${this._getLeaveTypeName()} does not require approval
      `
    }

    if (this.actorIsOwner()) {
      return `
        **Leave Dates** automatically approved the request because
        ${this._getLeaveOwnerName(viewer)} can approve
        ${this._toPossessiveForm(this.getActorName(viewer))} own requests
      `
    }

    return `**Leave Dates** automatically approved the request because an approver requested it`
  }

  wasAutomaticallyApproved() {
    return this.event.event_properties.automatically_approved ?? false
  }

  _isApprovalRequired() {
    return this.event.event_properties.leave_type.approval_required ?? false
  }

  _getLeaveTypeName() {
    return this.event.event_properties.leave_type.name ?? ''
  }

  _toPossessiveForm(name) {
    if (name === 'you') {
      return 'your'
    }

    return 'their'
  }
}
