<template>
  <OvertimeBreakdown
    :minutes-per-working-day="minutesPerWorkingDay"
    :breakdown="breakdown"
    @breakdown-unit-changed="breakdownUnitChanged"
    @breakdown-amount-changed="breakdownAmountChanged"
  >
    <template
      v-slot:default="{
        overtimeUnits,
        selectedOvertimeUnit,
        changeAmount,
        changeOvertimeUnit,
        displayAmount,
      }"
    >
      <div
        class="tw-flex tw-items-center tw-justify-between tw-w-full tw-bg-white tw-rounded-lg"
      >
        <RequestBreakdownDate :date="breakdown.date" />
        <div
          class="tw--mr-1 tw-py-2 tw-flex tw-items-center tw-justify-end tw-space-x-4"
        >
          <div class="tw-flex tw-items-center tw-justify-center tw-space-x-2">
            <input
              class="form-control"
              type="number"
              min="0"
              step="any"
              data-cy=""
              style="width: 74px;"
              :value="displayAmount"
              @input="changeAmount"
            />
            <VSelect
              id="overtime_unit_type"
              :value="selectedOvertimeUnit"
              class="tw-w-24"
              :options="overtimeUnits"
              :multiple="false"
              :searchable="false"
              :show-labels="false"
              :allow-empty="false"
              track-by="key"
              label="label"
              placeholder=""
              @input="changeOvertimeUnit"
            >
            </VSelect>
          </div>
          <div class="tw-space-x-2 tw-flex tw-items-center tw-justify-center">
            <button
              class="tw-flex tw-items-center tw-justify-center tw-rounded tw-bg-blue-550 tw-text-white"
              type="button"
              style="padding: 0.914rem;"
              @click="updateBreakdown"
            >
              <svg-icon name="checkmark" class="tw-w-5 tw-h-5 tw-text-white" />
            </button>

            <button
              class="tw-flex tw-items-center tw-justify-center tw-border tw-border-gray-330 tw-rounded tw-bg-white tw-text-gray-660"
              type="button"
              style="padding: 0.837rem;"
              @click="closeBreakdownEditor"
            >
              <svg-icon name="close" class="tw-w-5 tw-h-5 tw-text-gray-660" />
            </button>
          </div>
        </div>
      </div>
    </template>
  </OvertimeBreakdown>
</template>

<script>
import vSelect from 'vue-multiselect'
import RequestBreakdownDate from '@/components/requests/RequestBreakdownDate'
import OvertimeBreakdown from '@/components/requests/overtime/OvetimeBreakdown'

export default {
  name: 'OvertimeBreakdownEditor',

  components: {
    VSelect: vSelect,
    RequestBreakdownDate,
    OvertimeBreakdown,
  },

  props: {
    breakdown: {
      type: Object,
      required: true,
    },

    minutesPerWorkingDay: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      previousBreakdown: null,
    }
  },

  created() {
    this.previousBreakdown = this.breakdown.clone()
  },

  methods: {
    breakdownAmountChanged(breakdown) {
      this.$emit('breakdown-amount-changed', breakdown)
    },

    breakdownUnitChanged(breakdown) {
      this.$emit('breakdown-unit-changed', breakdown)
    },

    closeBreakdownEditor() {
      this.$emit('close-breakdown-editor', this.previousBreakdown)
    },

    updateBreakdown() {
      this.$emit('update-overtime-breakdown', this.previousBreakdown)
    },
  },
}
</script>
