<template>
  <div>
    <Loading
      class="tw-outline-none"
      :blur="null"
      loader="dots"
      :is-full-page="false"
      :active="isPageLoading"
    />

    <div v-if="!isPageLoading" class="tw-flex tw-flex-1 tw-flex-col">
      <Loading
        class="tw-outline-none"
        :blur="null"
        loader="dots"
        :is-full-page="false"
        :active="isDrafting || isSaving"
      />
      <form class="tw-w-full tw-flex" novalidate>
        <InputGroup
          class="tw-w-2/3 tw-mb-0"
          label="Change dates"
          :required="true"
          :has-error="errors.has('dateRange')"
          :input-error="errors.first('dateRange')"
        >
          <DateRangePicker
            :value="form.date_range"
            :min-date="selectableMinDate"
            :max-date="selectableMaxDate"
            :format="dateFormat"
            data-cy="leave-date-cal"
            class="form-control"
            tabindex="1"
            @input="setDateRange"
          />
        </InputGroup>

        <InputGroup
          label="Change leave type"
          :required="true"
          :has-error="errors.has('leaveType')"
          :input-error="errors.first('leaveType')"
          class="tw-w-1/3 tw-ml-3 tw-mb-0"
        >
          <div class="tw-flex tw-items-center">
            <RequestTypePicker
              v-validate:selectedLeaveType="'required'"
              :value="selectedLeaveType"
              :options="leaveTypes"
              data-vv-name="type"
              data-vv-as="leave type"
              data-cy="leave-type"
              tabindex="2"
              @input="setLeaveType"
            />
          </div>
        </InputGroup>
      </form>

      <CrossAllowanceYearBoundaryError
        v-if="hasCrossedAllowanceYearBoundaries"
      />

      <RequestLeaveOnPastDateWarning
        v-if="!hasCrossedAllowanceYearBoundaries && isLeaveRequestedOnPastDate"
      />

      <UnlimitedAllowanceTypeInfo
        v-if="selectedLeaveTypeDoesntHaveAllowances"
        :leave-type="selectedLeaveType"
      />

      <RemainingAllowanceExceededError
        v-if="hasRemainingAllowancesExceeded"
        :owner="owner"
        :viewer="activeEmployment"
      />

      <ScrollableContent class="tw-mt-3">
        <EditLeaveBreakdown
          v-for="breakdown in draftedLeave.breakdowns"
          :key="breakdown.key"
          :breakdown="breakdown"
          :owner="leave.owner"
          @duration-selected="handleDurationSelected"
          @show-overlap-leave-details="
            leaveKey => $emit('show-overlap-leave-details', leaveKey)
          "
        />
      </ScrollableContent>

      <div class="tw-my-4 tw-text-right">
        <LeaveRequestBreakdownTotalText
          :total-durations-in-units="draftedLeave.totalSelectedDurationsInUnits"
          :minutes-per-working-day="leave.getMinutesPerWorkingDay()"
        />
        <LeaveTypeLabel :leave="leave" />
      </div>

      <Portal to="leave-edit-actions">
        <SpinnerButton
          :disabled="isSaving"
          theme="white"
          type="button"
          class="tw-mr-2"
          data-cy="btn-cancel-leave"
          @click="$emit('quit-edit-mode')"
        >
          Cancel
        </SpinnerButton>

        <SpinnerButton
          :disabled="hasErrors || isSaving"
          :loading="isSaving"
          theme="default"
          type="button"
          data-cy="btn-save-leave"
          @click="saveLeave"
        >
          Save
        </SpinnerButton>
      </Portal>

      <Portal to="leave-edit-microcopy">
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="microcopyText" />
        <!-- eslint-enable -->
      </Portal>
    </div>
  </div>
</template>

<script>
import InputGroup from '@/components/InputGroup'
import RequestTypePicker from '@/components/pickers/RequestTypePicker'
import ValidatesForm from '@/mixins/ValidatesForm'
import { Employments, Leaves, LeaveTypes } from '@/api'
import DateRangePicker from '@/components/DateRangePicker'
import FormatDate from '@/mixins/FormatDate'
import moment from 'moment-timezone'
import EditLeaveBreakdown from '@/components/requests/leave/EditLeaveBreakdown'
import { maxBy, minBy, reject } from 'lodash-es'
import CrossAllowanceYearBoundaryError from '@/components/requests/CrossAllowanceYearBoundaryError'
import LeaveTypeLabel from '@/components/requests/leave/LeaveTypeLabel'
import RequestLeaveOnPastDateWarning from '@/components/requests/leave/RequestLeaveOnPastDateWarning'
import HandleWorkingSchedule from '@/mixins/HandleWorkingSchedule'
import UnlimitedAllowanceTypeInfo from '@/components/requests/leave/UnlimitedAllowanceTypeInfo'
import SpinnerButton from '@/components/SpinnerButton'
import RemainingAllowanceExceededError from '@/components/requests/leave/RemainingAllowanceExceededError'
import LeaveEditMicrocopy from '@/components/requests/leave/LeaveEditMicrocopy'
import Loading from 'vue-loading-overlay'
import LeaveRequestBreakdownTotalText from '@/components/requests/leave/LeaveRequestBreakdownTotalText'
import Leave from '@/models/leave/Leave'
import ScrollableContent from '@/components/ScrollableContent'
import EventBus from '@/plugins/event-bus'
import Employment from '@/models/employment/Employment'

export default {
  name: 'EditLeaveDetails',

  components: {
    ScrollableContent,
    Loading,
    InputGroup,
    SpinnerButton,
    LeaveTypeLabel,
    RequestTypePicker,
    DateRangePicker,
    EditLeaveBreakdown,
    RequestLeaveOnPastDateWarning,
    CrossAllowanceYearBoundaryError,
    UnlimitedAllowanceTypeInfo,
    RemainingAllowanceExceededError,
    LeaveRequestBreakdownTotalText,
  },

  mixins: [ValidatesForm, FormatDate, HandleWorkingSchedule],

  props: {
    leave: {
      type: Leave,
      required: true,
    },
  },

  data() {
    return {
      leaveTypes: [],
      form: {
        type_id: null,
        date_range: [moment(), moment()],
      },
      draftedLeave: null,
      userSelectedDurationOptions: {},
      selectedBreakdowns: [],
      isSaving: false,
      owner: null,
      isPageLoading: true,
      isDrafting: false,
    }
  },

  computed: {
    hasErrors() {
      return (
        this.hasRemainingAllowancesExceeded ||
        this.hasCrossedAllowanceYearBoundaries ||
        this.overlapWithExistingLeave
      )
    },

    microcopyText() {
      let textArray = new LeaveEditMicrocopy(
        this.owner,
        this.activeEmployment,
        this.selectedLeaveType,
        this.hasErrors
      )
        .microcopy()
        .split('**')

      let microcopyText = textArray[0]
      if (textArray.length === 3) {
        microcopyText +=
          ` <span class="tw-text-gray-700 tw-font-semibold">` +
          textArray[1] +
          `</span>` +
          textArray[2]
      }

      return microcopyText
    },

    selectedLeaveTypeDoesntHaveAllowances() {
      return this.draftedLeave?.selectedLeaveTypeDoesntHaveAllowances
    },

    isLeaveRequestedOnPastDate() {
      return this.draftedLeave?.containPastDates
    },

    hasCrossedAllowanceYearBoundaries() {
      return this.draftedLeave?.datesOverlapOnDifferentCalendars
    },

    hasRemainingAllowancesExceeded() {
      return this.draftedLeave?.hasRemainingAllowancesExceeded
    },

    overlapWithExistingLeave() {
      return this.draftedLeave?.overlapWithExistingLeave
    },

    selectedLeaveType() {
      return this.leaveTypes.find(
        leaveType => leaveType.id === this.form.type_id
      )
    },

    selectableMinDate() {
      if (this.isPageLoading) {
        return null
      }

      return this.owner?.start_date ?? this.minimumVisibleCalendar.start_date
    },

    selectableMaxDate() {
      if (this.isPageLoading) {
        return null
      }

      return this.owner?.end_date ?? this.maximumVisibleCalendar.end_date
    },

    minimumVisibleCalendar() {
      return minBy(this.visibleCalendars, 'start_date')
    },

    maximumVisibleCalendar() {
      return maxBy(this.visibleCalendars, 'end_date')
    },

    visibleCalendars() {
      return reject(this.owner.company.calendars, 'hidden')
    },

    dateFormat() {
      return this.getFormatOfDayReadableDayNumberShortMonthYear()
    },

    hasHoursPerWorkingDayExceeded() {
      return this.draftedLeave.containsHoursPerWorkingDayExceededWarning
    },

    isLeaveLimitReached() {
      return this.draftedLeave.containsLeaveLimitReachedWarning
    },
  },

  watch: {
    hasErrors(value) {
      this.$emit('has-leave-edit-errors', value)
    },
  },

  async created() {
    this.form.type_id = this.leave.getTypeKey()

    const timezone = this.leave.timezone
    this.form.date_range[0] = moment.utc(this.leave.from).tz(timezone)
    this.form.date_range[1] = moment.utc(this.leave.to).tz(timezone)

    await Promise.all([this.fetchOwner(), this.fetchLeaveTypes()])

    this.leave.setOwner(this.owner)

    await this.makeDraftLeave()

    this.setSelectedBreakdowns()

    this.isPageLoading = false
  },

  methods: {
    async fetchOwner() {
      const { data } = await Employments.get(this.leave.getOwnerKey(), {
        company_id: this.activeCompany.id,
      })

      this.owner = new Employment(data)
    },

    async fetchLeaveTypes() {
      const { data } = await LeaveTypes.all(this.$route.query)

      this.leaveTypes = data
    },

    setSelectedBreakdowns() {
      this.selectedBreakdowns = [...this.draftedLeave.breakdowns]
    },

    setLeaveType(leaveType) {
      this.form.type_id = leaveType.id

      this.validateLeaveBreakdowns()
    },

    setDateRange(dateRange) {
      this.form.date_range = dateRange.map(date => date.clone())

      this.makeBreakdowns()
    },

    handleDurationSelected(breakdown) {
      this.selectedBreakdowns = [
        ...this.draftedLeave.breakdowns.map(originalBreakdown => {
          if (originalBreakdown.key === breakdown.key) {
            return breakdown
          }

          return originalBreakdown
        }),
      ]

      this.validateLeaveBreakdowns()
    },

    async makeDraftLeave() {
      await this.redraftLeave({
        from: this.form.date_range[0],
        to: this.form.date_range[1],
        leave_breakdowns: [this.leave.getBreakdownsToRedraft()],
      })
    },

    validateLeaveBreakdowns() {
      this.redraftLeave({
        from: this.draftedLeave.findMinimumFromTime(this.selectedBreakdowns),
        to: this.draftedLeave.findMaximumToTime(this.selectedBreakdowns),
        leave_breakdowns: this.draftedLeave.all.map(draftedLeave => {
          return {
            owner_id: draftedLeave.owner.getKey(),
            breakdowns: draftedLeave.breakdowns.map(draftedBreakdown => {
              const breakdown = this.selectedBreakdowns.find(breakdown =>
                breakdown.isSameDate(draftedBreakdown.date, 'date')
              )

              return breakdown.getBreakdownToRequest()
            }),
          }
        }),
      })
    },

    makeBreakdowns() {
      this.redraftLeave({
        from: this.form.date_range[0],
        to: this.form.date_range[1],
        owner_id: this.leave.getOwnerKey(),
      })
    },

    async redraftLeave(overrides) {
      this.isDrafting = true

      this.draftedLeave = await Leaves.redraft(this.leave, {
        company_id: this.activeCompany.id,
        type_id: this.form.type_id,
        ...overrides,
      })

      this.setSelectedBreakdowns()

      this.isDrafting = false
    },

    async saveLeave() {
      await this.validate()

      if (!this.valid || this.hasRemainingAllowancesExceeded) {
        return
      }

      if (this.isLeaveLimitReached) {
        const confirmed = await this.confirm(
          'There is an issue with your request. Would you like to submit anyway?',
          'Warning'
        )

        if (!confirmed) return
      }

      if (this.hasHoursPerWorkingDayExceeded) {
        const confirmed = await this.confirm(
          'There is an issue with your request. Would you like to submit anyway?',
          'Warning'
        )

        if (!confirmed) return
      }

      this.isSaving = true
      try {
        await Leaves.update(this.leave.getKey(), {
          company_id: this.activeCompany.id,
          type_id: this.form.type_id,
          from: this.draftedLeave.findMinimumFromTime(this.selectedBreakdowns),
          to: this.draftedLeave.findMaximumToTime(this.selectedBreakdowns),
          leave_breakdowns: this.draftedLeave.breakdowns.map(breakdown =>
            breakdown.getBreakdownToRequest()
          ),
          is_private: this.leave.isPrivate(),
          reason: this.leave.reason,
        })

        this.success('Leave updated successfully!')
        this.$emit('leave-updated')

        EventBus.$emit('leave-updated')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.isSaving = false
    },
  },
}
</script>
