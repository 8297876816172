export default class Info {
  constructor(info) {
    this.info = info
  }

  get theme() {
    return 'info'
  }

  get isHoliday() {
    return this.info.code === 'holiday'
  }

  get isInvalidBreakdown() {
    return this.info.code === 'invalid_breakdown_provided'
  }

  get description() {
    return this.info.description
  }
}
