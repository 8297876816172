<template>
  <div class="form-group tw-block">
    <BaseInputLabel v-if="label" :for="labelFor" :required-field="required">
      {{ label }}
    </BaseInputLabel>

    <slot></slot>

    <BaseInputError v-if="hasError" class="tw-mt-1">
      {{ inputError }}
    </BaseInputError>
  </div>
</template>

<script>
import BaseInputLabel from '@/components/BaseInputLabel'
import BaseInputError from '@/components/BaseInputError'

export default {
  name: 'InputGroup',

  components: {
    BaseInputLabel,
    BaseInputError,
  },

  props: {
    required: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    inputError: {
      type: String,
      default: null,
    },

    labelFor: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },
  },
}
</script>
