<template>
  <div class="tw-flex tw-flex-col tw-flex-1">
    <Loading
      class="tw-outline-none"
      :blur="null"
      loader="dots"
      :is-full-page="false"
      :active="isProcessing"
    />

    <div v-if="leave && !loading" class="tw-flex tw-flex-col tw-flex-1">
      <PanelHeader @close-panel="closePanel">
        <div class="tw-flex tw-items-center">
          <BackgroundIcon name="clock-outline" />

          <span class="tw-font-medium tw-text-xl tw-ml-4">
            Leave Request for

            <MyYearPageLink :employee="leave.owner" :period="leavePeriod">
              {{ leave.getOwnerFullName() }}
            </MyYearPageLink>
          </span>

          <PrivateLabel
            v-if="leave.isPrivate()"
            class="tw-ml-3 tw-flex tw-items-center"
          />
          <RequestStatusLabel
            :status="leave.status"
            class="tw-ml-3 tw-flex tw-items-center"
          />
        </div>
      </PanelHeader>

      <TabsWrapper
        ref="tabs"
        class="tw-px-8 tw-pt-5 tw-overflow-y-auto"
        @changed="handleTabChange"
      >
        <BaseTab
          id="summary"
          name="Summary"
          :is-large-footer-visible="leave.isApproved()"
        >
          <LeaveSummaryTab
            :leave="leave"
            :employment-allowances="employmentAllowances"
            :timeline="timeline"
            :is-allowance-calculating="isProcessing"
            @show-more-details="changeTab('#updates')"
            @show-overlap-leave-details="showOverlapLeaveDetails"
          />
        </BaseTab>

        <BaseTab
          v-if="canSeeMoreDetails"
          id="details"
          name="Details"
          class="tw-flex tw-flex-col"
        >
          <template v-slot="props">
            <LeaveDetailsTab
              :leave="leave"
              :is-leave-editing="isLeaveEditing"
              :is-tab-active="props.isActive"
              @show-overlap-leave-details="showOverlapLeaveDetails"
              @quit-leave-edit-mode="disableLeaveEditMode"
              @leave-updated="handleLeaveUpdatedAction"
            />
          </template>
        </BaseTab>

        <BaseTab
          v-if="canSeeMoreDetails"
          id="attachments"
          name="Attachments"
          :suffix="attachmentsSuffix"
          :is-large-footer-visible="leave.isApproved()"
          data-cy="attachments"
        >
          <template #header>
            <RequestTabHeader header="Attachments" :count="attachmentsCount" />
          </template>

          <LeaveAttachmentsTab
            :leave="leave"
            @attachment-added="fetchLeaveAndTimeline"
            @attachment-removed="fetchLeaveAndTimeline"
          />
        </BaseTab>

        <BaseTab
          v-if="canSeeMoreDetails"
          id="updates"
          name="Updates"
          :suffix="updatesSuffix"
          :is-large-footer-visible="leave.isApproved()"
        >
          <template #header>
            <RequestTabHeader header="Updates" :count="eventsCount" />
          </template>

          <template v-slot="props">
            <LeaveUpdatesTab
              :timeline="timeline"
              :leave="leave"
              :tab-active="props.isActive"
              @comment-added="fetchLeaveAndTimeline"
              @comment-deleted="fetchLeaveAndTimeline"
              @comment-restored="fetchLeaveAndTimeline"
            />
          </template>
        </BaseTab>
      </TabsWrapper>

      <PanelFooter>
        <Component
          :is="alertComponent"
          v-if="alertComponent && !isLeaveEditing"
          class="tw-mb-4"
          :leave="leave"
          :event="lastLeaveEvent"
          @retract="retract"
        />

        <div
          v-if="
            leave.canApprove(activeEmployment) ||
              leave.canCancel(activeEmployment) ||
              leave.canEdit(activeEmployment)
          "
          class="tw-flex tw-items-center tw-mb-2"
          :class="isLeaveEditing ? 'tw-justify-end' : 'tw-justify-between'"
        >
          <SpinnerButton
            v-if="
              !isLeaveEditing && !leave.isCancelled() && !leave.isRejected()
            "
            theme="white"
            type="button"
            cypress-attribute="btn-edit-leave"
            @click="enableLeaveEditMode"
          >
            Edit Leave
          </SpinnerButton>

          <div
            v-if="leave.canApprove(activeEmployment) && !isLeaveEditing"
            class="tw-flex"
          >
            <SpinnerButton
              :disabled="isProcessing"
              :loading="cancelling"
              :spinner-only="true"
              theme="red"
              type="button"
              data-cy="btn-decline-leave"
              @click="cancel"
            >
              Decline
            </SpinnerButton>

            <SpinnerButton
              :disabled="isProcessing"
              :loading="approving"
              :spinner-only="true"
              :spinner-classes="['tw-h-2 tw-w-2 tw-text-green-500']"
              class="tw-ml-3"
              theme="green"
              type="button"
              data-cy="btn-approve-leave"
              @click="approve"
            >
              Approve
            </SpinnerButton>
          </div>

          <div
            v-else-if="
              leave.canCancel(activeEmployment) &&
                !(leave.isCancelled() || leave.isRejected()) &&
                !isLeaveEditing
            "
            class="tw-space-y-3"
          >
            <SpinnerButton
              :disabled="isProcessing"
              :loading="cancelling"
              :spinner-only="true"
              theme="red"
              type="button"
              data-cy="btn-cancel-leave"
              @click="cancel"
            >
              Cancel Request
            </SpinnerButton>
          </div>

          <PortalTarget
            v-if="leave.canEdit(activeEmployment) && isLeaveEditing"
            name="leave-edit-actions"
            class="tw-flex"
          />
        </div>

        <div
          class="tw-float-right tw-text-sm tw-text-gray-555"
          data-cy="micro-copy-text"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span v-if="!loading && !isLeaveEditing" v-html="microcopyText" />
          <PortalTarget v-else name="leave-edit-microcopy" />
          <!-- eslint-enable -->
        </div>
      </PanelFooter>
    </div>
  </div>
</template>

<script>
import BaseTab from '@/components/BaseTab'
import SpinnerButton from '@/components/SpinnerButton'
import PanelHeader from '@/components/PanelHeader'
import PanelFooter from '@/components/PanelFooter'
import TabsWrapper from '@/components/TabsWrapper'
import BackgroundIcon from '@/components/BackgroundIcon'
import ValidatesForm from '@/mixins/ValidatesForm'
import Loading from 'vue-loading-overlay'
import LeaveSummaryTab from '@/components/requests/leave/LeaveSummaryTab'
import LeaveDetailsTab from '@/components/requests/leave/LeaveDetailsTab'
import { AllowanceReport, Employments, Leaves } from '@/api'
import ApprovedAlert from '@/components/requests/leave/ApprovedAlert'
import RequestedAlert from '@/components/requests/leave/RequestedAlert.vue'
import CancelledAlert from '@/components/requests/leave/CancelledAlert'
import LeaveDetailsMicrocopy from '@/components/requests/leave/LeaveDetailsMicrocopy'
import DeclinedAlert from '@/components/requests/leave/DeclinedAlert'
import LeaveUpdatesTab from '@/components/requests/leave/LeaveUpdatesTab'
import Promise from 'lodash-es/_Promise'
import LeaveAttachmentsTab from '@/components/requests/leave/LeaveAttachmentsTab'
import EventBus from '@/plugins/event-bus'
import Employment from '@/models/employment/Employment'
import CancelConfirmation from '@/components/requests/leave/CancelConfirmation'
import RequestStatusLabel from '@/components/requests/RequestStatusLabel'
import MyYearPageLink from '@/components/MyYearPageLink'
import { omit } from 'lodash-es'
import PrivateLabel from '@/components/PrivateLabel'
import RequestTabHeader from '@/components/requests/RequestTabHeader'

export default {
  name: 'LeaveDetailsPanel',

  components: {
    RequestStatusLabel,
    PrivateLabel,
    MyYearPageLink,
    LeaveAttachmentsTab,
    LeaveUpdatesTab,
    DeclinedAlert,
    CancelledAlert,
    ApprovedAlert,
    Loading,
    BaseTab,
    SpinnerButton,
    PanelHeader,
    PanelFooter,
    TabsWrapper,
    BackgroundIcon,
    LeaveSummaryTab,
    LeaveDetailsTab,
    RequestedAlert,
    RequestTabHeader,
  },

  mixins: [ValidatesForm],

  props: {
    leaveKey: {
      type: String,
      required: true,
    },

    activeTab: {
      type: String,
      default: '#summary',
    },
  },

  data() {
    return {
      employmentAllowances: null,
      loading: true,
      approving: false,
      cancelling: false,
      retracting: false,
      isUpdatesTabActive: false,
      timeline: {},
      isLeaveEditing: false,
      leave: null,
    }
  },

  computed: {
    isProcessing() {
      return (
        this.loading || this.approving || this.cancelling || this.retracting
      )
    },

    leavePeriod() {
      return this.leave.getPeriod()
    },

    alertComponent() {
      const alertsMap = {
        isApproved: ApprovedAlert,
        isCancelled: CancelledAlert,
        isRejected: DeclinedAlert,
      }

      if (this.isProcessing) {
        return null
      }

      if (
        this.leave.isRequested() &&
        !this.leave.canApprove(this.activeEmployment)
      ) {
        return RequestedAlert
      }

      const alertState = Object.keys(alertsMap).find(state =>
        this.leave[state]()
      )

      return alertState ? alertsMap[alertState] : null
    },

    attachmentsSuffix() {
      return `<span class='${this.suffixClasses}'>
                ${this.leave.getAttachmentCount()}
              </span>`
    },

    updatesSuffix() {
      return `<span class='${this.suffixClasses}'>0</span>`
    },

    suffixClasses() {
      return 'tw-text-sm tw-bg-gray-300 tw-ml-2 tw-py-1 tw-px-2 tw-rounded-full md:tw-inline-block'
    },

    lastLeaveEvent() {
      return this.timeline.getLastLeaveEvent()
    },

    microcopyText() {
      const text = new LeaveDetailsMicrocopy(
        this.leave,
        this.activeEmployment,
        this.employmentAllowances.first()
      ).microcopy.split('**')

      if (text.length === 1) {
        return ''
      }

      let microcopyText =
        text[0] +
        ` <span class="tw-text-gray-700 tw-font-semibold">` +
        text[1] +
        `</span>`

      if (text.length === 3) {
        microcopyText += text[2]
      }

      return microcopyText
    },

    canSeeMoreDetails() {
      return this.activeEmployment.hasPermissionsToSeeMoreDetails(
        this.leave.owner
      )
    },

    attachmentsCount() {
      return this.leave ? this.leave.getAttachments().length : 0
    },

    eventsCount() {
      return this.timeline.events.length
    },
  },

  async created() {
    await this.fetchLeave()

    if (!this.leave) {
      this.closePanel()

      return
    }

    await this.fetchEmploymentAllowances()
    await this.fetchTimelineEvents()

    this.loading = false

    this.changeTab(this.activeTab)
  },

  methods: {
    async handleLeaveUpdatedAction() {
      this.isLeaveEditing = false
      this.loading = true

      await this.fetchLeaveTimelineAndEmploymentAllowances()

      this.loading = false
    },

    enableLeaveEditMode() {
      this.isLeaveEditing = true
      this.changeTab('#details')
    },

    disableLeaveEditMode() {
      this.isLeaveEditing = false
    },

    handleTabChange({ tab }) {
      this.isUpdatesTabActive = tab.hash === '#updates'
    },

    changeTab(tabHash) {
      this.$nextTick(() => this.$refs.tabs.selectTab(tabHash))
    },

    async approve() {
      this.approving = true

      await Leaves.approve(this.leave.getKey(), {
        company: this.activeCompany.id,
      })
      await this.fetchLeaveTimelineAndEmploymentAllowances()
      EventBus.$emit('leave-approved')

      this.approving = false
    },

    async cancel() {
      const confirmation = new CancelConfirmation(
        this.leave,
        this.activeEmployment
      ).message

      const confirmed = await this.confirm(
        confirmation.body,
        confirmation.title
      )

      if (!confirmed) return

      this.cancelling = true

      await Leaves.cancel(this.leave.getKey(), {
        company: this.activeCompany.id,
      })
      await this.fetchLeaveTimelineAndEmploymentAllowances()
      EventBus.$emit('leave-cancelled')

      this.cancelling = false
    },

    async retract() {
      this.retracting = true

      await Leaves.retract(this.leave.getKey(), {
        company: this.activeCompany.id,
      })
      await this.fetchLeaveTimelineAndEmploymentAllowances()
      EventBus.$emit('leave-retracted')

      this.retracting = false
    },

    async fetchLeaveTimelineAndEmploymentAllowances() {
      await this.fetchLeaveAndTimeline()
      await this.fetchEmploymentAllowances()
    },

    async fetchEmploymentAllowances() {
      let params = {
        company_id: this.activeCompany.id,
        allowance_type: this.leave.getAllowanceTypeKey(),
        employee: this.leave.getOwnerKey(),
        per_page: 1,
      }

      if (this.leave.isCancelled() || this.leave.isRejected()) {
        params.period = this.leave.getPeriod()
      } else {
        params.date = this.leave.end.format('YYYY-MM-DD')
      }

      this.employmentAllowances = (
        await AllowanceReport.getDetailsAllowances(params)
      ).items()
    },

    closePanel() {
      this.$router.push({
        query: {
          ...omit(this.$route.query, ['leave-request']),
        },
      })
    },

    showOverlapLeaveDetails(leaveKey) {
      this.$router.push({
        query: {
          ...this.$route.query,
          'leave-request': leaveKey,
        },
      })
    },

    fetchLeaveAndTimeline() {
      return Promise.all([this.fetchLeave(), this.fetchTimelineEvents()])
    },

    async fetchLeave() {
      try {
        this.leave = await Leaves.get(this.leaveKey, this.activeCompany)

        if (this.leave.isRequested()) {
          const { data } = await Employments.get(this.leave.owner_id, {
            company_id: this.activeCompany.id,
          })

          this.leave.setOwner(new Employment(data))
        }
      } catch ({ response }) {
        this.validateFromResponse(response, true)
      }
    },

    async fetchTimelineEvents() {
      this.timeline = await Leaves.getTimeline(this.leave.getKey(), {
        company: this.activeCompany.id,
      })
    },
  },
}
</script>
