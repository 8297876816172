export default {
  data: () => ({
    featureFlagService: null,
    featureFlagServiceLoading: true,
  }),

  methods: {
    isOn(flagName) {
      if (this.featureFlagService) {
        return this.featureFlagService.isOn(flagName)
      }
      return false
    },
    isOff(flagName) {
      if (this.featureFlagService) {
        return this.featureFlagService.isOff(flagName)
      }
      return true
    },
  },

  beforeMount() {
    this.featureFlagServiceLoading = true
    this.getGrowthBookInstance().then(growthBook => {
      if (!growthBook) {
        return
      }

      let companyId = null
      let employeeId = null
      let userId = null

      try {
        if (this.activeCompany.id != null) {
          companyId = this.activeCompany.id
        }
        if (this.activeEmployment.id != null) {
          employeeId = this.activeEmployment.id
        }
        if (this.authUser.id != null) {
          userId = this.authUser.id
        }
      } catch (error) {
        // Ignore errors, set to null
      }

      growthBook.setAttributes({
        company_id: companyId,
        employee_id: employeeId,
        user_id: userId,
      })

      this.featureFlagService = growthBook
    })
    this.featureFlagServiceLoading = false
  },
}
