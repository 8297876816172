<template>
  <div class="tw-flex tw-justify-between tw-items-center">
    <div>
      <LeaveBreakdownDeductionInfo
        v-if="showCustomDuration"
        :deduction="breakdownDuration.deduction"
        :interval="breakdownDuration.interval"
      />
    </div>

    <div class="tw-flex tw-items-center custom-options">
      <vSelect
        v-model="start"
        class="ld_v-select rtl"
        :options="generator.getStartOptions()"
        :multiple="false"
        :searchable="false"
        :show-labels="false"
        :allow-empty="false"
        track-by="key"
        label="label"
        placeholder=""
        data-cy="select-start-time"
        :custom-label="getLabels"
        :max-height="optionHeight"
        @open="showDurationInLabels"
        @close="hideDurationInLabels"
      />

      <span class="tw-mx-3">to</span>

      <vSelect
        v-model="end"
        class="ld_v-select rtl"
        :options="generator.getEndOptions()"
        :multiple="false"
        :searchable="false"
        :show-labels="false"
        :allow-empty="false"
        track-by="key"
        placeholder=""
        data-cy="select-end-time"
        :custom-label="getLabels"
        :max-height="optionHeight"
        @open="showDurationInLabels"
        @close="hideDurationInLabels"
      />
    </div>
  </div>
</template>

<script>
import pluralize from 'pluralize'
import { round } from 'lodash-es'
import vSelect from 'vue-multiselect'
import LeaveBreakdownCustomOptionGenerator from '@/components/requests/leave/LeaveBreakdownCustomOptionGenerator'
import moment from 'moment-timezone'
import LeaveBreakdownDeductionInfo from '@/components/requests/leave/LeaveBreakdownDeductionInfo'
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'LeaveBreakdownCustomOptions',
  components: {
    vSelect,
    LeaveBreakdownDeductionInfo,
  },

  mixins: [FormatDate],

  props: {
    from: {
      type: moment,
      required: true,
    },

    to: {
      type: moment,
      required: true,
    },

    breakdownDuration: {
      type: Object,
      default: null,
    },

    showCustomDuration: {
      type: Boolean,
      default: true,
    },

    employments: {
      type: Array,
      default: () => [],
    },

    isSingleEmploymentRequest: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      displayDuration: false,
      start: this.makeOption(this.from),
      end: this.makeOption(this.to),
    }
  },

  computed: {
    optionHeight() {
      return 180
    },

    duration() {
      return this.generator.getSelectedEndTimeOption().duration
    },

    generator() {
      let workingSchedule = this.isSingleEmploymentRequest
        ? this.employments[0].workingSchedule
        : {
            id: this.activeCompany.active_working_schedule.id,
            breakdowns: this.activeCompany.active_working_schedule
              .schedule_breakdowns,
          }

      return new LeaveBreakdownCustomOptionGenerator(
        {
          start: this.start.time,
          end: this.end.time,
        },
        workingSchedule
      )
    },

    updatedTime() {
      return {
        from: this.start.time,
        to: this.end.time,
      }
    },
  },

  watch: {
    start() {
      this.emmitUpdatedTime()
    },

    end() {
      this.emmitUpdatedTime()
    },
  },

  methods: {
    makeOption(time) {
      return {
        key: time.format('YYYYMMDDHHmmss'),
        time: time,
        duration: null,
      }
    },

    getLabels(option) {
      const formattedTime = this.formatDateFromIsoToHourMinutes(option.time)

      if (option.duration && this.displayDuration) {
        let durationString = ''

        const hours = option.duration.asHours()
        const minutes = option.duration.minutes()
        if (Number.parseInt(hours) === 0) {
          durationString = `(${minutes} ${pluralize('mins', minutes)})`
        } else {
          durationString = `(${this.$options.filters.toFraction(
            round(hours, 2)
          )} ${pluralize('hrs', hours)})`
        }

        return `${formattedTime} ${durationString}`
      }

      return formattedTime
    },

    async showDurationInLabels() {
      this.displayDuration = true

      await this.$nextTick()

      document
        .querySelectorAll('.custom-options .multiselect__option--selected')
        .forEach(e => e?.scrollIntoView())
    },

    hideDurationInLabels() {
      this.displayDuration = false
    },

    emmitUpdatedTime() {
      this.$emit('time-updated', this.updatedTime)
    },
  },
}
</script>
