export default class AllowanceType {
  constructor(allowanceType) {
    this.id = allowanceType.id
    this.name = allowanceType.name
  }

  isSame(allowanceTypeKey) {
    return this.id === allowanceTypeKey
  }
}
