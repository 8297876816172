<template>
  <span class="label label-primary tw-capitalize" :class="colour">
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'RequestStatusLabel',

  props: {
    status: {
      type: String,
      required: true,
    },
  },

  computed: {
    text() {
      let status = ''

      switch (this.status.toLowerCase()) {
        case 'requested':
          status = 'pending'
          break
        case 'approved':
          status = 'approved'
          break
        case 'rejected':
          status = 'declined'
          break
        case 'cancelled':
          status = 'cancelled'
          break
      }

      return status
    },

    colour() {
      let colour = ''

      switch (this.status.toLowerCase()) {
        case 'requested':
          colour = 'label-awaiting-approval'
          break
        case 'approved':
          colour = 'label-approved'
          break
        case 'rejected':
          colour = 'label-declined'
          break
        case 'cancelled':
          colour = 'label-canceled'
          break
      }

      return colour
    },
  },
}
</script>
