import Notification from '@/components/notifications/Notification'

export default class SynchronizationNotification extends Notification {
  // eslint-disable-next-line no-unused-vars
  navigate(router, query) {
    router.push({ name: 'integrations' }, () => {})
  }

  getMessage() {
    return this.notification.data.message
  }

  getType() {
    return 'synchronization'
  }
}
