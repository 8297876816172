<template>
  <BaseAlert title="Pending approval" class="tw-block" theme="warning">
    {{ pendingText }}
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
import UpdatedEvent from '@/components/requests/leave/events/UpdatedEvent'
import RequestedEvent from '@/components/requests/leave/events/RequestedEvent'
import RetractedEvent from '@/components/requests/leave/events/RetractedEvent'

export default {
  name: 'RequestedAlert',

  components: { BaseAlert },

  props: {
    leave: {
      type: Object,
      required: true,
    },

    event: {
      type: [RequestedEvent, UpdatedEvent, RetractedEvent],
      required: true,
    },
  },

  computed: {
    pendingText() {
      return `
          Leave request was sent to
          ${this.leave.owner.superiorNames}
          for approval on
          ${this.event._createdAtText(this.activeEmployment)}
        `
    },
  },
}
</script>
