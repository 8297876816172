<script>
import Hours from '@/models/overtime/Hours'
import Days from '@/models/overtime/Days'

const OvertimeBreakdown = {
  name: 'OvertimeBreakdown',

  props: {
    breakdown: {
      type: Object,
      required: true,
    },

    minutesPerWorkingDay: {
      type: Number,
      required: true,
    },
  },

  computed: {
    overtimeUnits() {
      return [
        { key: 'days', label: 'Days' },
        { key: 'hours', label: 'Hours' },
      ]
    },

    selectedOvertimeUnit: {
      get() {
        return this.overtimeUnits.find(unit => unit.key === this.breakdown.unit)
      },
    },
  },

  data() {
    return {
      displayAmount: this.breakdown.amount,
    }
  },

  methods: {
    changeAmount(e) {
      this.breakdown.amount = e.target.valueAsNumber || 0

      this.$emit('breakdown-amount-changed', this.breakdown)
    },

    changeOvertimeUnit(selectedUnit) {
      let breakdown = null
      let date = this.breakdown.date

      if (selectedUnit.key === 'hours') {
        let amount = (this.breakdown.amount * this.minutesPerWorkingDay) / 60

        breakdown = new Hours(date, amount)
      } else {
        let amount = this.breakdown.amount / (this.minutesPerWorkingDay / 60)

        breakdown = new Days(date, amount)
      }

      this.$emit('breakdown-unit-changed', breakdown)
    },
  },

  render() {
    return this.$scopedSlots.default({
      overtimeUnits: this.overtimeUnits,
      selectedOvertimeUnit: this.selectedOvertimeUnit,
      changeAmount: this.changeAmount,
      changeOvertimeUnit: this.changeOvertimeUnit,
      displayAmount: this.displayAmount,
    })
  },
}

export default OvertimeBreakdown
</script>
