export default class RequestAllowance {
  constructor(adjuster) {
    this.adjuster = adjuster
  }

  get employment() {
    return this.adjuster.owner
  }

  get allowanceUnitIsDays() {
    return this.adjuster.allowanceUnitIsDays
  }

  get allowanceUnit() {
    return this.adjuster.allowanceUnit
  }

  get minutesPerWorkingDay() {
    return this.adjuster.minutesPerWorkingDay
  }

  get currentAllowance() {
    throw Error('Error occurred while calculating currentAllowance')
  }

  get newAllowance() {
    throw Error('Error occurred while calculating newAllowance')
  }

  get changedAllowance() {
    throw Error('Error occurred while calculating changedAllowance')
  }

  get hasUnlimitedAllowances() {
    throw Error('Error occurred while finding hasUnlimitedAllowances')
  }
}
