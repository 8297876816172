import moment from 'moment/moment'
import { camelCase, startCase } from 'lodash-es'

export default class OvertimeEvent {
  constructor(event) {
    this.event = event
    this.createdAt = this.event.created_at
  }

  getResourceType() {
    return this.event.resource_type
  }

  getCreatedAt() {
    return moment(this.createdAt)
  }

  getCreatedTime() {
    return this.getCreatedAt().format('LT')
  }

  getName() {
    return startCase(camelCase(this.event.event_class)).replace(/ /g, '')
  }

  getActor() {
    return this.event.actor
  }

  _getOwnerName(viewer) {
    return this._viewerIsOwner(viewer)
      ? 'you'
      : this.event.resource.owner.full_name
  }

  _viewerIsOwnerAndActor(viewer) {
    return this._viewerIsOwner(viewer) && this.viewerIsActor(viewer)
  }

  _viewerIsOwner(viewer) {
    return viewer.id === this.event.resource.owner_id
  }

  getActorName(viewer) {
    return this.viewerIsActor(viewer)
      ? 'you'
      : this.event.actor.full_name +
          (this._isActorDeleted() ? ' (Archived)' : '')
  }

  _getHaveOrHas(viewer) {
    return this.viewerIsActor(viewer) ? 'have' : 'has'
  }

  viewerIsActor(viewer) {
    return viewer.id === this.event.actor_id
  }

  actorIsOwner() {
    return this.event.actor_id === this.event.resource.owner_id
  }

  _createdAtText(viewer) {
    let format = viewer.user.locale === 'en_US' ? 'ddd MMM DD' : 'ddd DD MMM'

    return moment(this.createdAt).format(format)
  }

  _isActorDeleted() {
    return this.event.actor.deleted_at != null
  }
}
