import http from '@/plugins/http'

export default class Comments {
  static async delete(comment, params) {
    await http.delete(`comments/${comment.id}`, {
      params: params,
    })
  }

  static async restore(comment, params) {
    await http.put(
      `comments/${comment.id}/restore`,
      {},
      {
        params: params,
      }
    )
  }
}
