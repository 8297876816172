<template>
  <Component
    :is="component"
    v-if="component"
    :event="event"
    :summary="summary"
    :data-cy="cypressAttribute"
    @delete-comment="comment => $emit('delete-comment', comment)"
    @restore-comment="comment => $emit('restore-comment', comment)"
  />
</template>

<script>
export default {
  name: 'DynamicTimelineEvent',

  props: {
    type: {
      type: String,
      required: true,
    },

    event: {
      type: Object,
      required: true,
    },

    summary: {
      type: Boolean,
      default: false,
    },
    cypressAttribute: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      component: null,
    }
  },

  computed: {
    componentLoader() {
      return () =>
        import(
          `@/components/requests/${this.type}/timeline/${this.event.getName()}`
        )
    },
  },

  mounted() {
    this.componentLoader().then(() => {
      this.component = () => this.componentLoader()
    })
  },
}
</script>
