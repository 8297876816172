<template>
  <div class="tw-w-full">
    <div>
      <div
        class="tw-p-2 tw-flex tw-justify-between tw-w-full"
        data-cy="leave-day-date"
      >
        <div>
          {{ date }}
        </div>
        <PortalTarget :name="portalTargetOneName" multiple></PortalTarget>
        <slot></slot>
      </div>
    </div>
    <div class="tw-flex tw-justify-end tw-w-full">
      <div
        v-if="leaveLimitExceeded"
        :ref="tooltipTarget"
        class="tw-w-full"
        @mouseover="showTooltip"
      >
        <BaseAlert class="tw-w-full tw-flex" theme="warning">
          Exceeds number of people allowed on leave.
          <template v-slot:right-content>
            <div class="tw-flex tw-justify-end">
              <UserAvatarList
                :nonvisible-user-count="nonvisibleEmploymentCount"
                :users="overlapLeaveOwners"
              />
            </div>
          </template>
        </BaseAlert>
      </div>

      <PortalTarget :name="portalTargetTwoName" multiple></PortalTarget>

      <Portal
        v-if="!leaveLimitExceeded && overlapsAvailableInPlan"
        :to="portalRenderTo"
        :order="1"
      >
        <div
          :ref="tooltipTarget"
          class="tw-flex tw-justify-end tw-w-full"
          data-cy="user-on-leave-avatars"
          @mouseover="showTooltip"
        >
          <div>
            <UserAvatarList
              :nonvisible-user-count="nonvisibleEmploymentCount"
              :users="overlapLeaveOwners"
            />
          </div>
        </div>
      </Portal>

      <Portal
        v-if="
          !hasOverlapLeave &&
            !leaveLimitExceeded &&
            nonvisibleEmploymentCount > 0 &&
            overlapsAvailableInPlan
        "
        :to="portalRenderTo"
        :order="2"
      >
        <div class="tw-flex tw-items-center tw-space-x-1 tw-ml-1">
          <span
            class="tw-font-medium tw-text-purple-700"
            data-cy="non-visible-overlaps"
          >
            {{ nonvisibleEmploymentCount }}
            {{ 'employee' | pluralize(nonvisibleEmploymentCount) }} on leave
          </span>
        </div>
      </Portal>

      <Portal
        v-if="
          !hasOverlapLeave &&
            nonvisibleEmploymentCount <= 0 &&
            overlapsAvailableInPlan
        "
        :to="portalRenderTo"
        :order="3"
      >
        <div class="tw-flex tw-h-full tw-space-x-1 tw-items-center tw-ml-3">
          <SvgIcon
            name="info-circle"
            class="tw-w-5 tw-h-5 tw-text-purple-500"
          />
          <span
            class="tw-w-full tw-font-medium tw-text-purple-700 tw-whitespace-no-wrap"
          >
            {{ noOverlapsText }}
          </span>
        </div>
      </Portal>
    </div>

    <div
      v-if="hasOverlapLeave && overlapsAvailableInPlan"
      ref="overlapLeavePanel"
      class="tw-hidden"
    >
      <div
        class="tw-items-start tw-p-3 tw-border tw-rounded-md tw-border-gray-660  tw-bg-gray-660 leave-container"
      >
        <OverlapLeave
          :overlap-leaves="overlaps.leaves"
          @show-overlap-leave-details="showOverlapLeaveDetails"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import UserAvatarList from '@/components/UserAvatarList'
import tippy from 'tippy.js'
import OverlapLeave from '@/components/requests/leave/OverlapLeave'
import BaseAlert from '@/components/BaseAlert'
import { uniqBy, uniqueId } from 'lodash-es'

export default {
  name: 'LeaveDayDetails',

  components: {
    BaseAlert,
    UserAvatarList,
    OverlapLeave,
  },

  mixins: [FormatDate],

  props: {
    date: {
      type: String,
      required: true,
    },
    overlaps: {
      type: Object,
      required: true,
    },
    noOverlapsText: {
      type: String,
      required: true,
    },
    multiline: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      tooltipInstance: null,
    }
  },

  computed: {
    uniqueKey() {
      return uniqueId()
    },

    hasOverlapLeave() {
      return this.overlaps.leaves.length > 0
    },

    overlapLeaveOwners() {
      return uniqBy(
        this.overlaps.leaves.map(leave => leave.owner),
        'id'
      )
    },

    portalRenderTo() {
      if (this.multiline) {
        return this.portalTargetTwoName
      }

      return this.portalTargetOneName
    },

    portalTargetOneName() {
      return 'portalTargetOne' + this.uniqueKey
    },

    portalTargetTwoName() {
      return 'portalTargetTwo' + this.uniqueKey
    },

    nonvisibleEmploymentCount() {
      if (this.overlaps.nonvisibleEmploymentCount)
        return this.overlaps.nonvisibleEmploymentCount
      return 0
    },

    leaveLimitExceeded() {
      return this.overlaps.leaveLimitScope !== null
    },

    tooltipTarget() {
      return 'tooltipTarget' + this.uniqueKey
    },

    overlapsAvailableInPlan() {
      return this.overlaps.error !== 'overlap_feature_not_available'
    },
  },

  methods: {
    showOverlapLeaveDetails(leaveKey) {
      this.destroyTooltip()

      this.$emit('show-overlap-leave-details', leaveKey)
    },

    showTooltip() {
      let target = this.$refs[this.tooltipTarget]
      if (!this.tooltipInstance && this.hasOverlapLeave) {
        this.tooltipInstance = tippy.one(target, {
          html: this.$refs.overlapLeavePanel.firstChild,
          theme: 'dark',
          arrow: true,
          interactive: true,
          placement: 'top',
          delay: [400, 0],
          distance: 1,
          interactiveBorder: 15,
        })
      }

      this.$once('hook:beforeDestroy', () => {
        this.destroyTooltip()
      })
    },

    destroyTooltip() {
      this.tooltipInstance?.destroy()
      this.tooltipInstance = null
    },
  },
}
</script>
