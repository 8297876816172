import OvertimeEvent from '@/components/requests/overtime/events/OvertimeEvent'

export default class OvertimeApprovedEvent extends OvertimeEvent {
  asNotice(viewer) {
    if (this._viewerIsOwnerAndActor(viewer)) {
      return `
        Overtime request was automatically approved on
        ${this._createdAtText(viewer)}.
      `
    }

    return `
      Overtime request was approved by
      ${this.getActorName(viewer)}
      on
      ${this._createdAtText(viewer)}.
      `
  }

  asText(viewer) {
    if (!this.wasAutomaticallyApproved()) {
      return `
        **${this.getActorName(viewer)}**
        approved the request
      `
    }

    if (this.actorIsOwner()) {
      return `
        **Leave Dates** automatically approved the request because
        ${this._getOwnerName(viewer)} can approve
        ${this._toPossessiveForm(this.getActorName(viewer))} own requests
      `
    }

    return `**Leave Dates** automatically approved the request because an approver requested it`
  }

  wasAutomaticallyApproved() {
    return this.event.event_properties?.automatically_approved ?? false
  }

  _toPossessiveForm(name) {
    if (name === 'you') {
      return 'your'
    }

    return 'their'
  }
}
