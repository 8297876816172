<template>
  <BaseAlert title="Approved" class="tw-block">
    {{ event.asNotice(activeEmployment) }}
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
import OvertimeApprovedEvent from '@/components/requests/overtime/events/OvertimeApprovedEvent'

export default {
  name: 'ApprovedAlert',

  components: { BaseAlert },

  props: {
    overtime: {
      type: Object,
      required: true,
    },

    event: {
      type: OvertimeApprovedEvent,
      required: true,
    },
  },
}
</script>
