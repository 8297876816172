<template>
  <a
    v-if="check"
    ref="reference"
    title="Notifications"
    data-cy="notifications"
    class="tw-flex tw-cursor-pointer tw-no-underline tw-text-sm tw-leading-none tw-text-gray-800 tw-px-6 tw-py-5"
  >
    <span class="tw-relative">
      <svg-icon
        name="bell-stroke"
        class="tw-w-6 tw-h-6 tw-pointer-events-none"
      />
      <span
        v-if="unreadNotifications.length"
        id="notification-counter"
        class="tw-absolute tw-rounded-full tw-bg-red-500 tw-text-white tw-text-xs tw-font-semibold tw-text-center"
        v-text="unreadNotifications.length"
      />
    </span>

    <div v-show="false" ref="tooltip">
      <div class="tw-w-80 tw-overflow-hidden" style="min-width: 200px;">
        <div class="tw-border-b tw-border-gray-300 tw-px-4 tw-pt-2">
          <div class="tw-flex tw-items-center tw-justify-start tw-relative">
            <span
              class="tw-pb-2 tw-text-gray-800 tw-text-thin tw-font-semibold tw-cursor-default"
              >Notifications</span
            >
            <button
              class="tw-opacity-50 hover:tw-opacity-100 modal-close tw-absolute tw-right-0 tw-top-0"
              @click="hideTippy"
            >
              <svg-icon name="close" style="width: 12px; height: 12px;" />
            </button>
          </div>
        </div>
        <DynamicNotification
          v-for="notification in notifications.slice(0, 5)"
          :key="notification.getKey()"
          :notification="notification"
          :active-notification="activeNotification"
          :class="[notification.isRead() ? 'tw-bg-white' : 'tw-bg-gray-300']"
          @click="markNotificationAsRead"
          @toggle-read="toggleRead"
        />
        <div
          v-if="!notifications.length"
          class="tw-flex tw-justify-center tw-items-center tw-p-4 tw-no-underline tw-overflow-hidden"
        >
          <div class="no-data tw-px-2 tw-text-center">
            <svg-icon
              name="information-outline"
              class="tw-my-2 tw-w-6 tw-h-6"
            />
            <h4 class="tw-mb-2 tw-text-sm tw-text-center">
              You have no notifications.
            </h4>
          </div>
        </div>
        <div
          v-else
          class="tw-flex tw-justify-between tw-items-center tw-px-4 tw-border-t tw-border-gray-300"
        >
          <button
            class="tw-p-2 tw-cursor-pointer tw-overflow-hidden tw-no-underline hover:tw-underline tw-text-gray-800"
            type="button"
            @click="seeAllNotifications"
          >
            View All
          </button>
          <button
            class="tw-p-2 tw-cursor-pointer tw-overflow-hidden tw-no-underline hover:tw-underline tw-text-gray-800"
            type="button"
            @click="markAllNotificationsAsRead"
          >
            Mark All as Read
          </button>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { mapGetters } from 'vuex'
import tippy from 'tippy.js'
import NotificationList from './../notifications/mixins/NotificationList'
import DynamicNotification from './../notifications/DynamicNotification.vue'

export default {
  name: 'NotificationMenu',

  components: { DynamicNotification },

  mixins: [NotificationList],

  computed: {
    ...mapGetters('auth', ['check']),
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.reference, {
      html: this.$refs.tooltip.firstChild,
      trigger: 'click',
      distance: -10,
      placement: 'bottom-end',
      offset: '0,18',
      interactive: true,
      theme: 'light',
      animation: 'scale',
      inertia: true,
      arrow: true,
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },

  methods: {
    hideTippy() {
      this.tippy.hide()
    },

    hidePopover() {
      this.tippy.hide()
    },

    seeAllNotifications() {
      this.hidePopover()

      this.$router.push({ name: 'notifications' }, () => {})
    },

    markNotificationAsRead(notification) {
      this.markAsActive(notification)

      this.hidePopover()

      this.markAsRead(notification)

      this.navigate(notification)
    },

    markAllNotificationsAsRead() {
      this.hidePopover()

      this.markAllAsRead()
    },

    navigate(notification) {
      notification.navigate(this.$router, this.$route.query)
    },
  },
}
</script>
