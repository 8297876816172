import Breakdown from '@/components/working-schedule/Breakdown'
import { first, last, omit, sortBy } from 'lodash-es'
import moment from 'moment-timezone'
import Interval from '@/components/working-schedule/Interval'

export default class DailySchedule {
  constructor(day, breakdowns) {
    this.day = day
    this.scheduledBreakdowns = this._filterScheduledBreakdownsOfDay(breakdowns)
  }

  get startTime() {
    return first(this.scheduledBreakdowns).startTime
  }

  get endTime() {
    return last(this.scheduledBreakdowns).endTime
  }

  shiftDate(date) {
    return new DailySchedule(
      this.day,
      this.scheduledBreakdowns.map(
        breakdown =>
          new Breakdown(
            omit(
              {
                ...breakdown,
                start_time: breakdown.startTime.format('HH:mm'),
                end_time: breakdown.endTime.format('HH:mm'),
              },
              ['startTime', 'endTime']
            ),
            date
          )
      )
    )
  }

  get interval() {
    if (this._hasOnlyFirstBreakdown() || this._hasOnlySecondBreakdown()) {
      return null
    }

    const startTme = first(this.scheduledBreakdowns).endTime.clone()
    const endTime = last(this.scheduledBreakdowns).startTime.clone()
    const duration = moment.duration(endTime.diff(startTme)).asMinutes()

    return new Interval(startTme, endTime, duration)
  }

  getBreakdowns() {
    const breakdowns = [...this.scheduledBreakdowns]

    if (!this.scheduledBreakdowns.length || this._hasOnlySecondBreakdown()) {
      let firstBreakdown = this._getDefaultFirstBreakdown()
      firstBreakdown.deactivate()
      breakdowns.push(firstBreakdown)
    }

    if (!this.scheduledBreakdowns.length || this._hasOnlyFirstBreakdown()) {
      let secondBreakdown = this._getDefaultSecondBreakdown()
      secondBreakdown.deactivate()
      breakdowns.push(secondBreakdown)
    }

    return sortBy(breakdowns, 'session')
  }

  isBreakdownOverlaps(breakdown) {
    const firstBreakdown = this._getActiveFirstBreakdown(breakdown)

    const secondBreakdown = this._getActiveSecondBreakdown(breakdown)

    return (
      firstBreakdown &&
      secondBreakdown &&
      firstBreakdown.isOverlap(secondBreakdown)
    )
  }

  isValid() {
    return (
      this.getBreakdowns().every(breakdown => breakdown.isValid()) &&
      !this.isBreakdownOverlaps(this.getBreakdowns()[0])
    )
  }

  calculateDurationInMinutes(from, to) {
    if (
      to.isSameOrBefore(from) ||
      this._isBeforeScheduleStartTime(from, to) ||
      this._isAfterScheduleEndTime(from, to)
    ) {
      return 0
    }

    from = this._getShiftedFrom(from)
    to = this._getShiftedTo(to)

    let durationInMinutes = moment.duration(to.diff(from)).asMinutes()

    if (this._isIntervalBetween(from, to)) {
      durationInMinutes -= this.interval.duration
    }

    return durationInMinutes
  }

  _isBeforeScheduleStartTime(from, to) {
    return to.isBefore(this.startTime) && from.isBefore(this.startTime)
  }

  _isAfterScheduleEndTime(from, to) {
    return from.isAfter(this.endTime) && to.isAfter(this.endTime)
  }

  _getShiftedFrom(from) {
    if (from.isBefore(this.startTime)) {
      return this.startTime.clone()
    }

    if (this._isBetweenInterval(from)) {
      return this.interval.startTime.clone()
    }

    return from
  }

  _getShiftedTo(to) {
    if (to.isAfter(this.endTime)) {
      return this.endTime.clone()
    }

    if (this._isBetweenInterval(to)) {
      return this.interval.startTime.clone()
    }

    return to
  }

  _isBetweenInterval(time) {
    return time.isBetween(
      this.interval?.startTime,
      this.interval?.endTime,
      null,
      '[]'
    )
  }

  _isIntervalBetween(from, to) {
    return (
      this.interval &&
      from.isSameOrBefore(this.interval.startTime) &&
      to.isSameOrAfter(this.interval.endTime)
    )
  }

  _filterScheduledBreakdownsOfDay(breakdowns) {
    return breakdowns.filter(breakdown => {
      return breakdown.iso_week_day === this._isoWeekDay()
    })
  }

  _hasOnlySecondBreakdown() {
    return (
      this.scheduledBreakdowns.length < 2 &&
      this.scheduledBreakdowns[0].isSecondSession()
    )
  }

  _hasOnlyFirstBreakdown() {
    return (
      this.scheduledBreakdowns.length < 2 &&
      this.scheduledBreakdowns[0].isFirstSession()
    )
  }

  _getDefaultFirstBreakdown() {
    return new Breakdown({
      iso_week_day: this._isoWeekDay(),
      start_time: '09:00',
      end_time: '13:00',
      session: 1,
    })
  }

  _getDefaultSecondBreakdown() {
    return new Breakdown({
      iso_week_day: this._isoWeekDay(),
      start_time: '14:00',
      end_time: '17:30',
      session: 2,
    })
  }

  _isoWeekDay() {
    return moment()
      .isoWeekday(this.day)
      .isoWeekday()
  }

  _getActiveFirstBreakdown(sessionBreakdown) {
    if (sessionBreakdown.isFirstSession() && sessionBreakdown.isActive()) {
      return sessionBreakdown
    }

    return this._activeFirstBreakdown()
  }

  _getActiveSecondBreakdown(sessionBreakdown) {
    if (sessionBreakdown.isSecondSession() && sessionBreakdown.isActive()) {
      return sessionBreakdown
    }

    return this._activeSecondBreakdown()
  }

  _activeFirstBreakdown() {
    return this.getBreakdowns().find(breakdown => {
      return breakdown.isFirstSession() && breakdown.isActive()
    })
  }

  _activeSecondBreakdown() {
    return this.getBreakdowns().find(breakdown => {
      return breakdown.isSecondSession() && breakdown.isActive()
    })
  }
}
