export default class EmploymentAllowanceText {
  constructor(viewer, owner) {
    this.viewer = viewer
    this.owner = owner
  }

  _getOwnerText() {
    return this.viewer.id === this.owner.id
      ? 'your'
      : `${this.owner.full_name}'s`
  }
}
