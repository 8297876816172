<template>
  <label class="form-label">
    <slot></slot>

    <span v-if="requiredField" class="required-field">&#42;</span>
  </label>
</template>

<script>
export default {
  name: 'BaseInputLabel',

  props: {
    requiredField: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
