<template>
  <div
    class="tw-p-4 tw-border tw-rounded-lg tw-bg-purple-200 tw-border-purple-400 tw-mt-3 tw-relative"
  >
    <div class="tw-text-base tw-font-medium tw-text-black">Recent Updates</div>

    <div v-if="timeline.eventCount" data-cy="request-timeline">
      <DynamicTimelineEvent
        v-for="timelineEvent in timeline.getRecentEvents(2)"
        :key="timelineEvent.event.id"
        class="tw-mt-3"
        :event="timelineEvent"
        :summary="true"
        type="overtime"
      />
    </div>

    <div v-else class="tw-mt-3">No recent updates.</div>

    <div class="tw-flex tw-justify-center tw-mt-3">
      <BaseButton
        class="tw-absolute tw-rounded-full"
        size="sm"
        theme="indigo"
        trailing-icon="arrow-right"
        @click="$emit('show-more-details')"
      >
        View more
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton'
import Timeline from '@/components/requests/overtime/events/Timeline'
import DynamicTimelineEvent from '@/components/requests/timeline/DynamicTimelineEvent'

export default {
  name: 'OvertimeRecentUpdates',

  components: {
    BaseButton,
    DynamicTimelineEvent,
  },

  props: {
    timeline: {
      type: Timeline,
      required: true,
    },
  },
}
</script>
