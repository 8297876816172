export default class Error {
  constructor(error) {
    this.error = error
  }

  get isCrossBoundaries() {
    return this.error.code === 'calendar_boundaries_crossed'
  }

  get isAllowanceInsufficient() {
    return this.error.code === 'allowance_insufficient'
  }

  get isCalendarNotSetup() {
    return this.error.code === 'calendar_not_setup'
  }
}
