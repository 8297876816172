<template>
  <a
    :target="target"
    class="tw-cursor-pointer hover:tw-underline"
    :class="classes"
    @click.stop="$emit('click')"
  >
    <slot></slot>
  </a>
</template>

<script>
const THEMES = {
  default: ['tw-text-blue-500'],

  lightGray: ['tw-text-gray-600'],

  darkGray: ['tw-text-gray-700'],

  darkerGray: ['tw-text-gray-900'],

  orange: ['tw-text-orange-500'],
}

export default {
  name: 'BaseLink',

  props: {
    theme: {
      type: String,
      default: 'default',
    },

    target: {
      type: String,
      default: '_blank',
    },

    underline: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return [...this.themeClasses, ...this.styleClasses]
    },

    themeClasses() {
      return THEMES[this.theme]
    },

    styleClasses() {
      return this.underline ? ['tw-underline'] : ['tw-no-underline']
    },
  },
}
</script>
