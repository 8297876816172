import OvertimeDetailsPanel from '@/components/requests/overtime/OvertimeDetailsPanel'
import LeaveDetailsPanel from '@/components/requests/leave/LeaveDetailsPanel'
import { omit } from 'lodash-es'

export default class SlideoutPanel {
  constructor(vm) {
    this.vm = vm

    this.query = this.vm.$route.query
  }

  handle() {
    if (this.vm.panel) {
      this._hidePanel()
    }

    if (this._shouldShowPanel()) {
      this._showPanel()
    }
  }

  _showPanel() {
    const self = this

    this.vm.$nextTick(function() {
      self.vm.panel = self.vm.$showPanel({
        component: self._getComponent(),
        openOn: 'right',
        width: 704,
        props: self._getComponentProps(),
      })

      self._clearQueryParamsOnPanelClose()
    })
  }

  _hidePanel() {
    this.vm.panel.hide()
  }

  _shouldShowPanel() {
    return !!this._getQueryParamKey()
  }

  _getComponent() {
    if (this._queryHasOvertime()) {
      return OvertimeDetailsPanel
    }

    return LeaveDetailsPanel
  }

  _getComponentProps() {
    if (this._queryHasOvertime()) {
      return {
        overtimeKey: this._getResourceKey(),
      }
    }

    if (this._queryHasLeave()) {
      return {
        leaveKey: this._getResourceKey(),
      }
    }

    return null
  }

  _clearQueryParamsOnPanelClose() {
    this.vm.panel.promise.then(() => {
      if (this._isCurrentQueryHasSameResourceKey()) {
        this._clearRequestURLParam()
      }
    })
  }

  _isCurrentQueryHasSameResourceKey() {
    return (
      this._getResourceKey() === this.vm.$route.query[this._getQueryParamKey()]
    )
  }

  _getResourceKey() {
    return this.query[this._getQueryParamKey()]
  }

  _clearRequestURLParam() {
    this.vm.$router.push({
      query: {
        ...omit(this.query, this._getQueryParamKey()),
      },
    })
  }

  _getQueryParamKey() {
    if (this._queryHasOvertime()) {
      return 'overtime'
    }

    if (this._queryHasLeave()) {
      return 'leave-request'
    }

    return null
  }

  _queryHasOvertime() {
    return this.query['overtime']
  }

  _queryHasLeave() {
    return this.query['leave-request']
  }
}
