import RequestMicrocopy from '@/components/requests/RequestMicrocopy'

export default class LeaveRequestMicrocopy extends RequestMicrocopy {
  constructor(draftRequest, requester, leaveType, hasErrors) {
    super(draftRequest, requester)

    this.leaveType = leaveType
    this.hasErrors = hasErrors
  }

  microcopy() {
    if (this.hasErrors) {
      return 'Request cannot be sent as there is an error'
    }

    if (
      !this.leaveType?.approval ||
      this._canRequestBeAutomaticallyApproved()
    ) {
      return 'Request will be automatically approved'
    }

    if (this._isRequesterIsSuperiorOfSomeOwners()) {
      return 'Request will be sent to the appropriate approvers'
    }

    return `Request will be sent to **${this._superiorNames()}** for approval`
  }
}
