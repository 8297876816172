<template>
  <div
    class="arrow-tooltip tw-relative tw-border tw-w-full tw-flex tw-items-center tw-p-2 tw-rounded-lg"
    :class="themeClasses"
  >
    <div class="tw-w-full tw-flex tw-items-center tw-space-x-2">
      <div>
        <SvgIcon class="tw-w-6 tw-h-6" :class="iconClasses" :name="iconName" />
      </div>

      <div
        v-if="text"
        class="tw-flex-1 tw-leading-normal"
        :class="bodyClasses"
        data-cy="leave-breakdown-error-item"
      >
        {{ text }}
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
const THEMES = {
  success: {
    class: 'success',
    icon: 'icon-check-circle',
    background: ['tw-bg-green-100'],
    border: ['tw-border-green-400'],
    iconColor: ['tw-text-green-600'],
    body: ['tw-text-green-600'],
  },

  danger: {
    class: 'danger',
    icon: 'icon-close-circle',
    background: ['tw-bg-red-100'],
    border: ['tw-border-red-250'],
    iconColor: ['tw-text-red-800'],
    body: ['tw-text-red-800'],
  },

  warning: {
    class: 'warning',
    icon: 'icon-exclamation-triangle',
    background: ['tw-bg-yellow-100'],
    border: ['tw-border-yellow-550'],
    iconColor: ['tw-text-brown-500'],
    body: ['tw-text-brown-500'],
  },
}

export default {
  name: 'ArrowAlert',

  props: {
    icon: {
      type: String,
      default: null,
    },

    text: {
      type: String,
      default: null,
    },

    theme: {
      type: String,
      default: 'danger',
    },
  },

  computed: {
    iconName() {
      return this.icon || THEMES[this.theme].icon
    },

    iconClasses() {
      return THEMES[this.theme].iconColor
    },

    themeClasses() {
      return [
        THEMES[this.theme].class,
        ...THEMES[this.theme].background,
        ...THEMES[this.theme].border,
      ]
    },

    bodyClasses() {
      return THEMES[this.theme].body
    },
  },
}
</script>

<style lang="scss" scoped>
.arrow-tooltip {
  &:after,
  &:before {
    left: 8%;
    bottom: 100%;
    border: solid transparent;
    content: '';
    @apply .h-0;
    @apply .w-0;
    @apply .absolute;
    @apply .pointer-events-none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 6px;
    margin-left: -6px;
  }

  &:before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    margin-left: -7px;
  }

  &.danger {
    &:after {
      border-bottom-color: theme('colors.red.100');
    }

    &:before {
      border-bottom-color: theme('colors.red.250');
    }
  }

  &.success {
    &:after {
      border-bottom-color: theme('colors.green.100');
    }

    &:before {
      border-bottom-color: theme('colors.green.400');
    }
  }

  &.warning {
    &:after {
      border-bottom-color: theme('colors.yellow.100');
    }

    &:before {
      border-bottom-color: theme('colors.yellow.550');
    }
  }
}
</style>
