import store from '@/store'

export default async (to, from, next) => {
  if (
    !store.getters['auth/check'] &&
    !['not-found', 'unsubscribe', 'unauthorized'].includes(to.name)
  ) {
    try {
      await store.dispatch('auth/fetchUser')
    } catch (e) {
      //
    }
  }

  next()
}
