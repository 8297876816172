import http from '@/plugins/http'
import PendingApproval from '@/models/reporting/PendingApproval'

export default class PendingApprovals {
  static async get(company) {
    const { data } = await http.get('pending-approvals', {
      params: { company: company.id },
    })

    return data.map(request => new PendingApproval(request))
  }

  static approve(company) {
    return http.patch('pending-approvals/approve', {
      company: company.id,
    })
  }

  static decline(company) {
    return http.patch('pending-approvals/decline', {
      company: company.id,
    })
  }
}
