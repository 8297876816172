import OvertimeEvent from '@/components/requests/overtime/events/OvertimeEvent'

export default class AttachmentAddedEvent extends OvertimeEvent {
  constructor(event) {
    super(event)
  }

  asText(viewer) {
    return `
      **${this.getActorName(viewer)}**
      ${this._getHaveOrHas(viewer)} uploaded a new
      attachment
    `
  }

  isDeleted() {
    return !!this.event.deleted_at
  }

  getResource() {
    return this.event.resource
  }
}
