<template>
  <BaseAlert title="Declined" class="tw-block" theme="danger">
    {{ cancelledText }}
    <div v-if="canRetractDecline">
      <button
        class="tw-font-semibold tw-underline"
        data-cy="btn-undo-decline"
        @click="$emit('retract')"
      >
        Undo Decline
      </button>
    </div>
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
import LeavePermission from '@/components/requests/leave/LeavePermission'
import DeclinedEvent from '@/components/requests/leave/events/DeclinedEvent'

export default {
  name: 'DeclinedAlert',

  components: { BaseAlert },

  props: {
    leave: {
      type: Object,
      required: true,
    },

    event: {
      type: DeclinedEvent,
      required: true,
    },
  },

  computed: {
    cancelledText() {
      return this.event.asNotice(this.activeEmployment)
    },

    canRetractDecline() {
      return LeavePermission.canRetract(this.leave, this.activeEmployment)
    },
  },
}
</script>
