<template>
  <div class="tw-flex tw-items-center tw-h-10 tw-min-h-full">
    <div class="tw-flex tw-items-center tw-space-x-1">
      <SvgIcon name="info-circle" class="tw-w-5 tw-h-5 tw-text-purple-500" />

      <span class="tw-font-medium tw-text-purple-700">
        Non working day
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NonWorkingDayInfo',
}
</script>
