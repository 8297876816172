<template>
  <div class="tw-flex tw-items-center tw-h-10 tw-min-h-full">
    <div class="tw-flex tw-items-center tw-space-x-1">
      <span ref="holidayIcon">
        <SvgIcon name="info-circle" class="tw-w-5 tw-h-5 tw-text-purple-500" />
      </span>

      <span class="tw-font-medium tw-text-purple-700">
        Public holiday
      </span>
    </div>
    <div v-show="false" ref="holidayText">
      <div class="tw-p-3 tw-w-48 tw-text-center">
        <div v-for="name in holiday.description" :key="name">
          {{ name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
  name: 'HolidayInfo',
  props: {
    holiday: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.holidayIcon, {
      html: this.$refs.holidayText.firstChild,
      theme: 'dark',
      arrow: true,
      interactive: true,
      placement: 'top',
      delay: [400, 0],
      distance: 1,
      interactiveBorder: 15,
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },
}
</script>
