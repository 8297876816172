import LeavePermission from '@/components/requests/leave/LeavePermission'

export default class LeaveDetailsMicrocopy {
  constructor(leave, viewer, allowance) {
    this.leave = leave
    this.viewer = viewer
    this.leaveOwnerAllowance = allowance
  }

  get microcopy() {
    if (
      this._isTheViewerOwner() &&
      this.leave.isCancellable() &&
      !LeavePermission.canCancel(this.leave, this.viewer)
    ) {
      return `It is not possible to update or cancel a request which has already been taken, please contact
       **${this.viewer.superiorNames}**
      to assist`
    }

    if (
      this.leave.isCancellable() &&
      LeavePermission.canCancel(this.leave, this.viewer)
    ) {
      return this._isTheViewerOwner()
        ? this._makeMicrocopyForLeaveOwner()
        : this._makeMicrocopyForSuperior()
    }

    return ''
  }

  _makeMicrocopyForLeaveOwner() {
    if (!this.leave.hasAffectToEmploymentAllowances()) {
      return `Cancelling this request won’t affect **your** allowance because ${this.leave.getTypeName()} does not deduct`
    }

    if (this._isLeaveOwnerAllowanceUnlimited()) {
      return `Cancelling this request won’t affect ** your ** allowance because it is unlimited`
    }

    return `Cancelling this request will credit ** your ** allowance`
  }

  _makeMicrocopyForSuperior() {
    let action =
      this.viewer.isApproverOf(this.leave.owner_id) && this.leave.isRequested()
        ? 'Declining'
        : 'Cancelling'

    if (!this.leave.hasAffectToEmploymentAllowances()) {
      return `
          ${action} this request won't affect the allowance for
          ** ${this._getLeaveOwnerName()} **
          because ${this.leave.getTypeName()} does not deduct
        `
    }

    if (this._isLeaveOwnerAllowanceUnlimited()) {
      return `
          ${action} this request won’t affect the allowance for
          ** ${this._getLeaveOwnerName()} **
          because it's unlimited
        `
    }

    return `${action} this request will credit the allowance for ** ${this._getLeaveOwnerName()} **`
  }

  _getLeaveOwnerName() {
    if (this._isTheViewerOwner()) {
      return 'you'
    }
    return this.leave.owner.full_name
  }

  _isTheViewerOwner() {
    return this.viewer.id === this.leave.owner_id
  }

  _isLeaveOwnerAllowanceUnlimited() {
    if (!this.leaveOwnerAllowance) {
      return true
    }

    return this.leaveOwnerAllowance.isUnlimited()
  }
}
