import { mapActions, mapGetters } from 'vuex'
import EventBus from '@/plugins/event-bus'

export default {
  data: () => ({
    activeNotification: null,
  }),

  computed: {
    ...mapGetters('notifications', ['notifications', 'unreadNotifications']),
  },

  created() {
    this.all()

    this.$echo
      .private('user.' + this.authUser.id)
      .notification(notification => {
        this.all()
        this.fetchUser()
        EventBus.$emit('notification-fetched', notification)
      })

    this.authUser.employments.forEach(employment => {
      this.$echo
        .private('employment.' + employment.id)
        .notification(notification => {
          this.all()
          this.fetchUser()
          EventBus.$emit('notification-fetched', notification)
        })
    })
  },

  methods: {
    ...mapActions('notifications', [
      'all',
      'markAsRead',
      'markAsUnread',
      'markAllAsRead',
    ]),

    ...mapActions('auth', ['fetchUser']),

    toggleRead(notification) {
      if (notification.isRead()) {
        this.markAsUnread(notification)
      } else {
        this.markAsRead(notification)
      }
    },

    markAsActive(notification) {
      this.activeNotification = notification
    },
  },
}
