import moment from 'moment'
import { camelCase, startCase } from 'lodash-es'

export default class LeaveEvent {
  constructor(event) {
    this.event = event
    this.createdAt = this.event.created_at
  }

  getResourceType() {
    return this.event.resource_type
  }

  getCreatedAt() {
    return moment(this.createdAt)
  }

  isRequested() {
    return this.getName() === 'LeaveWasRequested'
  }

  isUpdated() {
    return this.getName() === 'LeaveWasUpdated'
  }

  isApproved() {
    return this.getName() === 'LeaveWasApproved'
  }

  isRejected() {
    return this.getName() === 'LeaveWasRejected'
  }

  isCancelled() {
    return this.getName() === 'LeaveWasCancelled'
  }

  isUndone() {
    return this.getName() === 'LeaveWasUndone'
  }

  getName() {
    return startCase(camelCase(this.event.event_class)).replace(/ /g, '')
  }

  getCreatedTime() {
    return moment(this.createdAt).format('LT')
  }

  getActor() {
    return this.event.actor
  }

  getActorName(viewer) {
    if (this.viewerIsActor(viewer)) {
      return 'you'
    }

    return (
      this.event.actor.full_name + (this._isActorDeleted() ? ' (Archived)' : '')
    )
  }

  toPossessiveForm(name) {
    if (name === 'you') {
      return 'your'
    }

    return name + `'s`
  }

  _getLeaveOwnerName(viewer) {
    if (this._viewerIsOwner(viewer)) {
      return 'you'
    }
    return this.event.resource.owner.full_name
  }

  _getHaveOrHas(viewer) {
    if (this.viewerIsActor(viewer)) {
      return 'have'
    }

    return 'has'
  }

  _viewerIsOwnerAndActor(viewer) {
    return this._viewerIsOwner(viewer) && this.viewerIsActor(viewer)
  }

  viewerIsActor(viewer) {
    return viewer.id === this.event.actor_id
  }

  actorIsOwner() {
    return this.event.actor_id === this.event.resource.owner_id
  }

  _viewerIsOwner(viewer) {
    return viewer.id === this.event.resource.owner_id
  }

  _createdAtText(viewer) {
    if (viewer.user.locale === 'en_US') {
      return moment(this.createdAt).format('ddd MMM DD')
    }
    return moment(this.createdAt).format('ddd DD MMM')
  }

  _isActorDeleted() {
    return this.event.actor.deleted_at != null
  }
}
