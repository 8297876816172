import { http } from '@/plugins'
import LeaveNotification from '@/components/notifications/LeaveNotification'
import ImportNotification from '@/components/notifications/ImportNotification'
import PaymentNotification from '@/components/notifications/PaymentNotification'
import OvertimeNotification from '@/components/notifications/OvertimeNotification'
import AllowanceNotification from '@/components/notifications/AllowanceNotification'
import SynchronizationNotification from '@/components/notifications/SynchronizationNotification'
import HolidayNotification from '@/components/notifications/HolidayNotification'

export default class Notifications {
  static async getAll() {
    const { data } = await http.get('notifications')

    return this._makeNotifications(data)
  }

  static markAsRead(notification) {
    return http.delete(`notifications/${notification.getKey()}`)
  }

  static markAsUnread(notification) {
    return http.put(`notifications/${notification.getKey()}`)
  }

  static markAllAsRead() {
    return http.delete('notifications')
  }

  static _makeNotifications(data) {
    let notifications = []

    for (const notification of data) {
      if (this._isLeave(notification)) {
        notifications.push(new LeaveNotification(notification))
      }

      if (this._isOvertime(notification)) {
        notifications.push(new OvertimeNotification(notification))
      }

      if (this._isImport(notification)) {
        notifications.push(new ImportNotification(notification))
      }

      if (this._isAllowance(notification)) {
        notifications.push(new AllowanceNotification(notification))
      }

      if (this._isPayment(notification)) {
        notifications.push(new PaymentNotification(notification))
      }

      if (this._isSynchronization(notification)) {
        notifications.push(new SynchronizationNotification(notification))
      }

      if (this._isHoliday(notification)) {
        notifications.push(new HolidayNotification(notification))
      }
    }

    return notifications
  }

  static _isLeave(notification) {
    return (
      notification.type.includes('leave') &&
      !Notifications._isImport(notification)
    )
  }

  static _isOvertime(notification) {
    return notification.type.includes('overtime')
  }

  static _isImport(notification) {
    return notification.type.includes('import')
  }

  static _isAllowance(notification) {
    return (
      notification.type.includes('allowance') &&
      !Notifications._isImport(notification)
    )
  }

  static _isPayment(notification) {
    return notification.type.includes('payment')
  }

  static _isSynchronization(notification) {
    return notification.type.includes('calendar_synchronization')
  }

  static _isHoliday(notification) {
    return notification.type.includes('holiday')
  }
}
