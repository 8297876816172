<template>
  <div class="tw-flex tw-flex-col tw-flex-1">
    <div class="tw-flex-1 tw-relative">
      <div class="sm:tw-absolute sm:tw-overflow-y-auto tw-h-full tw-w-full">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ScrollableContent',
}
</script>
