<template>
  <div>
    <multiselect
      ref="multiSelect"
      v-model="selectedEmployments"
      :options="groupedEmployments"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :internal-search="false"
      :show-labels="false"
      :max-height="300"
      :group-select="true"
      group-label="department"
      group-values="employments"
      placeholder="Search and select"
      track-by="id"
      class="input-chips input-chips_basic"
      data-cy="emp-search-select"
      @search-change="setSearchQuery"
      @remove="onRemove"
      @close="handleClosedEvent"
    >
      <template slot="option" slot-scope="props">
        <multiple-employment-picker-option
          :props="props"
          :selected-employments="selectedEmployments"
          :grouped-employments="groupedEmployments"
        />
      </template>

      <template slot="placeholder">
        <span class="tw-font-normal">
          {{ 'No employees selected' }}
        </span>
      </template>

      <span slot="noResult">No results were found.</span>

      <template slot="selection" slot-scope="{ isOpen }">
        <span
          v-if="value.length === 1"
          class="multiselect__single tw-font-normal tw-flex tw-items-center"
        >
          <UserAvatar class="tw-mr-2" size="sm" :user="selectedEmployment" />

          {{ selectedEmployment.full_name }}
        </span>
        <span
          v-else-if="value.length > 1"
          class="multiselect__single tw-font-normal"
        >
          {{ value.length }}
          {{ 'employee' | pluralize(value.length) }} selected
        </span>
        <span v-else-if="isOpen" class="multiselect__single tw-font-normal">
          {{ 'No employees selected' }}
        </span>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { Multiselect } from 'vue-multiselect'
import { map, groupBy, head, remove } from 'lodash-es'
import MultipleEmploymentPickerOption from './MultipleEmploymentPickerOption'

export default {
  name: 'MultipleEmploymentPicker',

  components: { Multiselect, MultipleEmploymentPickerOption },

  props: {
    value: {
      type: Array,
      default: null,
    },

    employments: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      removedEmployments: [],
      searchQuery: null,
    }
  },

  computed: {
    selectedEmployment() {
      return head(this.selectedEmployments)
    },

    groupedEmployments() {
      const groupedEmployments = groupBy(
        map(this.filteredEmployments, employment => {
          return {
            ...employment,
            is_selected: this.selectedEmployments.some(selectedEmployment => {
              return selectedEmployment.id === employment.id
            }),
          }
        }),
        'department.name'
      )

      return map(groupedEmployments, employments => {
        return {
          department: head(employments).department?.id
            ? head(employments).department.name
            : 'No Department',
          employments,
        }
      })
    },

    selectedEmployments: {
      get() {
        return this.value
      },
      set(selectedEmployments) {
        this.updateEmploymentsList(selectedEmployments)
      },
    },

    filteredEmployments() {
      if (!this.searchQuery) {
        return this.employments
      }

      const searchQuery = this.searchQuery.trim().toLowerCase()

      return this.employments.filter(function(employment) {
        return employment.full_name.toLowerCase().indexOf(searchQuery) >= 0
      })
    },
  },

  methods: {
    updateEmploymentsList(employments) {
      if (this.removedEmployments.length) {
        remove(employments, employment => {
          return this.removedEmployments.some(
            removedEmployment => removedEmployment.id === employment.id
          )
        })

        this.removedEmployments = []
      }

      this.$emit('input', employments)
    },

    setSearchQuery(query) {
      this.searchQuery = query
    },

    hide() {
      this.$refs.multiSelect.$refs.search.blur()
    },

    open() {
      this.$refs.multiSelect.activate()
    },

    onRemove(removedEmployments) {
      if (removedEmployments.length) {
        this.removedEmployments = removedEmployments
      }
    },

    handleClosedEvent() {
      this.$emit('close', this.employments)
    },
  },
}
</script>
