import moment from 'moment'
import ProratedAllowance from '@/models/employment/ProratedAllowance'

export default class ProratedAllowanceReport {
  constructor(data) {
    this.policy = data.policy

    this.employeeWorkingPercentage = data.employment_working_percentage

    this.allowanceYear = {
      startDate: moment.utc(data.allowance_year.start_date),
      endDate: moment.utc(data.allowance_year.end_date),
    }

    this.employmentWorkingPeriod = {
      startDate: moment.utc(data.employment_working_period.start_date),
      endDate: moment.utc(data.employment_working_period.end_date),
    }

    this.allowances = data.allowances.map(
      allowance => new ProratedAllowance(allowance)
    )
  }
}
