import moment from 'moment-timezone'

export default class Duration {
  constructor({ duration, from, to, duration_in_minutes }) {
    this.name = duration
    this.from = moment.utc(from)
    this.to = moment.utc(to)
    this.durationInMinutes = duration_in_minutes
  }

  get date() {
    return this.from.clone().startOf('date')
  }

  get isCustom() {
    return this.name === 'custom'
  }

  update({ from, to }) {
    this.from = from
    this.to = to
  }

  toJson() {
    return {
      duration: this.name,
      from: this.from.format('YYYY-MM-DD HH:mm:ss'),
      to: this.to.format('YYYY-MM-DD HH:mm:ss'),
    }
  }
}
