import LeaveEvent from '@/components/requests/leave/events/LeaveEvent'

export default class CommentEvent extends LeaveEvent {
  asText(viewer) {
    return `
      **${this.getActorName(viewer)}**
      ${this._getHaveOrHas(viewer)} added a
      new comment
    `
  }

  getCommentText(viewer) {
    return this.isDeleted()
      ? this._makeDeletedMessage(viewer)
      : this.event.resource.text
  }

  canDeleteComment(viewer) {
    return !this.isDeleted() && (viewer.isAdmin() || this.viewerIsActor(viewer))
  }

  canRestoreComment(viewer) {
    return this.isDeleted() && (viewer.isAdmin() || this.viewerIsActor(viewer))
  }

  isDeleted() {
    return !!this.event.resource.deleted_at
  }

  _makeDeletedMessage(viewer) {
    return `${this._getDeleterName(viewer)} deleted this comment`
  }

  _getDeleterName(viewer) {
    return this._viewerIsDeleter(viewer)
      ? 'You'
      : this.event.resource.deleter.full_name +
          (this._isDeleterDeleted() ? ' (Archived)' : '')
  }

  _isDeleterDeleted() {
    return this.event.resource.deleter.deleted_at !== null
  }

  _viewerIsDeleter(viewer) {
    return viewer.id === this.event.resource.deleter.id
  }
}
