import Vue from 'vue'
import '@/plugins/vee-validate'
import VueGtm from 'vue-gtm'
import config from '@/config'
import VueTour from 'vue-tour'
import router from '@/router'
import Modal from 'vue-js-modal'
import echo from '@/plugins/echo'
import http from '@/plugins/http'
import PortalVue from 'portal-vue'
import FlagIcon from 'vue-flag-icon'
import * as Sentry from '@sentry/vue'
import VueClipboard from 'vue-clipboard2'
import Notification from 'vue-notification'
import { BrowserTracing } from '@sentry/tracing'
import vueNumeralFilterInstaller from 'vue-numeral-filter'
import VueSlideoutPanel from 'vue2-slideout-panel'
import { SentryLogger } from '@/plugins/SentryLogger'

const inProduction = process.env.NODE_ENV === 'production'

Vue.use(VueTour)
Vue.use(FlagIcon)
Vue.use(PortalVue)
Vue.use(VueClipboard)
Vue.use(Notification)
Vue.use(vueNumeralFilterInstaller)
Vue.use(Modal, { dialog: true })
Vue.use(VueSlideoutPanel)
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID,
  vueRouter: router,
  enabled: inProduction,
})

const tracingOrigins = [
  'api.leavedates.com',
  'dev.api.leavedates.com',
  'stg-api.leavedates.com',
]
Sentry.init({
  Vue,
  logErrors: !inProduction,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [...tracingOrigins, /^\//],
    }),
  ],
  environment: process.env.VUE_APP_ENV,
  release: `web@${config.app.version}`,
  tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,
  beforeSend: event => SentryLogger.handle(process.env, event),
})

export { http, echo }
