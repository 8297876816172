import EmploymentAllowanceCollection from '@/models/reporting/EmploymentAllowanceCollection'

export default class PaginatedDetailedAllowances {
  constructor(paginate) {
    this.total = paginate.total
    this.per_page = paginate.per_page
    this.current_page = paginate.current_page

    this.data = new EmploymentAllowanceCollection(paginate.data)
  }

  items() {
    return this.data
  }
}
