import gql from 'graphql-tag'

export default gql`
  query Employments(
    $company: String!
    $employmentsQuery: QueryEmploymentInput
  ) {
    employments(company: $company, employmentsQuery: $employmentsQuery) {
      employments {
        id
        full_name: fullName
        avatar_url: avatarUrl
        timezone
        minutes_per_working_day: minutesPerWorkingDay
        allowance_unit_is_days: allowanceUnitIsDays
      }
      departments {
        id
        name
        ownerIds
      }
      workingSchedules {
        id
        ownerIds
        breakdowns {
          id
          session
          iso_week_day: isoWeekDay
          start_time: startTime
          end_time: endTime
        }
      }
    }
  }
`
