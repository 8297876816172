import LeavePermission from '@/components/requests/leave/LeavePermission'
import { orderBy, sumBy } from 'lodash-es'
import Employment from '@/models/employment/Employment'
import moment from 'moment-timezone'

export default class Leave {
  constructor(leave, company) {
    Object.keys(leave).forEach(property => {
      this[property] = leave[property]
    })

    this.owner = new Employment(leave.owner, company)
  }

  setOwner(employment) {
    this.owner = employment
  }

  getKey() {
    return this.id
  }

  isPrivate() {
    return this.is_private ?? false
  }

  getOwnerKey() {
    return this.owner_id
  }

  getOwnerFullName() {
    return this.owner.full_name
  }

  getTypeKey() {
    return this.type_id
  }

  getTypeName() {
    return this.type.name
  }

  getAllowanceTypeKey() {
    return this.allowance_type_id
  }

  getAttachments(orderByColumn = 'created_at') {
    return orderBy(this.attachments, orderByColumn)
  }

  getMinutesPerWorkingDay() {
    return this.minutes_per_working_day
  }

  hasAffectToEmploymentAllowances() {
    return !!this.allowance_type_id ?? false
  }

  getLeaveDuration() {
    return this.leave_duration
  }

  getBreakdowns() {
    return this.leave_breakdowns?.map(breakdown => ({
      ...breakdown,
      durationInMinutes: breakdown.duration_in_minutes,
    }))
  }

  getAttachmentCount() {
    return this.attachments.length
  }

  canApprove(viewer) {
    return this.isRequested() && LeavePermission.canApprove(this, viewer)
  }

  canCancel(viewer) {
    return LeavePermission.canCancel(this, viewer)
  }

  canEdit(viewer) {
    return LeavePermission.canEditLeave(this, viewer)
  }

  canAddComment(commenter) {
    return LeavePermission.canAddComment(this, commenter)
  }

  canAddAttachments(viewer) {
    return LeavePermission.canAddAttachments(this, viewer)
  }

  isApproved() {
    return this.status === 'APPROVED'
  }

  isRequested() {
    return this.status === 'REQUESTED'
  }

  isRejected() {
    return this.status === 'REJECTED'
  }

  isCancelled() {
    return this.status === 'CANCELLED'
  }

  isCancellable() {
    return !this.isCancelled() && !this.isRejected()
  }

  getCurrentAllowance(remainingAllowance) {
    if (this.isApproved() || this.isRequested()) {
      return remainingAllowance + this.getChangedAllowance()
    }

    return remainingAllowance
  }

  getNewAllowance(remainingAllowance) {
    if (this.isApproved() || this.isRequested()) {
      return remainingAllowance
    }

    return remainingAllowance - this.getChangedAllowance()
  }

  getChangedAllowance() {
    let divider = this.allowanceUnitIsDays ? this.minutesPerWorkingDay : 60

    return sumBy(this.getBreakdowns(), 'durationInMinutes') / divider
  }

  get minutesPerWorkingDay() {
    return this.minutes_per_working_day
  }

  get allowanceUnit() {
    return this.allowanceUnitIsDays ? 'days' : 'hours'
  }

  get allowanceUnitIsDays() {
    return this.allowance_unit_is_days
  }

  getBreakdownsToRedraft() {
    return {
      owner_id: this.getOwnerKey(),
      breakdowns: this.getBreakdowns().map(breakdown => {
        const timezone = this.timezone

        return {
          type: breakdown.type,
          duration: breakdown.duration,
          from: moment
            .utc(breakdown.from)
            .tz(timezone)
            .format('YYYY-MM-DD HH:mm:ss'),
          to: moment
            .utc(breakdown.to)
            .tz(timezone)
            .format('YYYY-MM-DD HH:mm:ss'),
          duration_in_minutes: breakdown.duration_in_minutes,
        }
      }),
    }
  }

  getPeriod() {
    return [this._toDate(this.start), this._toDate(this.end)].join(',')
  }

  get start() {
    return this._toMoment(this.from)
  }

  get end() {
    return this._toMoment(this.to)
  }

  _toMoment(time) {
    return moment.utc(time).tz(this.timezone)
  }

  _toDate(date) {
    return date.format('YYYY-MM-DD')
  }
}
