<template>
  <BaseAlert class="tw-p-3 tw-mt-2" theme="danger">
    It is not possible to request leave which crosses the boundary of the leave
    allowance year. Please raise separate requests for this year and next year.
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'

export default {
  name: 'CrossAllowanceYearBoundaryError',
  components: {
    BaseAlert,
  },
}
</script>
