<template>
  <div class="tw-rounded-lg" data-cy="leave-attachments">
    <FileUpload
      v-if="canAddAttachments"
      ref="fileUpload"
      :input-id="computedId"
      :multiple="true"
      :drop="true"
      class="tw-w-full"
      @input-file="saveAttachments"
      @input="attachmentErrors = []"
    >
      <div
        class="tw-py-10 tw-flex tw-rounded-lg tw-border tw-border-gray-330 tw-cursor-pointer"
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-text-gray-550"
        >
          <div
            class="tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-bg-gray-103 tw-rounded-full"
          >
            <svg-icon class="tw-w-6 tw-h-6 tw-text-gray-660" name="upload" />
          </div>

          <div class="tw-mt-3 tw-text-lg">
            <span class="tw-text-blue-550">Click to upload</span> or drag and
            drop
          </div>
        </div>
      </div>
    </FileUpload>
    <Portal
      v-if="attachments.length || attachmentErrors.length"
      :disabled="!attachmentsPortalTarget"
      :to="attachmentsPortalTarget"
    >
      <div
        class="tw-space-y-2"
        :class="{ 'tw-mt-2': !attachmentsPortalTarget }"
      >
        <BaseAttachment
          v-for="attachment in attachments"
          :key="attachment.id"
          class="base-attachment"
          :attachment="attachment"
          :can-download="directUpload"
          :can-delete="canAddAttachments"
          @retry-upload="retryUpload(attachment)"
          @delete-attachment="deleteAttachment(attachment)"
        />

        <AttachmentErrorMessage
          v-for="(attachmentError, key) in attachmentErrors"
          :key="key"
          class="attachment-error-message"
          :attachment-error="attachmentError"
          @remove-attachment-error="removeAttachmentError(key)"
        />
      </div>
    </Portal>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component/src/FileUpload'
import BaseAttachment from '@/components/BaseAttachment'
import ValidatesForm from '@/mixins/ValidatesForm'
import { delay, filter, map, uniqBy } from 'lodash-es'
import moment from 'moment-timezone'
import SupportedMimeType from '@/support/SupportedMimeType'
import AttachmentErrorMessage from '@/components/AttachmentErrorMessage'
import { Attachments } from '@/api'

export default {
  name: 'AttachmentInput',

  components: {
    AttachmentErrorMessage,
    FileUpload,
    BaseAttachment,
  },

  mixins: [ValidatesForm],

  props: {
    currentAttachments: {
      type: Array,
      default: () => [],
    },

    attachmentsPortalTarget: {
      type: String,
      default: null,
    },

    directUpload: {
      type: Boolean,
      default: false,
    },

    unuploadedAttachments: {
      type: Array,
      default: () => [],
    },

    uploadedAttachments: {
      type: Array,
      default: () => [],
    },

    canAddAttachments: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      attachments: [],
      attachmentErrors: [],
      temporaryAttachmentKeys: [],
    }
  },

  computed: {
    computedId() {
      return this._uid.toString()
    },
  },

  watch: {
    currentAttachments: {
      immediate: true,
      handler(currentAttachments) {
        this.setAttachments(currentAttachments)
      },
    },

    unuploadedAttachments(unuploadedAttachments) {
      this.setUnuploadedAttachmentErrors(unuploadedAttachments)
    },

    uploadedAttachments(uploadedAttachments) {
      this.setTemporaryAttachmentsWithoutUploadedAttachments(
        uploadedAttachments
      )
    },
  },

  methods: {
    setUnuploadedAttachmentErrors(unuploadedAttachments) {
      if (!unuploadedAttachments.length) {
        return
      }

      this.attachments = map(this.attachments, function(attachment) {
        const unuploadedAttachment = unuploadedAttachments.find(
          unuploadedAttachment => unuploadedAttachment.id === attachment.id
        )

        if (unuploadedAttachment) {
          return {
            ...attachment,
            error: 'upload failed',
            active: false,
          }
        }

        return attachment
      })
    },

    setTemporaryAttachmentsWithoutUploadedAttachments(uploadedAttachments) {
      this.temporaryAttachmentKeys = this.temporaryAttachmentKeys.filter(
        attachmentKey =>
          !this.isAttachmentUploaded(uploadedAttachments, attachmentKey)
      )
    },

    isAttachmentUploaded(uploadedAttachments, attachmentKey) {
      return uploadedAttachments.some(
        uploadedAttachment => uploadedAttachment.id === attachmentKey
      )
    },

    setAttachments(currentAttachments) {
      this.attachments = uniqBy(
        [
          ...this.mapCurrentAttachments(currentAttachments),
          ...this.getTemporaryAttachments(),
        ],
        'id'
      )
    },

    mapCurrentAttachments(currentAttachments) {
      return currentAttachments.map(attachment => ({
        ...attachment,
        active: false,
        error: '',
        progress: 0,
      }))
    },

    getTemporaryAttachments() {
      return this.attachments.filter(attachment =>
        this.temporaryAttachmentKeys.includes(attachment.id)
      )
    },

    removeAttachmentError(key) {
      this.attachmentErrors.splice(key, 1)
    },

    async saveAttachments(file) {
      const error = this.getAttachmentError(file)

      if (error) {
        this.focusToErrorMessage(error)

        return
      }

      try {
        const attachment = await Attachments.upload(
          this.activeCompany,
          file,
          this.handleUpload
        )

        if (this.directUpload) {
          this.$emit('save-attachment', attachment)

          return
        }

        this.$emit('update-attachments', this.attachments)

        this.focusToAttachmentList()
      } catch (error) {
        this.addAttachmentToList(error)
        this.setError(error)
      }
    },

    getAttachmentError(attachment) {
      if (!this.isSupportedMimeType(attachment)) {
        return (
          attachment.name +
          " couldn't upload since it is an unsupported file type"
        )
      }

      if (!this.isValidAttachmentsSize(attachment)) {
        return attachment.name + ' file is larger than allowable 50MB.'
      }

      return null
    },

    focusToErrorMessage(error) {
      this.attachmentErrors.push(error)

      this.focusTo('attachment-error-message')
    },

    focusToAttachmentList() {
      this.focusTo('base-attachment')
    },

    focusTo(className) {
      if (this.attachmentsPortalTarget) return

      this.$nextTick(() => {
        this.$el.querySelector(`.${className}`).scrollIntoView()
      })
    },

    handleUpload(attachment, progressEvent = null) {
      this.addAttachmentToList(attachment)

      if (progressEvent) {
        this.setProgress(attachment, progressEvent)
      }
    },

    addAttachmentToList(attachment) {
      if (!this.temporaryAttachmentKeys.includes(attachment.id)) {
        this.temporaryAttachmentKeys.push(attachment.id)
        this.attachments.push({
          ...attachment,
          active: true,
          creator: this.activeEmployment,
          updated_at: moment(),
        })
      }
    },

    removeAttachmentFromList(attachment) {
      this.attachments = filter(
        this.attachments,
        existingAttachment => existingAttachment.id !== attachment.id
      )

      this.temporaryAttachmentKeys = this.temporaryAttachmentKeys.filter(
        attachmentKey => attachmentKey !== attachment.id
      )

      this.$emit('attachment-count-decreased')
    },

    retryUpload(attachment) {
      this.attachmentErrors = []
      this.removeAttachmentFromList(attachment)

      this.saveAttachments({ ...attachment, error: '', progress: 0 })
    },

    setProgress(attachment, progressEvent) {
      const progress = (progressEvent.loaded / progressEvent.total) * 100

      this.updateAttachment(attachment, {
        progress: progress,
      })

      if (progress === 100) {
        delay(
          () =>
            this.updateAttachment(attachment, {
              active: false,
            }),
          1300
        )
      }
    },

    setError(attachment) {
      this.updateAttachment(attachment, {
        error: 'upload failed',
        active: false,
      })
    },

    updateAttachment(attachment, properties) {
      this.attachments = map(this.attachments, existingAttachment =>
        existingAttachment.id === attachment.id
          ? {
              ...existingAttachment,
              ...properties,
            }
          : existingAttachment
      )
    },

    async deleteAttachment(attachment) {
      if (!this.directUpload || this.hasError(attachment)) {
        this.removeAttachmentFromList(attachment)

        this.$emit('update-attachments', this.attachments)

        return
      }

      let confirmed = await this.confirm(
        'Are you sure you want to delete?',
        'Confirm delete'
      )

      if (confirmed) {
        try {
          this.removeAttachmentFromList(attachment)

          await Attachments.delete(attachment, {
            company: this.activeCompany.id,
          })

          this.$emit('attachment-removed')
        } catch ({ response }) {
          this.attachments = [...this.attachments, attachment]

          this.validateFromResponse(response)
        }
      }
    },

    hasError(attachment) {
      return attachment.error !== ''
    },

    isValidAttachmentsSize(newAttachment) {
      return newAttachment.size < 50000000
    },

    isSupportedMimeType(attachment) {
      return SupportedMimeType.hasMimeType(attachment.type)
    },
  },
}
</script>
