import http from '@/plugins/http'

export default class Company {
  static async create(params) {
    return await http.post('/companies', params)
  }

  static async update(company, params) {
    return await http.put(`companies/${company.id}`, {
      company_id: company.id,
      ...params,
    })
  }

  static async all() {
    return await http.get('companies')
  }

  static async delete(company) {
    return await http.delete(`companies/${company.id}`)
  }
}
