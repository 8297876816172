import OvertimeBreakdownUnit from '@/models/overtime/OvertimeBreakdownUnit'

export default class Hours extends OvertimeBreakdownUnit {
  constructor(date, amount) {
    super(date, amount)
  }

  get unit() {
    return 'hours'
  }
}
