import RequestAllowance from '@/models/employment/RequestAllowance'

export default class LimitedAllowance extends RequestAllowance {
  constructor(adjuster, availableAllowances) {
    super(adjuster)

    this.availableAllowances = availableAllowances
  }

  get currentAllowance() {
    return this.adjuster.getCurrentAllowance(this.availableAllowances)
  }

  get newAllowance() {
    return this.adjuster.getNewAllowance(this.availableAllowances)
  }

  get changedAllowance() {
    return this.adjuster.getChangedAllowance()
  }

  get hasUnlimitedAllowances() {
    return false
  }
}
