import expiringStorage from '@/support/expiringStorage'

export default {
  methods: {
    async login(credentials, tour = false) {
      const query = tour ? { tour: 'yes' } : null
      const redirectPath = this.$route.query.redirect

      await this.$http.get('sanctum/csrf-cookie')

      await this.$http.post('login', credentials)

      this.$recaptchaInstance.hideBadge()

      if (redirectPath) {
        return this.$router.push(redirectPath)
      }

      return this.$router.push({ name: 'wall-chart', query }, () => {})
    },

    async logout(reasonFlag = null) {
      try {
        expiringStorage.clear()

        await this.$http.post('logout')
        let redirectUrl = '/signin?redirect=' + this.$route.path

        if (reasonFlag) {
          redirectUrl += '&reason=' + reasonFlag
        }

        window.location.href = redirectUrl
      } catch (e) {
        //
      }
    },

    async impersonate(email) {
      try {
        await this.$http.post('impersonate', { email })

        window.location.href = '/'
      } catch (e) {
        throw e
      }
    },

    async unimpersonate() {
      try {
        await this.$http.delete('unimpersonate')

        window.location.href = '/'
      } catch (e) {
        throw e
      }
    },
  },
}
