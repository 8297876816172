import Mousetrap from 'mousetrap'

export default {
  computed: {
    employmentId() {
      return this.activeEmployment.id
    },

    isAdmin() {
      return this.activeEmployment && this.activeEmployment.is_admin
    },
  },

  mounted() {
    // Pages shortcuts
    Mousetrap.bind('shift+1', e => {
      e.preventDefault()
      this.$router.push({ name: 'leave-summary' }, () => {})
    })

    Mousetrap.bind('shift+2', e => {
      e.preventDefault()
      this.$router.push({ name: 'wall-chart' }, () => {})
    })

    Mousetrap.bind('shift+3', e => {
      e.preventDefault()
      this.$router.push({ name: 'my-year' }, () => {})
    })

    Mousetrap.bind('shift+4', e => {
      e.preventDefault()
      this.$router.push({ name: 'reports-allowances' }, () => {})
    })

    Mousetrap.bind('alt+c', e => {
      e.preventDefault()
      this.$router.push({ name: 'companies' }, () => {})
    })

    Mousetrap.bind('alt+p', e => {
      e.preventDefault()
      this.$router.push({ name: 'my-profile' }, () => {})
    })

    Mousetrap.bind('alt+i', e => {
      e.preventDefault()
      this.$modal.toggle('shortcut-modal')
    })

    Mousetrap.bind('alt+n', e => {
      e.preventDefault()
      this.$router.push({ name: 'notifications' }, () => {})
    })

    // help model shortcut
    Mousetrap.bind('alt+h', e => {
      e.preventDefault()
      this.$modal.show('help-modal')
    })

    // settings shortcuts
    Mousetrap.bind('shift+s', e => {
      e.preventDefault()
      this.$router.push({ name: 'company' }, () => {})
    })

    Mousetrap.bind('shift+d', e => {
      e.preventDefault()
      this.$router.push({ name: 'departments' }, () => {})
    })

    Mousetrap.bind('shift+a', e => {
      e.preventDefault()
      this.$router.push({ name: 'allowances' }, () => {})
    })

    Mousetrap.bind('shift+l', e => {
      e.preventDefault()
      this.$router.push({ name: 'leave-types' }, () => {})
    })

    Mousetrap.bind('shift+h', e => {
      e.preventDefault()
      this.$router.push({ name: 'holidays' }, () => {})
    })

    Mousetrap.bind('shift+e', e => {
      e.preventDefault()
      this.$router.push({ name: 'employees' }, () => {})
    })

    Mousetrap.bind('shift+c', e => {
      e.preventDefault()
      this.$router.push({ name: 'calendars' }, () => {})
    })

    Mousetrap.bind('shift+m', e => {
      e.preventDefault()
      this.$router.push({ name: 'limits' }, () => {})
    })

    Mousetrap.bind('shift+i', e => {
      e.preventDefault()
      this.$router.push({ name: 'import' }, () => {})
    })

    Mousetrap.bind('shift+t', e => {
      e.preventDefault()
      this.$router.push({ name: 'integrations' }, () => {})
    })

    Mousetrap.bind('shift+b', e => {
      e.preventDefault()
      this.$router.push({ name: 'billing' }, () => {})
    })

    this.$once('hook:beforeDestroy', () => {
      Mousetrap.unbind(
        'shift+1',
        'shift+2',
        'shift+3',
        'shift+4',
        'alt+h',
        'alt+n',
        'alt+c',
        'alt+p',
        'alt+i',
        'shift+s',
        'shift+d',
        'shift+a',
        'shift+l',
        'shift+e',
        'shift+c',
        'shift+m',
        'shift+i',
        'shift+t',
        'shift+b'
      )
    })
  },
}
