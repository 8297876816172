<template>
  <div class="tw-grid tw-grid-cols-4 tw-gap-2 ">
    <template v-for="(leave, index) in overlapLeaves">
      <div :key="index" class="tw-flex tw-ml-2 tw-pr-1">
        <UserAvatar
          :key="leave.owner_id"
          :user="leave.owner"
          :show-tooltip="true"
          tooltip-placement="top"
          class="tw-border-2 tw-border-white tw--ml-2 tw-rounded-full"
        />
        <div
          :key="index"
          class="tw-flex tw-mx-2 tw-mt-2  tw-font-sans tw-font-normal tw-text-sm tw-leading-tight tw-text-white "
          data-cy="leave-owner-full-name"
        >
          {{ leave.owner.full_name }}
        </div>
      </div>
      <div
        :key="'leaveType' + index"
        class="tw-flex tw-mt-2 tw-ml-2 tw-font-sans tw-font-normal tw-text-sm tw-leading-tight tw-text-white "
        data-cy="leave-type"
      >
        {{ leave.type.name }}
      </div>
      <div
        :key="'leaveDate' + index"
        class="tw-flex tw-mt-2 tw-ml-2 tw-font-sans tw-font-normal tw-text-sm tw-leading-tight tw-text-white "
        data-cy="leave-date"
      >
        {{ leaveDate(leave) }}
      </div>
      <div :key="'viewButton' + index" class=" tw-flex tw-ml-12">
        <button
          data-cy="btn-view-details"
          class="tw-flex tw-m-0 tw-flex-row tw-justify-center tw-items-center tw-bg-blue-200 tw-border tw-rounded tw-text-blue-700 tw-font-medium tw-text-smaller tw-leading-none tw-w-24 tw-h-10  "
          @click="$emit('show-overlap-leave-details', leave.id)"
        >
          View Details
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'OverlapLeave',

  props: {
    overlapLeaves: {
      type: Array,
      required: true,
    },
  },

  methods: {
    leaveDate(leave) {
      let from = moment
        .utc(leave.from)
        .tz(leave.timezone)
        .format('D MMM')
      let to = moment
        .utc(leave.to)
        .tz(leave.timezone)
        .format('D MMM')

      let secondPart = ''
      if (from !== to) {
        secondPart = ' - ' + to
      }

      return from + secondPart
    },
  },
}
</script>
