<template>
  <a
    ref="reference"
    title="Help"
    data-cy="btn-help"
    class="tw-flex tw-cursor-pointer tw-no-underline tw-text-sm tw-leading-none tw-text-gray-800 tw-px-6 tw-py-5"
  >
    <span class="tw-relative">
      <SvgIcon
        name="question-stroke"
        class="tw-w-6 tw-h-6 tw-pointer-events-none"
      />
    </span>

    <div v-show="false" ref="tooltip">
      <div class="tw-w-80 tw-overflow-hidden" style="min-width: 200px;">
        <div class="tw-border-b tw-border-gray-300 tw-px-4 tw-pt-2">
          <div class="tw-flex tw-items-center tw-justify-start tw-relative">
            <span
              class="tw-pb-2 tw-text-gray-800 tw-text-thin tw-font-semibold tw-cursor-default"
            >
              Help
            </span>

            <button
              class="tw-opacity-50 hover:tw-opacity-100 modal-close tw-absolute tw-right-0 tw-top-0"
              @click="hideTippy"
            >
              <SvgIcon name="close" style="width: 12px; height: 12px;" />
            </button>
          </div>
        </div>

        <div class="tw-pb-0">
          <div class="tw-border-t tw-border-b tw-border-gray-300">
            <div class="tw-flex tw-flex-col">
              <a
                class="tw-cursor-pointer tw-no-underline hover:tw-bg-gray-300 tw-overflow-hidden tw-px-4 tw-py-3"
                @click.prevent="modalShortcut"
              >
                <span class="tw-text-gray-800">Keyboard shortcuts</span>
              </a>

              <a
                class="tw-cursor-pointer tw-no-underline hover:tw-bg-gray-300 tw-overflow-hidden tw-px-4 tw-py-3"
                data-cy="btn-help-center"
                :href="helpCenterUrl"
                target="_blank"
              >
                <span class="tw-text-gray-800">Help Center</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import documentationUrls from '@/documentations/documentation-urls'
import tippy from 'tippy.js'

export default {
  name: 'HelpMenu',

  computed: {
    helpCenterUrl() {
      return documentationUrls.helpCenterUrl
    },
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.reference, {
      html: this.$refs.tooltip.firstChild,
      trigger: 'click',
      distance: -10,
      placement: 'bottom-end',
      offset: '0,18',
      interactive: true,
      theme: 'light',
      animation: 'scale',
      inertia: true,
      arrow: true,
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },

  methods: {
    hideTippy() {
      this.tippy.hide()
    },

    modalShortcut() {
      this.hideTippy()
      this.$modal.show('shortcut-modal')
    },
  },
}
</script>
