<template>
  <button
    class="btn btn-icon"
    :class="classes"
    :title="title"
    @click.stop="$emit('click')"
  >
    <span class="tw-flex tw-items-center tw-justify-center">
      <svg-icon :name="icon" :class="iconClasses" />
    </span>
  </button>
</template>

<script>
const THEMES = {
  gray: ['tw-border-gray-400', 'hover:tw-bg-gray-300'],

  red: ['tw-bg-red-500', 'hover:tw-bg-red-500'],
}

export default {
  name: 'IconButton',

  props: {
    theme: {
      type: String,
      default: 'gray',
    },

    icon: {
      type: String,
      default: 'edit-pencil',
    },

    title: {
      type: String,
      default: 'Edit',
    },

    width: {
      type: Number,
      default: null,
    },

    height: {
      type: Number,
      default: null,
    },

    showBorder: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    themeClasses() {
      return THEMES[this.theme]
    },

    iconThemeClasses() {
      if (this.theme === 'gray') {
        return ['tw-text-gray-700']
      }

      return ['tw-text-white']
    },

    iconSizeClasses() {
      if (!this.width || !this.height) {
        return ['svg-icon']
      }

      return [`tw-w-${this.width}`, `tw-h-${this.height}`]
    },

    iconClasses() {
      return [...this.iconThemeClasses, ...this.iconSizeClasses]
    },

    borderClasses() {
      if (!this.showBorder) {
        return []
      }

      return ['tw-border']
    },

    classes() {
      return [...this.themeClasses, ...this.borderClasses]
    },
  },
}
</script>
