<template>
  <div class="tw-flex tw-flex-col tw-flex-1 tw-h-full tw-justify-between">
    <div
      class="tw-p-4 tw-border tw-rounded-lg tw-bg-gray-102 tw-border-gray-220 tw-flex tw-flex-col"
    >
      <div>
        <div>
          <div class="tw-text-base tw-font-semibold">
            Leave request
          </div>
          <div class="tw-flex tw-justify-between">
            <div class="tw-mt-4 tw-space-y-3">
              <p class="tw-font-semibold">
                <span class="tw-font-normal tw-text-gray-600">From:</span>
                {{ fromTime.date }}
                <span class="tw-font-normal tw-text-gray-600">at</span>
                {{ fromTime.time }}
              </p>
              <p class="tw-font-semibold">
                <span class="tw-font-normal tw-text-gray-600">To:</span>
                {{ toTime.date }}
                <span class="tw-font-normal tw-text-gray-600">at</span>
                {{ toTime.time }}
              </p>
            </div>
            <div
              class="tw-flex tw-flex-col tw-items-end tw-justify-end tw-mt-4"
            >
              <div
                class="tw-text-lg tw-font-semibold tw-text-gray-880"
                data-cy="leave-duration"
              >
                {{ leaveDuration }}
              </div>

              <LeaveTypeLabel :leave="leave" class="tw-mt-1" />
            </div>
          </div>
        </div>
        <LeaveLimitSummaryWarning
          :leave="leave"
          @show-overlap-leave-details="
            leaveKey => $emit('show-overlap-leave-details', leaveKey)
          "
        />
        <div class="tw-my-5 tw-w-full tw-border-t tw-border-gray-350" />
        <div
          v-if="activeEmployment.hasPermissionsToSeeMoreDetails(leave.owner)"
        >
          <div>
            <div class="tw-text-base tw-font-semibold">Description</div>

            <p class="tw-mt-3 tw-leading-normal tw-text-gray-600">
              {{ leave.reason || '-' }}
            </p>
          </div>
          <div class="tw-mt-5 tw-mb-2">
            <LeaveRecentUpdates
              :timeline="timeline"
              @show-more-details="$emit('show-more-details')"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="activeEmployment.hasPermissionsToSeeMoreDetails(leave.owner)"
      class="tw-mb-4"
    >
      <div
        v-if="
          leave.hasAffectToEmploymentAllowances() && !isAllowanceCalculating
        "
      >
        <div class="tw-flex tw-justify-between tw-mb-2">
          <h4 class="tw-m-1">Allowance Summary</h4>
          <PortalTarget name="summary-view-switcher" />
        </div>
        <AllowanceDecrementMonitor :allowances="allowances" />
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <BaseAlert
        v-if="!leave.hasAffectToEmploymentAllowances()"
        theme="info"
        class="tw-mt-2"
      >
        <span v-html="employmentAllowanceHasNoEffectText"></span>
      </BaseAlert>
      <!-- eslint-enable -->

      <PortalTarget name="allowance-unlimited-alert" />
    </div>
  </div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import LeaveRecentUpdates from '@/components/requests/leave/LeaveRecentUpdates'
import AllowanceDecrementMonitor from '@/components/requests/leave/AllowanceDecrementMonitor'
import LeaveLimitSummaryWarning from '@/components/requests/leave/LeaveLimitSummaryWarning'
import LeaveTypeLabel from '@/components/requests/leave/LeaveTypeLabel'
import BaseAlert from '@/components/BaseAlert'
import EmploymentAllowanceHasNoEffectText from '@/components/requests/EmploymentAllowanceHasNoEffectText'
import Timeline from '@/components/requests/leave/events/Timeline'
import Leave from '@/models/leave/Leave'
import LeaveAllowances from '@/models/leave/LeaveAllowance'
import RequestDuration from '@/components/requests/RequestDuration'
import EmploymentAllowanceCollection from '@/models/reporting/EmploymentAllowanceCollection'

export default {
  name: 'LeaveSummaryTab',

  components: {
    BaseAlert,
    LeaveTypeLabel,
    AllowanceDecrementMonitor,
    LeaveRecentUpdates,
    LeaveLimitSummaryWarning,
  },

  mixins: [FormatDate],

  props: {
    leave: {
      type: Leave,
      required: true,
    },

    employmentAllowances: {
      type: EmploymentAllowanceCollection,
      required: true,
    },

    timeline: {
      type: Timeline,
      required: true,
    },

    isAllowanceCalculating: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      recentTimelineEvents: [],
    }
  },

  computed: {
    leaveDuration() {
      return new RequestDuration(this.leave.getLeaveDuration()).toString(
        'Total: '
      )
    },

    employmentAllowanceHasNoEffectText() {
      const text = new EmploymentAllowanceHasNoEffectText(this.leave.type)
        .getText()
        .split('**')

      return `${text[0]} <span class="tw-font-semibold">${text[1]}</span> ${text[2]}`
    },

    allowances() {
      return new LeaveAllowances([this.leave], this.employmentAllowances)
    },

    fromTime() {
      return {
        weekday: this.isoStringToWeekday(this.leave.from, this.leave.timezone),
        date: this.formatDateFromIsoToDayReadableNumberMonthYear(
          this.leave.from,
          this.leave.timezone
        ),
        time: this.formatDateFromIsoToHourMinutes(
          this.leave.from,
          this.leave.timezone
        ),
      }
    },

    toTime() {
      return {
        weekday: this.isoStringToWeekday(this.leave.to, this.leave.timezone),
        date: this.formatDateFromIsoToDayReadableNumberMonthYear(
          this.leave.to,
          this.leave.timezone
        ),
        time: this.formatDateFromIsoToHourMinutes(
          this.leave.to,
          this.leave.timezone
        ),
      }
    },
  },
}
</script>
