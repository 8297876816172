import http from '@/plugins/http'
import OverlapCollection from '@/models/reporting/OverlapCollection'

export default class Overlaps {
  static async get(employmentKey, params) {
    const { data } = await http.get(`employments/${employmentKey}/overlaps`, {
      params: params,
    })

    return new OverlapCollection(data)
  }
}
