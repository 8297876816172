<template>
  <DatePicker
    :configurations="configurations"
    :value="selectedDates"
    class="tw-flex tw-items-center tw-justify-start"
    @input="setPickedDates"
    @open="clearDates"
    @close="autofillDates"
  >
    <svg-icon
      name="icon-calendar"
      class="tw-mr-3 tw-w-5 tw-h-5 tw-text-gray-555"
    />

    <div
      class="tw-cursor-pointer tw-text-thin"
      :class="{ 'content-empty': !formattedStartDate }"
      data-text="Select date"
    >
      {{ formattedStartDate }}
    </div>

    <span v-show="startDate" class="tw-mx-2 tw-font-medium">
      -
    </span>

    <div
      v-show="startDate"
      class="tw-cursor-pointer tw-text-thin"
      :class="{ 'content-empty': !formattedEndDate }"
      data-text="Select date"
    >
      {{ formattedEndDate }}
    </div>
  </DatePicker>
</template>

<script>
import moment from 'moment-timezone'
import DatePicker from '@/components/DatePicker'

export default {
  name: 'DateRangePicker',

  components: { DatePicker },

  props: {
    value: {
      type: Array,
      required: true,
    },

    minDate: {
      type: [String, Object],
      default: null,
    },

    maxDate: {
      type: [String, Object],
      default: null,
    },

    format: {
      type: String,
      default: 'DD-MM-YYYY',
    },

    inlinePicker: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      startDate: null,
      endDate: null,
    }
  },

  computed: {
    selectedStartDate() {
      return moment(this.toDateRange(this.value).startDate)
    },

    selectedDates() {
      return [
        this.parseIntoDate(this.startDate),
        this.parseIntoDate(this.endDate),
      ]
    },

    configurations() {
      return {
        mode: 'range',
        showMonths: this.isOnMobileView ? 1 : 2,
        minDate: this.minDate,
        maxDate: this.maxDate,
        static: this.inlinePicker,
      }
    },

    formattedStartDate() {
      return this.formatDate(this.startDate)
    },

    formattedEndDate() {
      return this.formatDate(this.endDate)
    },

    isOnMobileView() {
      return window.innerWidth < 760
    },

    isSelectedDatesValid() {
      return (
        this.startDate &&
        this.startDate.isValid() &&
        this.endDate &&
        this.endDate.isValid()
      )
    },

    defaultViewDate() {
      return this.selectedStartDate.isValid()
        ? this.selectedStartDate.toDate()
        : undefined
    },
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: 'setDates',
    },
  },

  methods: {
    setDates(dates) {
      this.startDate = dates?.[0] ? moment(dates[0]) : null
      this.endDate = dates?.[1] ? moment(dates[1]) : null
    },

    parseIntoDate(date) {
      const parsedDate = moment(date, this.format)

      return parsedDate.isValid() ? parsedDate.toDate() : ''
    },

    setPickedDates(dates) {
      this.startDate = dates?.[0] ? moment(dates[0]) : null
      this.endDate = dates?.[1] ? moment(dates[1]).endOf('day') : null

      if (this.isSelectedDatesValid) {
        this.emitDates()
      }
    },

    formatDate(date) {
      return date ? date.format(this.format) : ''
    },

    emitChanges(startDate, endDate) {
      this.$emit('input', [startDate, endDate])
    },

    clearDates({ picker }) {
      this.setDates([])

      this.$nextTick(() => picker.jumpToDate(this.defaultViewDate))
    },

    autofillDates(dates) {
      if (!dates.length) {
        this.resetDates()

        return
      }

      const dateRange = this.toDateRange(dates)

      this.startDate = moment(dateRange.startDate)

      this.endDate = dateRange.endDate
        ? moment(dateRange.endDate).endOf('day')
        : this.startDate.clone().endOf('day')

      this.emitDates()
    },

    resetDates() {
      this.setDates(this.value)
    },

    emitDates() {
      this.$emit('input', [this.startDate, this.endDate])
    },

    toDateRange(dates) {
      return {
        startDate: dates?.[0],
        endDate: dates?.[1],
      }
    },
  },
}
</script>

<style lang="scss">
.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.prevMonthDay.inRange {
  background-color: #d9eaf2;
  @apply .border-none;
  -webkit-box-shadow: -5px 0 0 #d9eaf2, 5px 0 0 #d9eaf2;
  box-shadow: -5px 0 0 #d9eaf2, 5px 0 0 #d9eaf2;

  &:hover {
    @apply .border-transparent;
    background-color: #e6e6e6;
  }
}

.flatpickr-day.today.inRange {
  @apply .border-transparent;
  background-color: #e6e6e6;
}

.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.today.startRange,
.flatpickr-day.today.endRange,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.prevMonthDay {
  @apply .bg-blue-500;
  @apply .border-transparent;

  &:hover {
    @apply .bg-blue-500;
    @apply .border-transparent;
    @apply .text-white;
  }
}

.flatpickr-day.selected.startRange,
.flatpickr-day.selected.endRange {
  @apply .bg-blue-500;
  @apply .border-transparent;

  &:hover {
    @apply .bg-blue-500;
    @apply .border-transparent;
    @apply .rounded-full;
  }
}

.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  -webkit-box-shadow: -5px 0 0 #d9eaf2 !important;
  box-shadow: -5px 0 0 #d9eaf2 !important;
}

.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:last-child
  .flatpickr-day.inRange:nth-child(7n + 7) {
  -webkit-box-shadow: -5px 0 0 #d9eaf2, 5px 0 0 #d9eaf2 !important;
  box-shadow: -5px 0 0 #d9eaf2, 5px 0 0 #d9eaf2 !important;
}

.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  -webkit-box-shadow: 5px 0 0 #d9eaf2 !important;
  box-shadow: 5px 0 0 #d9eaf2 !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n + 2) {
  border-left: 1px solid rgba(72, 72, 72, 0.2);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 rgba(2, 164, 236, var(--bg-opacity));
  box-shadow: -10px 0 0 rgba(2, 164, 236, var(--bg-opacity));
}

.flatpickr-current-month span.cur-month {
  &:hover {
    @apply .bg-transparent;
  }
}

.flatpickr-day.hidden {
  @apply .visible;
}

.numInputWrapper input:disabled {
  @apply .opacity-100;
}

.content-empty:before {
  color: #a9a9a9;
  content: attr(data-text);
}
</style>
