import { first, maxBy, minBy } from 'lodash-es'

export default class AllowanceSummary {
  constructor(items) {
    this.items = items
    this.calculateAllowanceSummary()
  }

  get allowanceUnit() {
    if (this.hasSingleAllowance) {
      return first(this.items).allowanceUnit
    }

    if (this.allUnlimitedAllowances) {
      return this.hasUnlimitedDaysAllowances ? 'days' : 'hours'
    }

    return this.hasDaysAllowances ? 'days' : 'hours'
  }

  get hasUnlimitedAllowances() {
    return this.allUnlimitedAllowances
  }

  calculateAllowanceSummary() {
    let currentAllowance = new Set()
    let newAllowance = new Set()
    let changedAllowance = new Set()

    this.filteredAllowances.forEach(allowance => {
      currentAllowance.add(allowance.currentAllowance)
      newAllowance.add(allowance.newAllowance)
      changedAllowance.add(allowance.changedAllowance)
    })

    this.currentAllowance = Array.from(currentAllowance)
    this.newAllowance = Array.from(newAllowance)
    this.changedAllowance = Array.from(changedAllowance)
  }

  get filteredAllowances() {
    if (this.hasSingleAllowance || this.allUnlimitedAllowances) {
      return this.items
    }

    if (this.hasDaysAllowances) {
      return this.dayAllowances
    }

    return this.hoursAllowances
  }

  get hasSingleAllowance() {
    return this.items.length === 1
  }

  get allUnlimitedAllowances() {
    return this.items.every(allowance => allowance.hasUnlimitedAllowances)
  }

  get hasUnlimitedDaysAllowances() {
    return this.items.filter(allowance => allowance.allowanceUnit === 'days')
  }

  get hasDaysAllowances() {
    return this.dayAllowances.length > 0
  }

  get dayAllowances() {
    return this.items.filter(
      allowance =>
        allowance.allowanceUnit === 'days' && !allowance.hasUnlimitedAllowances
    )
  }

  get hoursAllowances() {
    return this.items.filter(
      allowance =>
        allowance.allowanceUnit === 'hours' && !allowance.hasUnlimitedAllowances
    )
  }

  get hasNewAllowanceRange() {
    return this.newAllowance.length > 1
  }

  get minimumBalanceAllowance() {
    return minBy(this.newAllowance)
  }

  get maximumBalanceAllowance() {
    return maxBy(this.newAllowance)
  }

  get hasChangedAllowanceRange() {
    return this.changedAllowance.length > 1
  }

  get minimumChangedAllowance() {
    return minBy(this.changedAllowance)
  }

  get maximumChangedAllowance() {
    return maxBy(this.changedAllowance)
  }

  get hasRemainingAllowanceRange() {
    return this.currentAllowance.length > 1
  }

  get minimumRemainingAllowance() {
    return minBy(this.currentAllowance)
  }

  get maximumRemainingAllowance() {
    return maxBy(this.currentAllowance)
  }

  get hasRemainingAllowancesExceeded() {
    return this.newAllowance.some(allowance => allowance < 0)
  }
}
