const documentationUrls = {
  helpCenterUrl: 'https://help.leavedates.com',
  apiDocumentationUrl: 'https://api.leavedates.com/documentation',
  cookieUrl: 'http://leavedates.com/cookie-policy',
  termsUrl: 'http://leavedates.com/our-terms-of-use',
  privacyUrl: 'http://leavedates.com/privacy-policy',
  prorationUrl: 'https://help.leavedates.com/hc/en-us/articles/360021822020',
  companyCalendarUrl:
    'https://help.leavedates.com/hc/en-us/articles/360006150359-Sync-leave-to-Google-Calendar-Outlook-365-or-another-calendar',
  ssoUrl: 'https://help.leavedates.com/hc/en-us/articles/360015779640',
  webhookUrl: 'https://help.leavedates.com/hc/en-us/articles/5747176545042',
  overtimeUrl:
    'https://help.leavedates.com/hc/en-us/articles/360006251779-Time-off-in-Lieu-TOIL-',
  leaveTypeUrl:
    'https://help.leavedates.com/hc/en-us/articles/360002592640-Leave-types',
}

export default documentationUrls
