import ApprovedEvent from '@/components/requests/leave/events/ApprovedEvent'

export default class OneOfBulkLeaveWasImportedEvent extends ApprovedEvent {
  asText(viewer) {
    return `**${this.getActorName(viewer)}**
    ${this._getHaveOrHas(viewer)} imported the request`
  }

  asNotice(viewer) {
    return `Leave request was automatically approved on
    ${this._createdAtText(viewer)}`
  }
}
