<template>
  <v-select
    v-model="selectedOption"
    :options="options"
    :multiple="false"
    :searchable="false"
    :show-labels="false"
    :allow-empty="false"
    :max-height="180"
    track-by="id"
    label="name"
    placeholder=""
    :disabled="disabled"
    data-cy="request-type"
  >
    <template v-slot:singleLabel="props">
      <div
        class="tw-flex tw-justify-start tw-items-center tw-space-x-3 tw-w-11/12"
      >
        <div
          v-if="props.option.colour"
          class="tw-flex tw-items-center tw-justify-center"
        >
          <span
            class="tw-inline-block tw-rounded-full tw-w-3 tw-h-3"
            :style="`background:${props.option.colour}`"
          ></span>
        </div>

        <div class="tw-text-base tw-font-normal tw-truncate">
          {{ props.option.name }}
        </div>
      </div>
    </template>

    <template v-slot:option="props">
      <div
        class="tw-flex tw-justify-start tw-items-center tw-space-x-3 tw-w-11/12"
      >
        <div
          v-if="props.option.colour"
          class="tw-flex tw-items-center tw-justify-center"
        >
          <span
            class="tw-inline-block tw-rounded-full tw-w-3 tw-h-3 color-circle"
            :style="`background:${props.option.colour}`"
          ></span>
        </div>

        <div class="tw-flex tw-flex-col tw-w-full tw-space-y-1">
          <div class="tw-text-base tw-font-normal tw-truncate">
            {{ props.option.name }}
          </div>

          <div
            v-if="props.option.description"
            class="tw-text-sm tw-text-gray-600 tw-truncate description"
            :title="props.option.description"
          >
            {{ props.option.description }}
          </div>
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
import commonOptions from './commonOptions'

export default {
  name: 'RequestTypePicker',

  mixins: [commonOptions],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.multiselect__content {
  @apply .tw-max-w-full;
}

.multiselect__option--highlight .description {
  @apply .tw-text-white;
}

.multiselect__option--highlight .color-circle {
  @apply .tw-border;
  @apply .tw-border-white;
}
</style>
