<template>
  <div
    class="tw-w-full tw-pt-4 tw-pb-4 tw-z-50 tw-text-white tw-uppercase tw-font-semibold tw-text-sm tw-tracking-wide new-release-banner"
    style="background-color: #02a4ec;"
  >
    <div class="tw-flex tw-justify-center tw-items-center">
      <svg-icon name="rocket-f" class="tw-mr-2 tw-w-4 tw-h-4 tw-white" />
      <span>
        New update available.
        <a class="tw-underline tw-cursor-pointer" @click="reload">
          Refresh
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewVersionReleased',

  methods: {
    reload() {
      window.location.reload()
    },
  },
}
</script>

<style lang="scss">
@supports not (-ms-high-contrast: none) {
  .new-release-banner {
    @apply .tw-fixed;
    @apply .tw-bottom-0;
  }
}
</style>
