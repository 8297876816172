import moment from 'moment'

export default class OvertimeBreakdownUnit {
  constructor(date, amount) {
    this.date = moment.utc(date)
    this.amount = amount
  }

  toJson() {
    return {
      unit: this.unit,
      date: this.date.format('YYYY-MM-DD'),
      amount: this.amount,
    }
  }

  clone() {
    return new this.constructor(this.date, this.amount)
  }

  get unit() {
    throw new Error('Unit type not provided')
  }
}
