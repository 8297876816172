import Vue from 'vue'
import ScrollHint from 'scroll-hint'
import { ObserveVisibility } from 'vue-observe-visibility'

Vue.directive('scroll-hint', function(el) {
  el.classList.add('scroll-hint')
  new ScrollHint('.scroll-hint', {
    remainingTime: 3000,
  })
})

Vue.directive('focus', {
  inserted: function(el) {
    el.focus()
  },
})

Vue.directive('support-touch', {
  inserted: function(el) {
    if (navigator.maxTouchPoints > 0) {
      el.classList.remove('hover:tw-bg-blue-400')
    }
  },
})

Vue.directive('observe-visibility', ObserveVisibility)
