<template>
  <BaseAlert theme="danger" class="tw-mt-2">
    {{ attachmentError }}
    <template v-slot:right-content>
      <button
        class="tw-flex items-center tw-justify-center"
        type="button"
        @click="$emit('remove-attachment-error')"
      >
        <SvgIcon class="tw-w-5 tw-h-5 tw-text-gray-440" name="close" />
      </button>
    </template>
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
export default {
  name: 'AttachmentErrorMessage',

  components: {
    BaseAlert,
  },

  props: {
    attachmentError: {
      type: String,
      required: true,
    },
  },
}
</script>
