import OvertimeBreakdownUnit from '@/models/overtime/OvertimeBreakdownUnit'

export default class Days extends OvertimeBreakdownUnit {
  constructor(date, amount) {
    super(date, amount)
  }

  get unit() {
    return 'days'
  }
}
