<template>
  <div
    class="tw-flex tw-space-x-2 tw-p-4 tw-shadow tw-rounded-lg tw-border-l-4"
    :class="activeThemeClasses.wrapper"
  >
    <svg-icon v-if="icon" :name="icon" class="tw-mt-1 tw-w-6 tw-h-6" />

    <div class="tw-flex-1">
      <span
        v-if="$slots.title"
        class="tw-font-semibold tw-inline-block tw-mb-1"
      >
        <slot name="title"></slot>
      </span>

      <slot></slot>
    </div>

    <div v-if="closable">
      <button
        class="tw-w-5 tw-h-5 tw-inline-flex tw-items-center tw-justify-center tw-p-1 tw-rounded-full"
        :class="activeThemeClasses.closeButton"
        @click="close"
      >
        <svg-icon name="close" class="tw-w-full tw-h-full tw-text-inherit" />
      </button>
    </div>
  </div>
</template>

<script>
const THEMES = {
  orange: {
    wrapper: ['tw-bg-orange-200', 'tw-border-orange-400', 'tw-text-orange-500'],
    closeButton: ['hover:tw-text-brown-900'],
  },
}

export default {
  name: 'Banner',

  props: {
    icon: {
      type: String,
      default: null,
    },

    theme: {
      type: String,
      default: 'orange',
    },

    closable: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    activeThemeClasses() {
      return THEMES[this.theme]
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
