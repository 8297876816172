import LeaveEvent from '@/components/requests/leave/events/LeaveEvent'

export default class AttachmentDeletedEvent extends LeaveEvent {
  asText(viewer) {
    return `
      **${this.getActorName(viewer)}**
      ${this._getHaveOrHas(viewer)} deleted
      attachment
    `
  }

  isDeleted() {
    return !!this.event.deleted_at
  }

  getResource() {
    return this.event.resource
  }
}
