export default class WarningCollection {
  constructor(warnings) {
    this.warnings = warnings
  }

  whereUnavailableAllowanceYears() {
    return this.warnings.filter(warning => {
      return (
        warning.code === 'unavailable_company_allowance_years' ||
        warning.code === 'unavailable_aligned_allowance_years'
      )
    })
  }

  get length() {
    return this.warnings.length
  }
}
