<template>
  <div class="tw-flex tw-flex-col tw-flex-1">
    <PanelHeader @close-panel="$emit('closePanel')">
      <PortalTarget :name="panelHeaderPortalTarget"></PortalTarget>
    </PanelHeader>

    <TabsWrapper
      class="tw-px-8 tw-pt-5 tw-overflow-y-auto"
      @changed="setActiveTab"
    >
      <BaseTab id="request-leave" prefix-icon="umbrella" name="Request Leave">
        <LeaveForm
          v-if="employee"
          :employee="employee"
          :period="period"
          :employments="selectableEmployments"
          @leave-requested="emitLeaveRequested"
          @hide="$emit('closePanel')"
        />
      </BaseTab>

      <BaseTab
        v-if="isOvertimeEnabled && canRequestOvertimes"
        id="request-overtime"
        prefix-icon="clock-outline"
        name="Request Overtime"
      >
        <OvertimeForm
          v-if="employee"
          :employee="employee"
          :period="period"
          :employments="selectableEmployments"
          :is-overtime-tab-active="isOvertimeTabActive"
          @overtime-requested="emitOvertimeRequested"
          @hide="$emit('closePanel')"
        />
      </BaseTab>
    </TabsWrapper>

    <PanelFooter class="tw-mt-4">
      <PortalTarget :name="panelFooterPortalTarget"></PortalTarget>
    </PanelFooter>
  </div>
</template>

<script>
import EventBus from '@/plugins/event-bus'
import BaseTab from '@/components/BaseTab'
import PanelHeader from '@/components/PanelHeader'
import PanelFooter from '@/components/PanelFooter'
import TabsWrapper from '@/components/TabsWrapper'
import OvertimeForm from '@/components/requests/overtime/OvertimeForm'
import LeaveForm from '@/components/requests/leave/LeaveForm'
import EmploymentsQuery from '@/graphql/queries/employments'
import Employments from '@/graphql/Employments'

export default {
  name: 'RequestPanel',

  components: {
    BaseTab,
    PanelHeader,
    PanelFooter,
    TabsWrapper,
    OvertimeForm,
    LeaveForm,
  },

  props: {
    employee: {
      type: Object,
      required: true,
    },

    period: {
      type: Array,
      required: true,
    },

    employments: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeTab: null,
      selectableEmployments: [...this.employments],
    }
  },

  computed: {
    isOvertimeTabActive() {
      return this.activeTab?.hash === '#request-overtime'
    },

    panelHeaderPortalTarget() {
      if (!this.activeTab || this.activeTab.hash === '#request-leave') {
        return 'leave-panel-header-content'
      }

      return 'overtime-panel-header-content'
    },

    panelFooterPortalTarget() {
      if (!this.activeTab || this.activeTab.hash === '#request-leave') {
        return 'leave-panel-footer-content'
      }

      return 'overtime-panel-footer-content'
    },

    isOvertimeEnabled() {
      if (!this.activeCompany.calendars.length) {
        return false
      }

      return !!this.activeCompany.calendars[0].time_off_in_lieu
    },

    canRequestOvertimes() {
      if (
        this.activeEmployment.is_admin ||
        (this.activeEmployment.is_approver &&
          this.activeEmployment.isApproverOf(this.employee.id))
      ) {
        return true
      }

      return this.activeCompany.allow_employees_to_request_overtime
    },
  },

  created() {
    this.fetchEmployments()
  },

  methods: {
    setActiveTab(selectedTab) {
      this.activeTab = selectedTab.tab
    },

    emitLeaveRequested() {
      EventBus.$emit('leave-requested')
    },

    emitOvertimeRequested() {
      EventBus.$emit('overtime-requested')
    },

    async fetchEmployments() {
      try {
        await this.$apollo.queries.employments.refetch()
      } catch (error) {
        this.validateGraphQLFromResponse(error, true)
      }
    },
  },

  apollo: {
    employments: {
      debounce: 50,
      query: EmploymentsQuery,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          company: this.activeCompany.id,
          employmentsQuery: {
            offset: 0,
            limit: 10000,
            employmentIds: [],
            departmentIds: [],
            orderBy: null,
          },
        }
      },
      result(response) {
        this.selectableEmployments = Employments.all(response.data.employments)
      },
      error() {},
      skip() {
        return this.selectableEmployments.length > 0
      },
    },
  },
}
</script>
