<template>
  <div class="tw-px-8 tw-py-4 tw-border-t tw-border-gray-350">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PanelFooter',
}
</script>
