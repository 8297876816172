export default {
  methods: {
    toFixed(num, dec, strict = false) {
      const value = num.toFixed(dec)

      if (!strict || !this.hasDecimalValue(value)) {
        return Number(value.replace(/\.0+$/, ''))
      }

      return value
    },

    toNearestTenth(num) {
      const value = Math.ceil((num + 1) / 10) * 10

      return Number(value)
    },

    hasDecimalValue(number) {
      let value = number.split('.')

      return /[1-9]/.test(value[1])
    },

    toCleanFloat(value, digits = 0) {
      return Number.parseFloat(value.toFixed(digits))
    },
  },
}
