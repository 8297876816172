import http from '@/plugins/http'
import moment from 'moment/moment'

export default class Feed {
  static async getPublishedDate() {
    const feed = await http.get('/feeds')

    const publishedDate = feed.data['feed_published_at']

    if (!publishedDate) return null

    return moment.utc(feed.data['feed_published_at'])
  }

  static async read() {
    return http.put('me/feeds/read')
  }
}
