import LeaveEvent from '@/components/requests/leave/events/LeaveEvent'

export default class DeclinedEvent extends LeaveEvent {
  constructor(event) {
    super(event)
  }

  asNotice(authUser) {
    return `
        Leave request was declined by
        ${this.getActorName(authUser)}
        on
        ${this._createdAtText(authUser)}.
      `
  }

  asText(viewer) {
    return `
      **${this.getActorName(viewer)}**
      declined the request
    `
  }
}
