export default class Employments {
  static all(employments) {
    return this.mapEmployments(employments)
  }

  static mapEmployments(employments) {
    return employments.employments.map(employment => {
      let department = this.findDepartment(employment, employments)
      let workingSchedule = this.findWorkingSchedule(employment, employments)

      return {
        ...employment,
        department: {
          id: department?.id ?? null,
          name: department?.name ?? null,
        },
        workingSchedule: workingSchedule,
      }
    })
  }

  static findDepartment(employment, employments) {
    return (
      employments.departments.find(department =>
        department.ownerIds.includes(employment.id)
      ) ?? null
    )
  }

  static findWorkingSchedule(employment, employments) {
    return employments.workingSchedules.find(workingSchedule =>
      workingSchedule.ownerIds.includes(employment.id)
    )
  }
}
