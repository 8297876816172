import { uniqueId } from 'lodash-es'
import Info from '@/models/leave/drafting/breakdowns/Info'
import Error from '@/models/leave/drafting/breakdowns/Error'
import Warning from '@/models/leave/drafting/breakdowns/Warning'
import Duration from '@/models/leave/drafting/breakdowns/Duration'

export default class Breakdown {
  constructor(rawBreakdown) {
    this.rawBreakdown = rawBreakdown

    this.key = this.rawBreakdown.key ?? uniqueId()
    this.type = this.rawBreakdown.type
    this.duration = this.rawBreakdown.duration.name

    this.setAvailableDurations()
    this.setMessages(this.rawBreakdown.messages)
  }

  setMessages(messages) {
    this._setErrors(messages.errors)
    this._setWarnings(messages.warnings)
    this._setInformation(messages.info)
  }

  get date() {
    return this.activeDuration.date
  }

  isSameDate(date) {
    return this.date.isSame(date, 'date')
  }

  get isWorkingDay() {
    return this.type === 'working_day'
  }

  get isNonWorkingDay() {
    return this.type === 'non_working_day'
  }

  get isHoliday() {
    return this.type === 'holiday'
  }

  get holidayInformation() {
    return this.information.find(info => info.isHoliday)
  }

  get activeDuration() {
    return this.findDuration(this.duration)
  }

  set activeDuration(duration) {
    this.duration = duration
  }

  get messages() {
    return [...this.errors, ...this.warnings, ...this.information]
  }

  findDuration(durationName) {
    return (
      this.availableDurations.find(
        duration => duration.name === durationName
      ) ?? this.activeDuration
    )
  }

  clone() {
    return new this.constructor({
      ...JSON.parse(JSON.stringify(this.rawBreakdown)),
      key: this.key,
    })
  }

  updateCustomDuration(updatedTime) {
    const duration = this.findDuration('custom')
    duration.update(updatedTime)

    this.activeDuration = 'custom'
  }

  getBreakdownToRequest() {
    return {
      type: this.type,
      ...this.activeDuration.toJson(),
    }
  }

  setActiveDuration() {
    this.activeDuration =
      this.activeDuration.name ?? this.availableDurations[0].name
  }

  setAvailableDurations() {
    this.availableDurations = this.rawBreakdown.available_durations.map(
      duration => new Duration(duration)
    )
  }

  _setErrors(errors) {
    this.errors = errors.map(error => new Error(error))
  }

  _setWarnings(warnings) {
    this.warnings = warnings.map(warning => new Warning(warning))
  }

  _setInformation(info) {
    this.information = info.map(info => new Info(info))
  }
}
