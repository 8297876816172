<template>
  <div ref="flatpickr">
    <slot :is-mobile="isMobile" :picker="picker"></slot>
  </div>
</template>

<script>
import flatpickr from 'flatpickr'
import 'flatpickr/dist/themes/material_blue.css'

export default {
  name: 'DatePicker',

  props: {
    value: {
      type: [String, Date, Array],
      default: null,
    },

    configurations: {
      type: Object,
      default: () => {},
    },

    firstDayOfWeek: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      picker: null,
    }
  },

  computed: {
    defaultConfigurations() {
      let that = this

      return {
        onChange: dates => that.$emit('input', dates),
        onOpen: dates => that.$emit('open', { dates, picker: this.picker }),
        onClose: dates => that.$emit('close', dates),
        locale: {
          firstDayOfWeek: this.firstDayOfWeek,
        },
        disableMobile: true,
      }
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    },
  },

  watch: {
    value(value) {
      this.picker.setDate(value)
    },

    configurations: {
      deep: true,
      handler(configurations) {
        if (!this.picker) {
          return
        }

        for (let key of Object.keys(configurations)) {
          this.picker.set(key, configurations[key])
        }
      },
    },
  },

  mounted() {
    this.picker = flatpickr(this.$refs.flatpickr, {
      ...this.defaultConfigurations,
      ...this.configurations,
    })

    this.picker.setDate(this.value)
  },

  beforeDestroy() {
    this.picker.destroy()
  },
}
</script>

<style lang="scss">
.flatpickr-calendar {
  @apply font-sans;
  @apply .text-sm;
  @apply mt-1;

  &:before {
    @apply .border-none;
  }
}

.flatpickr-months,
.flatpickr-rContainer,
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  @apply .text-white;
}

.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  @apply .bg-blue-500;
  @apply .cursor-pointer;
}

.flatpickr-calendar:not(.rangeMode) .flatpickr-months .flatpickr-month {
  border-radius: 5px 5px 0 0 !important;
}

.flatpickr-calendar.rangeMode .flatpickr-months .flatpickr-month:nth-child(2) {
  border-radius: 5px 0 0 0 !important;
}

.flatpickr-calendar.rangeMode
  .flatpickr-months
  .flatpickr-month:nth-last-child(2) {
  border-radius: 0 5px 0 0 !important;
}

.flatpickr-calendar.rangeMode
  .flatpickr-months
  .flatpickr-month:not(:nth-child(2)):not(:nth-last-child(2)) {
  border-radius: 0;
}

span.flatpickr-weekday {
  @apply .text-base;
  @apply .bg-blue-500;
  @apply .text-white;
}

.flatpickr-weekdaycontainer,
.flatpickr-months .flatpickr-month,
.flatpickr-rContainer .flatpickr-weekdays,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  @apply .bg-blue-500;
}

.flatpickr-day {
  @apply .text-gray-800;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay {
  @apply .text-gray-500;
}

.flatpickr-day,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay {
  &:hover {
    @apply .border-transparent;
    background-color: #e6e6e6;
  }
}

.flatpickr-day.today:not(.selected) {
  border-color: #e6e6e6;

  &:hover {
    @apply .border-transparent;
    background-color: #e6e6e6;
    @apply .text-gray-800;
  }
}

.flatpickr-day.selected {
  @apply .bg-blue-500;
  @apply .border-transparent;

  &:hover {
    @apply .bg-blue-500;
    @apply .border-transparent;
    @apply .text-white;
  }
}

.flatpickr-day.flatpickr-disabled {
  @apply .line-through;

  &:hover {
    @apply .text-gray-500;
    @apply .bg-transparent;
  }
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  @apply .fill-current;
  @apply .text-white;
}

.numInputWrapper {
  &:hover {
    @apply .bg-transparent;
  }
}

.flatpickr-current-month .numInputWrapper {
  width: 6.5ch;
}

.flatpickr-wrapper {
  @apply .w-full;
}
</style>
