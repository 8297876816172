<template>
  <div class="tw-text-lg tw-font-semibold tw-text-gray-880">
    Total : <OvertimeBreakdownDetailsText :breakdowns="breakdowns" />
  </div>
</template>

<script>
import OvertimeBreakdownDetailsText from '@/components/requests/overtime/OvertimeBreakdownDetailsText'

export default {
  name: 'OvertimeBreakdownDetails',
  components: { OvertimeBreakdownDetailsText },
  props: {
    breakdowns: {
      type: Array,
      required: true,
    },
  },
}
</script>
