<template>
  <section
    v-show="isActive"
    :id="computedId"
    :aria-hidden="!isActive"
    class="tw-flex-1 tw-overflow-hidden"
    :class="{ 'panel-footer-lg': isLargeFooterVisible }"
    role="tabpanel"
    :data-cy="cypressAttribute"
  >
    <portal :to="name">
      <slot name="header">
        {{ name }}
      </slot>
    </portal>

    <slot :is-active="isActive" />
  </section>
</template>

<script>
export default {
  name: 'BaseTab',

  props: {
    id: {
      type: String,
      default: null,
    },

    name: {
      type: String,
      required: true,
    },

    prefixIcon: {
      type: String,
      default: '',
    },

    suffixIcon: {
      type: String,
      default: '',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isLargeFooterVisible: {
      type: Boolean,
      default: false,
    },

    cypressAttribute: {
      type: String,
      default: 'tab',
    },
  },

  data: () => ({
    isActive: false,
    isVisible: true,
  }),

  computed: {
    computedId() {
      return this.id ?? this.name.toLowerCase().replace(/ /g, '-')
    },

    hash() {
      if (this.isDisabled) {
        return '#'
      }

      return '#' + this.computedId
    },
  },
}
</script>
