<template>
  <BaseAlert title="Approved" class="tw-block">
    {{ approvedText }}
    <div v-if="canRetractApproval">
      <button
        class="tw-font-semibold tw-underline"
        data-cy="btn-undo-approval"
        @click="$emit('retract')"
      >
        Undo Approval
      </button>
    </div>
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
import LeavePermission from '@/components/requests/leave/LeavePermission'
import ApprovedEvent from '@/components/requests/leave/events/ApprovedEvent'

export default {
  name: 'ApprovedAlert',

  components: { BaseAlert },

  props: {
    leave: {
      type: Object,
      required: true,
    },

    event: {
      type: ApprovedEvent,
      required: true,
    },
  },

  computed: {
    approvedText() {
      return this.event.asNotice(this.activeEmployment)
    },

    canRetractApproval() {
      return LeavePermission.canRetract(this.leave, this.activeEmployment)
    },
  },
}
</script>
