<template>
  <div
    class="tw-flex tw-items-center tw-justify-between tw-cursor-pointer hover:tw-bg-blue-200"
    style="min-width: 272px;"
    @mouseover="controlNotifications = true"
    @mouseleave="controlNotifications = false"
  >
    <component
      :is="component"
      v-if="component"
      :notification="notification"
      :active-notification="activeNotification"
      @click="$emit('click', $event)"
    />
    <div
      v-if="controlNotifications"
      class="notification-dot tw-mr-2 tw-px-2 tw-text-2xl tw-text-blue-500"
      @click="$emit('toggle-read', notification)"
    >
      <span v-if="notification.isRead()">&#8226;</span>
      <span v-else class="tw-font-semibold" style="font-size: 15px"
        >&#4125;</span
      >
    </div>
  </div>
</template>

<script>
import Notification from '@/components/notifications/Notification'

export default {
  name: 'DynamicNotification',

  props: {
    notification: {
      type: Notification,
      required: true,
    },

    activeNotification: {
      type: Notification,
      default: () => null,
    },
  },

  data() {
    return {
      component: null,
      controlNotifications: false,
    }
  },

  computed: {
    type() {
      return this.notification.getComponentName()
    },
  },

  mounted() {
    this.component = () => import(`@/components/notifications/${this.type}`)
  },
}
</script>
