export default class Info {
  constructor(info) {
    this.info = info
  }

  get isAllowanceUnlimited() {
    return this.info.code === 'allowance_unlimited'
  }

  get isAllowanceNotSetupForLeaveType() {
    return this.info.code === 'allowance_not_setup_for_leave_type'
  }
}
