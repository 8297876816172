import moment from 'moment-timezone'

export default class PendingApproval {
  constructor(approval) {
    Object.keys(approval).forEach(property => {
      this[property] = approval[property]
    })
  }

  getKey() {
    return this.id
  }

  getOwner() {
    return this.owner
  }

  getOwnerFullName() {
    return this.owner.full_name
  }

  getType() {
    return this.type
  }

  getTypeName() {
    return this.type.name
  }

  getDuration() {
    return this.duration
  }

  getTimezone() {
    return this.timezone
  }

  isLeave() {
    return this.getRequestType() === 'leave'
  }

  getRequestType() {
    return this.request_type
  }

  get start() {
    return this._toMoment(this.from)
  }

  _toMoment(time) {
    return moment.utc(time).tz(this.timezone)
  }
}
