<template>
  <div>
    <div ref="target">
      <SpinnerButton
        type="button"
        :disabled="disabled"
        v-bind="$attrs"
        @click="$emit('click')"
      >
        {{ buttonText }}
      </SpinnerButton>
    </div>

    <div ref="content">
      <slot />
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import SpinnerButton from '@/components/SpinnerButton'

export default {
  name: 'SpinnerButtonWithTooltip',

  components: {
    SpinnerButton,
  },

  props: {
    disabled: {
      type: Boolean,
      default: true,
    },

    buttonText: {
      type: String,
      default: '',
    },
  },

  watch: {
    disabled() {
      if (this.disabled) {
        this.tippy.enable()
      } else {
        this.tippy.disable()
      }
    },
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.target, {
      html: this.$refs.content.firstChild,
      placement: 'right',
      distance: 1,
      interactiveBorder: 15,
      interactive: true,
      theme: 'dark',
      arrow: true,
      delay: [400, 0],
      width: '10px',
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },
}
</script>
