import LimitedAllowance from '@/models/employment/LimitedAllowance'
import UnlimitedAllowance from '@/models/employment/UnlimitedAllowance'
import LeaveAllowanceSummary from '@/models/leave/LeaveAllowanceSummary'

export default class LeaveAllowances {
  constructor(leave, employmentAllowances) {
    this.employmentAllowances = employmentAllowances
    this.leave = leave
  }

  getAllowances() {
    return this._makeAllowances()
  }

  getSummary() {
    return new LeaveAllowanceSummary(this._makeAllowances())
  }

  getAccruedSummary() {
    return new LeaveAllowanceSummary(this._makeAllowances(true))
  }

  _makeAllowances(isAccrued = false) {
    return this.leave.map(leave => {
      let employmentAllowance = this.employmentAllowances
        .all()
        .find(allowance => allowance.employment().id === leave.getOwnerKey())

      return this._makeAllowance(leave, employmentAllowance, isAccrued)
    })
  }

  _makeAllowance(leave, allowance = null, isAccrued = false) {
    if (!allowance) {
      return new UnlimitedAllowance(leave)
    }

    const amount = isAccrued
      ? allowance.accruedRemainingToBook()
      : allowance.remainingToBook()

    return new LimitedAllowance(leave, amount)
  }
}
