<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-flex tw-flex-col tw-flex-1">
      <ScrollableContent>
        <PortalTarget name="attachments"></PortalTarget>
      </ScrollableContent>
    </div>

    <div class="tw-my-4 tw-border-t">
      <AttachmentInput
        class="tw-mt-4 tw-flex tw-flex-col tw-justify-end"
        :current-attachments="leave.getAttachments()"
        :direct-upload="true"
        :unuploaded-attachments="unuploadedAttachments"
        :uploaded-attachments="uploadedAttachments"
        :can-add-attachments="leave.canAddAttachments(activeEmployment)"
        attachments-portal-target="attachments"
        @save-attachment="saveAttachment"
        @attachment-removed="$emit('attachment-removed')"
      />
    </div>
  </div>
</template>

<script>
import AttachmentInput from '@/components/AttachmentInput'
import { Leaves } from '@/api'
import ValidatesForm from '@/mixins/ValidatesForm'
import Leave from '@/models/leave/Leave'
import ScrollableContent from '@/components/ScrollableContent'

export default {
  name: 'LeaveAttachmentsTab',

  components: {
    ScrollableContent,
    AttachmentInput,
  },

  mixins: [ValidatesForm],

  props: {
    leave: {
      type: Leave,
      required: true,
    },
  },

  data() {
    return {
      unuploadedAttachments: [],
      uploadedAttachments: [],
    }
  },

  methods: {
    async saveAttachment(attachment) {
      try {
        await Leaves.saveAttachment(this.leave.getKey(), {
          company_id: this.activeCompany.id,
          attachment_id: attachment.id,
          file_name: attachment.name,
        })

        this.uploadedAttachments.push(attachment)
        this.$emit('attachment-added')
      } catch ({ response }) {
        this.unuploadedAttachments.push(attachment)

        this.validateFromResponse(response)
      }
    },
  },
}
</script>
