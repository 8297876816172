import Vue from 'vue'
import AuthUser from './AuthUser'
import HandleAuth from './HandleAuth'
import HandleConfirmations from './HandleConfirmations'
import HandleNotifications from './HandleNotifications'

Vue.mixin(AuthUser)
Vue.mixin(HandleAuth)
Vue.mixin(HandleConfirmations)
Vue.mixin(HandleNotifications)
