<template>
  <div>
    <div class="tw-flex">
      <div
        class="tw-px-3 tw-py-2 tw-flex-grow tw-text-gray-880 tw-text-thin"
        style="width: 44%;"
      >
        <div class="tw-flex tw-items-center">
          <UserAvatar :user="overtimeAllowance.employment" class="tw-mr-2" />

          <span>{{ overtimeAllowance.employment.full_name }}</span>
        </div>
      </div>

      <div
        class="tw-px-3 tw-py-2 tw-flex tw-items-center tw-flex-grow tw-text-gray-880 tw-text-thin"
        style="width: 19%;"
      >
        {{ currentAllowance | formatAmount | toFraction }}
        {{ allowanceUnit | pluralize(currentAllowance) }}
      </div>

      <div
        class="tw-px-3 tw-py-2 tw-flex tw-items-center tw-flex-grow tw-text-gray-880 tw-text-thin"
        style="width: 19%;"
      >
        {{ newAllowance | formatAmount | toFraction }}
        {{ allowanceUnit | pluralize(newAllowance) }}
      </div>

      <div
        class="tw-px-3 tw-py-2 tw-flex tw-items-center tw-flex-grow tw-text-gray-880 tw-text-thin"
        style="width: 22%;"
      >
        <div class="tw-flex items-center">
          <SvgIcon
            v-if="!overtimeAllowance.hasUnlimitedAllowances"
            name="arrow-right"
            class="tw-mr-1 tw-w-5 tw-h-5 tw-p-1 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-green-100 tw-text-green-550 tw-transform tw--rotate-90"
          />

          <span>
            {{ changedAllowance | formatAmount('∅') | toFraction }}
            {{ allowanceUnit | pluralize(changedAllowance) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormatNumbers from '@/mixins/FormatNumbers'
import { capitalize } from 'lodash-es'

export default {
  name: 'EmploymentOvertimeAllowancesTableRow',

  filters: {
    formatAmount(amount, symbol = '∞') {
      if (amount === Infinity) {
        return symbol
      }

      return amount
    },
  },

  mixins: [FormatNumbers],

  props: {
    overtimeAllowance: {
      type: Object,
      required: true,
    },
  },

  computed: {
    currentAllowance() {
      return this.toFixed(this.overtimeAllowance.currentAllowance, 2)
    },

    changedAllowance() {
      return this.toFixed(this.overtimeAllowance.changedAllowance, 2)
    },

    newAllowance() {
      return this.toFixed(this.overtimeAllowance.newAllowance, 2)
    },

    allowanceUnit() {
      return capitalize(this.overtimeAllowance.allowanceUnit)
    },
  },
}
</script>
