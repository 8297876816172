import RequestAllowance from '@/models/employment/RequestAllowance'

export default class UnlimitedAllowance extends RequestAllowance {
  get currentAllowance() {
    return Infinity
  }

  get newAllowance() {
    return Infinity
  }

  get changedAllowance() {
    return Infinity
  }

  get hasUnlimitedAllowances() {
    return true
  }
}
