<template>
  <div
    class="employment-picker-option tw-flex tw-justify-between tw-items-center tw-content-center"
    style="margin: -0.75rem -1rem; padding: 0.75rem 1rem;"
    data-cy="emp-picker-option"
  >
    <div
      class="tw-text-base tw-font-normal option-label-text"
      :class="props.option.$isLabel ? 'tw-text-blue-600' : 'tw-text-gray-800'"
    >
      <span
        v-if="props.option.$isLabel"
        class="tw-font-semibold tw-inline-block md:tw-truncate tw-w-full md:tw-w-88 "
      >
        {{ props.option.$groupLabel }}
      </span>
      <span v-else>{{ props.option.full_name }}</span>
    </div>
    <div class="tw-flex tw-items-center tw-relative">
      <div
        v-if="props.option.$isLabel"
        class="tw-hidden md:tw-inline"
        data-cy="select-all"
      >
        <svg-icon
          v-if="isAllSelectedInGroup"
          name="check-circle-solid"
          class="tw-w-4 tw-h-4 tw-text-blue-500 option-check-icon"
        />
        <span v-else class="tw-w-5 tw-h-5 tw-text-gray-500 option-label-text">
          Select all
        </span>
      </div>
      <div v-else>
        <svg-icon
          name="check-circle-solid"
          class="tw-w-4 tw-h-4 employee-check-icon"
          :class="
            props.option.is_selected
              ? 'tw-text-green-500 employee-check-icon-selected'
              : 'tw-text-gray-500 employee-check-icon-not-selected'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { size, filter, flatMap } from 'lodash-es'

export default {
  name: 'MultipleEmploymentPickerOption',

  props: {
    props: {
      type: Object,
      required: true,
    },

    selectedEmployments: {
      type: Array,
      required: true,
    },

    groupedEmployments: {
      type: Array,
      required: true,
    },
  },

  computed: {
    groupName() {
      return this.props.option.$groupLabel
    },

    isAllSelectedInGroup() {
      if (this.groupName === 'No Department') {
        const actualCount = size(
          filter(
            flatMap(this.groupedEmployments, 'employments'),
            employment => {
              return (
                employment.department_id === null ||
                employment.department?.id === null
              )
            }
          )
        )

        const selectedCount = size(
          filter(this.selectedEmployments, selectedEmployment => {
            return (
              selectedEmployment.department_id === null ||
              selectedEmployment?.department?.id === null
            )
          })
        )

        return actualCount === selectedCount
      }

      const actualCount = size(
        filter(flatMap(this.groupedEmployments, 'employments'), employment => {
          return employment.department
            ? employment.department.name === this.groupName
            : false
        })
      )

      const selectedCount = size(
        filter(this.selectedEmployments, employment => {
          return employment.department
            ? employment.department.name === this.groupName
            : false
        })
      )

      return actualCount === selectedCount
    },
  },
}
</script>

<style scoped lang="scss">
.employment-picker-option {
  @apply .tw-bg-white;

  &:hover {
    @apply .tw-bg-blue-500;

    .option-label-text,
    .option-check-icon,
    .employee-check-icon {
      @apply .tw-text-white;
    }

    .employee-check-icon-selected {
      @apply .tw-bg-green-500;
      border-radius: 50%;
    }

    .employee-check-icon-not-selected {
      @apply .tw-bg-gray-500;
      border-radius: 50%;
    }
  }
}
</style>
