export default {
  methods: {
    confirm(
      text,
      title = 'Alert!',
      titleClasses = 'modal-header-icon error-icon-exclamation-triangle tw-text-red-500'
    ) {
      this.$modal.show('dialog', {
        title: `<span class="${titleClasses}">${title}</span>`,
        text: text,
        buttons: [
          {
            title: 'Yes',
            handler: () => this.$emit('model-confirmed'),
          },
          {
            title: 'No',
            handler: () => this.$emit('model-rejected'),
          },
        ],
      })

      return new Promise(resolve => {
        this.$on('model-confirmed', () => {
          this.$modal.hide('dialog')
          resolve(true)
        })
        this.$on('model-rejected', () => {
          this.$modal.hide('dialog')
          resolve(false)
        })
      })
    },
  },
}
