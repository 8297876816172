import Vue from 'vue'
import jQuery from 'jquery'
import store from './store'
import router from './router'
import App from './components/App.vue'
import { sync } from 'vuex-router-sync'
import Feature from './plugins/feature'
import apolloProvider from './plugins/graphql'
import { GrowthBookVuePlugin } from './plugins/growthbook'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueMeta from 'vue-meta'

window.jQuery = jQuery

import './mixins'
import './plugins'
import './filters'
import './directives'
import config from './config'

sync(store, router)

Vue.use(VueMeta)

Vue.component('feature', Feature)
Vue.component('SvgIcon', () => import('@/components/SvgIcon.vue'))
Vue.component('UserAvatar', () => import('@/components/UserAvatar'))

Vue.use(GrowthBookVuePlugin, {
  featuresEndpoint: process.env.VUE_APP_GROWTHBOOK_ENDPOINT_URL,
  enableDevMode: process.env.VUE_APP_GROWTHBOOK_ENABLE_DEVMODE,
})

Vue.use(VueReCaptcha, {
  siteKey: config.app.recaptchaSiteKey,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
  },
})

Vue.config.productionTip = false

const app = new Vue({
  store,
  router,
  ...App,
  apolloProvider,
}).$mount('#app')

if (window.Cypress) {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`
  window.__app__ = app
}
