<template>
  <span class="tw-flex tw-justify-center tw-items-center">
    <span>{{ header }}</span>
    <CountLabel v-if="count > 0" :count="count" />
  </span>
</template>

<script>
import CountLabel from '@/components/CountLabel'

export default {
  name: 'TabHeader',

  components: {
    CountLabel,
  },

  props: {
    header: {
      type: String,
      required: true,
    },

    count: {
      type: Number,
      default: 0,
    },
  },
}
</script>
