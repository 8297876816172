import Notification from '@/components/notifications/Notification'

export default class AllowanceNotification extends Notification {
  constructor(notification) {
    super(notification)
  }

  // eslint-disable-next-line no-unused-vars
  navigate(router, query) {
    router.push({ name: 'calendars' }, () => {})
  }

  getMessage() {
    return this.notification.data.message
  }

  getType() {
    return 'allowance'
  }
}
