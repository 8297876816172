import { camelCase, startCase } from 'lodash-es'

export default class Notification {
  constructor(notification) {
    this.notification = notification
  }

  viewerIsOwner(viewer) {
    return this.notification.data.owner.id === viewer.id
  }

  viewerIsCreator(viewer) {
    return this.getCreator().id === viewer.id
  }

  actorIsOwner() {
    return this.getActor().id === this.getOwner().id
  }

  creatorIsOwner() {
    return this.getCreator().id === this.getOwner().id
  }

  getCreator() {
    return this.notification.data.creator
  }

  getOwner() {
    return this.notification.data.owner
  }

  getActor() {
    return this.notification.data.actor
  }

  getCreatedAt() {
    return this.notification.created_at
  }

  getKey() {
    return this.notification.id
  }

  routeQuery() {
    return {
      company: this._getCompanyKey(),
      [this.getType()]: this._getResourceKey(),
    }
  }

  getComponentName() {
    return startCase(camelCase(this.notification.type)).replace(/ /g, '')
  }

  isRead() {
    return !this.isUnread()
  }

  isUnread() {
    return this.notification.read_at === null
  }

  getType() {
    throw new Error('Method not implemented.')
  }

  viewerIsRequestOwner(viewer, type) {
    return this.getRequestOwner(type).id === viewer.id
  }

  requestOwnerIsCommentator(type) {
    return this.getRequestOwner(type).id === this.getCommenter().id
  }

  getCommenter() {
    throw new Error('Method not implemented')
  }

  getRequestOwner(type) {
    return this.notification.data[type + '_owner']
  }

  _getCompanyKey() {
    return this.notification.data.company_id
  }

  _getResourceKey() {
    throw new Error('Method not implemented.')
  }
}
