import pluralize from 'pluralize'

export default class RequestDuration {
  constructor(duration) {
    this.duration = duration
  }

  toString(prefix = '') {
    let durationText = []

    if (this.duration.days) {
      durationText.push(
        `${this.duration.days} ${pluralize('days', this.duration.days)}`
      )
    }

    if (this.duration.hours) {
      durationText.push(
        `${this.duration.hours} ${pluralize('hours', this.duration.hours)}`
      )
    }

    if (this.duration.minutes) {
      durationText.push(
        `${this.duration.minutes} ${pluralize(
          'minutes',
          this.duration.minutes
        )}`
      )
    }

    return durationText.length
      ? `${prefix}${durationText.join(', ')}`
      : 'No deduction'
  }
}
