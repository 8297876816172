import moment from 'moment-timezone'
import {
  orderBy,
  head,
  filter,
  groupBy,
  reduce,
  sortBy,
  first,
  last,
  uniq,
  map,
} from 'lodash-es'

export default {
  methods: {
    isNonWorkingDay(date, employment) {
      return !this.workingScheduleForDay(date, employment).length
    },

    workingSchedule(employment) {
      return employment.available_working_schedule
    },

    commonBreakdownOf(workingSchedule, session) {
      const scheduleBreakdowns = sortBy(
        filter(
          workingSchedule.schedule_breakdowns,
          breakdown => breakdown.session === session
        ),
        'iso_week_day',
        'desc'
      )

      if (!scheduleBreakdowns.length) return null

      const groupedBreakdowns = groupBy(
        scheduleBreakdowns,
        breakdown => breakdown.start_time + '-' + breakdown.end_time
      )

      return head(
        reduce(
          groupedBreakdowns,
          (largestGroup, group) => {
            if (largestGroup.length < group.length) {
              return group
            }

            return largestGroup
          },
          []
        )
      )
    },

    commonMorningBreakdownOf(workingSchedule) {
      return this.commonBreakdownOf(workingSchedule, 1)
    },

    commonEveningBreakdownOf(workingSchedule) {
      return this.commonBreakdownOf(workingSchedule, 2)
    },

    workingScheduleForDay(date, employment) {
      return orderBy(
        this.workingScheduleInTimezone(
          this.workingSchedule(employment),
          employment.timezone
        ).schedule_breakdowns.filter(breakdown => {
          return breakdown.iso_week_day === date.isoWeekday()
        }),
        breakdown => breakdown.start_time
      )
    },

    detailedWorkingPeriod(workingScheduleForDay) {
      if (!workingScheduleForDay.length) return null

      workingScheduleForDay = sortBy(workingScheduleForDay, 'session')

      const morning = workingScheduleForDay.find(breakdown => {
        return breakdown.session === 1
      })

      const evening = workingScheduleForDay.find(breakdown => {
        return breakdown.session === 2
      })

      const startTime = first(workingScheduleForDay).startTime
      const endTime = last(workingScheduleForDay).endTime

      const workingTime = endTime.diff(startTime, 'minutes')

      const interval =
        morning && evening
          ? {
              startTime: morning.endTime,
              endTime: evening.startTime,
              duration: evening.startTime.diff(morning.endTime, 'minutes'),
            }
          : null

      return {
        startTime,
        endTime,
        morning,
        evening,
        interval,
        workingTime,
      }
    },

    formattedBreakdown(date, breakdown) {
      return {
        session: breakdown.session,
        startTime: date.clone().set({
          Hours: breakdown.start_time.split(':')[0],
          minutes: breakdown.start_time.split(':')[1],
        }),
        endTime: date.clone().set({
          Hours: breakdown.end_time.split(':')[0],
          minutes: breakdown.end_time.split(':')[1],
        }),
      }
    },

    formattedWorkingScheduleForDay(date, employment) {
      return this.workingScheduleForDay(date, employment).map(breakdown => {
        return this.formattedBreakdown(date, breakdown)
      })
    },

    workingPeriodOfDay(date, employment) {
      const workingScheduleForDay = this.formattedWorkingScheduleForDay(
        date,
        employment
      )

      return this.detailedWorkingPeriod(workingScheduleForDay)
    },

    commonWorkingPeriodOfDay(date, employment) {
      const workingScheduleForDay = this.formattedWorkingScheduleForDay(
        date,
        employment
      )

      const morningSchedule = this.morningSchedule(workingScheduleForDay)

      if (!morningSchedule) {
        const commonMorningBreakdown = this.commonMorningBreakdownOf(
          this.workingSchedule(employment)
        )

        if (commonMorningBreakdown)
          workingScheduleForDay.push(
            this.formattedBreakdown(date, commonMorningBreakdown)
          )
      }

      const eveningSchedule = this.eveningSchedule(workingScheduleForDay)

      if (!eveningSchedule) {
        const commonEveningBreakdown = this.commonEveningBreakdownOf(
          this.workingSchedule(employment)
        )

        if (commonEveningBreakdown)
          workingScheduleForDay.push(
            this.formattedBreakdown(date, commonEveningBreakdown)
          )
      }

      if (!workingScheduleForDay.length) return null

      return this.detailedWorkingPeriod(workingScheduleForDay)
    },

    workingScheduleInTimezone(workingSchedule, timezone) {
      let newScheduleBreakdowns = []

      workingSchedule.schedule_breakdowns.forEach(breakdown => {
        const startTime = moment
          .tz(breakdown.start_time, 'HH:mm', timezone)
          .isoWeekday(breakdown.iso_week_day)

        const endTime = moment
          .tz(breakdown.end_time, 'HH:mm', timezone)
          .isoWeekday(breakdown.iso_week_day)

        const startTimeEmploymentTimeZone = this.toMoment(
          startTime.clone(),
          timezone
        )

        const endTimeEmploymentTimeZone = this.toMoment(
          endTime.clone(),
          timezone
        )

        if (
          startTime.isoWeekday() !== startTimeEmploymentTimeZone.isoWeekday()
        ) {
          newScheduleBreakdowns.push(
            {
              ...breakdown,
              iso_week_day: startTimeEmploymentTimeZone.day(),
              start_time: startTimeEmploymentTimeZone.format('HH:mm'),
              end_time: '24:00',
            },
            {
              ...breakdown,
              start_time: '00:00',
              end_time: endTimeEmploymentTimeZone.format('HH:mm'),
            }
          )
        } else if (
          endTime.isoWeekday() !== endTimeEmploymentTimeZone.isoWeekday()
        ) {
          newScheduleBreakdowns.push(
            {
              ...breakdown,
              start_time: startTimeEmploymentTimeZone.format('HH:mm'),
              end_time: '24:00',
            },
            {
              ...breakdown,
              iso_week_day: endTimeEmploymentTimeZone.day(),
              start_time: '00:00',
              end_time: endTimeEmploymentTimeZone.format('HH:mm'),
            }
          )
        } else {
          newScheduleBreakdowns.push({
            ...breakdown,
            start_time: startTimeEmploymentTimeZone.format('HH:mm'),
            end_time: endTimeEmploymentTimeZone.format('HH:mm'),
          })
        }
      })

      return {
        ...workingSchedule,
        schedule_breakdowns: newScheduleBreakdowns,
      }
    },

    workingDayIsFullDay(workingSchedule) {
      const days = uniq(
        map(workingSchedule.schedule_breakdowns, 'iso_week_day')
      )

      return days.length !== workingSchedule.schedule_breakdowns.length
    },

    getStartDateForBreakdown(date, employment) {
      let start = date.clone()

      const workingPeriodOfDay = this.availableWorkingPeriod(start, employment)

      if (start.format('HH:mm') === '00:00') {
        if (workingPeriodOfDay.interval || workingPeriodOfDay.morning) {
          start.set({
            hours: workingPeriodOfDay.startTime.hour(),
            minutes: workingPeriodOfDay.startTime.minute(),
            seconds: '00',
            milliseconds: '00',
          })
        }
      } else if (start.format('HH:mm') === '12:00') {
        if (workingPeriodOfDay.interval || workingPeriodOfDay.evening) {
          start.set({
            hours: workingPeriodOfDay.evening.startTime.hour(),
            minutes: workingPeriodOfDay.evening.startTime.minute(),
            seconds: '00',
            milliseconds: '00',
          })
        }
      }

      return start
    },

    getEndDateForBreakdown(date, employment) {
      let end = date.clone()

      if (end.format('HH:mm') === '00:00') {
        end.subtract(1, 'minutes')
      }

      const workingPeriodOfDay = this.availableWorkingPeriod(end, employment)

      if (end.format('HH:mm') === '23:59') {
        if (workingPeriodOfDay.interval || workingPeriodOfDay.evening) {
          end.set({
            hours: workingPeriodOfDay.endTime.hour(),
            minutes: workingPeriodOfDay.endTime.minute(),
            seconds: '00',
            milliseconds: '00',
          })
        }
      } else if (end.format('HH:mm') === '12:00') {
        if (workingPeriodOfDay.interval || workingPeriodOfDay.morning) {
          end.set({
            hours: workingPeriodOfDay.morning.endTime.hour(),
            minutes: workingPeriodOfDay.morning.endTime.minute(),
            seconds: '00',
            milliseconds: '00',
          })
        }
      }

      return end
    },

    availableWorkingPeriod(date, employment) {
      return this.isNonWorkingDay(date, employment)
        ? this.commonWorkingPeriodOfDay(date, employment)
        : this.workingPeriodOfDay(date, employment)
    },

    hasSingleScheduleForDay(date, employment) {
      return 1 === this.workingScheduleForDay(date, employment).length
    },

    hasEveningSchedule(date, employment) {
      return !!this.eveningSchedule(
        this.workingScheduleForDay(date, employment)
      )
    },

    hasMorningSchedule(date, employment) {
      return !!this.morningSchedule(
        this.workingScheduleForDay(date, employment)
      )
    },

    morningSchedule(workingSchedule) {
      return workingSchedule.find(breakdown => {
        return breakdown.session === 1
      })
    },

    eveningSchedule(workingSchedule) {
      return workingSchedule.find(breakdown => {
        return breakdown.session === 2
      })
    },
  },
}
