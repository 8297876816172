<template>
  <div
    class="tw-px-2 tw-py-1 tw-inline-flex tw-border tw-rounded-full tw-font-semibold tw-text-thin"
    :style="{ background: backgroundColour, color: textColour }"
    data-cy="leave-type-name"
  >
    {{ leaveTypeLabel }}
  </div>
</template>

<script>
import Leave from '@/models/leave/Leave'

const BACKGROUND_COLOURS_TO_TEXT_COLOUR_MAP = [
  {
    textColour: '#101828',
    backgroundColors: [
      '#BFA9EB',
      '#FF9F43',
      '#4DDBFB',
      '#1CD1A1',
      '#FCF600',
      '#F183DA',
      '#BCC1C7',
      '#FEFB99',
    ],
  },
  {
    textColour: '#FFFFFF',
    backgroundColors: [
      '#576574',
      '#5F27CD',
      '#F05451',
      '#35AAF0',
      '#00A3A4',
      '#9F7DE1',
      '#C0C0C0',
      '#000000',
    ],
  },
  {
    textColour: '#F5F5F5',
    backgroundColors: ['#1561BD'],
  },
  {
    textColour: '#530707',
    backgroundColors: ['#F8BABA'],
  },
  {
    textColour: '#643507',
    backgroundColors: ['#FFD9B4'],
  },
  {
    textColour: '#095B46',
    backgroundColors: ['#D3F5D6'],
  },
  {
    textColour: '#084243',
    backgroundColors: ['#99DADB'],
  },
  {
    textColour: '#083047',
    backgroundColors: ['#AEDDF9'],
  },
  {
    textColour: '#042751',
    backgroundColors: ['#A1C0E5'],
  },
  {
    textColour: '#290079',
    backgroundColors: ['#D9CBF3'],
  },
  {
    textColour: '#4F003E',
    backgroundColors: ['#FBA7E9'],
  },
  {
    textColour: '#003D49',
    backgroundColors: ['#B8F1FD'],
  },
]

export default {
  name: 'LeaveTypeLabel',

  props: {
    leave: {
      type: Leave,
      required: true,
    },
  },

  computed: {
    leaveTypeLabel() {
      if (this.leave.isPrivate()) {
        return `${this.leaveType.name} (private)`
      }

      return this.leaveType.name
    },

    leaveType() {
      return this.leave.type
    },

    textColour() {
      let colorGroup = BACKGROUND_COLOURS_TO_TEXT_COLOUR_MAP.find(colourGroup =>
        colourGroup.backgroundColors.includes(
          this.backgroundColour.toUpperCase()
        )
      )

      return colorGroup.textColour
    },

    backgroundColour() {
      return this.leaveType.colour
    },
  },
}
</script>
