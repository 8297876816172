import moment from 'moment-timezone'

export default class LeaveBreakdownCustomOptionGenerator {
  constructor(period, workingSchedule) {
    this.incrementBy = 15
    this.start = period.start
    this.end = period.end
    this.workingSchedule = workingSchedule
  }

  getStartOptions() {
    let options = this._makeOptions(
      this.start.clone().startOf('day'),
      this.end
    ).map(option => ({
      ...option,
      duration: moment.duration(this.end.diff(option.time)),
    }))

    return options.sort((a, b) => a.time.valueOf() - b.time.valueOf())
  }

  getEndOptions() {
    let options = this._makeOptions(
      this.start,
      this.start.clone().endOf('day')
    ).map(option => ({
      ...option,
      duration: moment.duration(option.time.diff(this.start)),
    }))

    return options.sort((a, b) => a.time.valueOf() - b.time.valueOf())
  }

  getSelectedEndTimeOption() {
    return this.getEndOptions().find(option =>
      option.time.isSame(this.end, 'seconds')
    )
  }

  _makeOptions(from, to) {
    let options = []
    let time = this._roundUpTimeToNext15Minutes(from.clone())
    let end = to.clone()

    if (time.isSame(this.start)) {
      time = time.add(this.incrementBy, 'minutes')
    }

    while (time.isBefore(end)) {
      options.push(this._makeOption(time))

      time = time.add(this.incrementBy, 'minutes')
    }

    let times = this._getTimesForMakeCustomOptions(from, end, options)
    if (times) {
      for (let t of times) {
        options.push(this._makeCustomOption(t))
      }
    }

    if (end.isSame(this.end.clone().endOf('day'))) {
      options.push(this._makeOption(end))
    }

    return options
  }

  _roundUpTimeToNext15Minutes(time) {
    const minutes = time.minutes()
    const minutesForNext15Minutes = Math.ceil(minutes / 15) * 15

    return time
      .clone()
      .minutes(minutesForNext15Minutes)
      .seconds(0)
  }

  _getTimesForMakeCustomOptions(from, end, options) {
    let times = []

    for (let time of this._getSessionStartsAndEndTimes()) {
      if (
        this._inBetween(time, from.clone(), end.clone()) &&
        !this._hasOption(time, options)
      ) {
        times.push(time)
      }
    }

    return times
  }

  _getSessionStartsAndEndTimes() {
    return this._getWorkingSchedule(
      this.workingSchedule,
      this.start.isoWeekday()
    ).flatMap(session => [session.start_time, session.end_time])
  }

  _getWorkingSchedule(workingSchedule, isoWeekDay) {
    let filteredWorkingSchedule = []

    workingSchedule.breakdowns.forEach(function(breakdown) {
      if (breakdown.iso_week_day === isoWeekDay) {
        filteredWorkingSchedule.push(breakdown)
      }
    })

    return filteredWorkingSchedule
  }

  _inBetween(time, from, to) {
    let [hours, minutes, seconds] = time.split(':')

    let date = moment.utc(
      `${this.start
        .clone()
        .format('YYYY-MM-DD')} ${hours}:${minutes}:${seconds}`
    )

    return date.isBetween(from, to)
  }

  _hasOption(time, options) {
    return options.some(option => {
      return option.time.format('HH:mm:ss') === time
    })
  }

  _makeCustomOption(time) {
    let [hours, minutes, seconds] = time.split(':')

    let optionTime = moment.utc(
      `${this.start
        .clone()
        .format('YYYY-MM-DD')} ${hours}:${minutes}:${seconds}`
    )

    return this._makeOption(optionTime)
  }

  _makeOption(time) {
    return {
      key: time.format('YYYYMMDDHHmmss'),
      time: time.clone(),
      duration: null,
    }
  }
}
