export default class OvertimeRequest {
  constructor(owner, breakdowns) {
    this.breakdowns = breakdowns
    this.owner = owner
  }

  getCurrentAllowance(remainingAllowance) {
    return remainingAllowance
  }

  getNewAllowance(remainingAllowance) {
    return remainingAllowance + this.getChangedAllowance()
  }

  getChangedAllowance() {
    let amountInMinutes = 0

    this.breakdowns.forEach(breakdown => {
      if (breakdown.unit === 'days') {
        amountInMinutes += breakdown.amount * this.minutesPerWorkingDay
      } else {
        amountInMinutes += breakdown.amount * 60
      }
    })

    let divider = this.allowanceUnitIsDays ? this.minutesPerWorkingDay : 60

    return amountInMinutes / divider
  }

  get allowanceUnit() {
    return this.owner.allowanceUnit
  }

  get allowanceUnitIsDays() {
    return this.owner.allowanceUnitIsDays
  }

  get minutesPerWorkingDay() {
    return this.owner.minutesPerWorkingDay
  }
}
