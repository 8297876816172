export default {
  computed: {
    canApproveOvertime() {
      return this.overtimeIsRequested && this.hasOvertimeApproveLink
    },

    hasOvertimeApproveLink() {
      const overtimeApproveLink = this.overtime._links.find(
        link => link.rel === 'approve'
      )
      return !!overtimeApproveLink
    },

    canEditOvertime() {
      return this.hasOvertimeUpdateLink
    },

    hasOvertimeUpdateLink() {
      const overtimeUpdateLink = this.overtime._links.find(
        link => link.rel === 'update'
      )

      return !!overtimeUpdateLink
    },

    canCancelOvertime() {
      return (
        !['CANCELLED', 'REJECTED'].includes(this.overtime.status) &&
        this.hasOvertimeCancelLink
      )
    },

    hasOvertimeCancelLink() {
      const overtimeCancelLink = this.overtime._links.find(
        link => link.rel === 'cancel'
      )

      return !!overtimeCancelLink
    },

    overtimeIsRequested() {
      return this.overtime.status === 'REQUESTED'
    },
  },
}
