import moment from 'moment-timezone'

export default class LeavePermission {
  static canCancel(leave, employment) {
    return (
      !this.isLeaveCancelledOrRejected(leave) &&
      (employment.isAdmin() ||
        (employment.id === leave.owner_id && this.isFuture(leave)) ||
        this.canApprove(leave, employment))
    )
  }

  static canApprove(leave, employment) {
    return this._ownerSubordinateOf(employment, leave.owner_id)
  }

  static canEditLeave(leave, employment) {
    return (
      employment.is_admin ||
      this._ownerSubordinateOf(employment, leave.owner_id) ||
      (employment.id === leave.owner_id && this.isFuture(leave))
    )
  }

  static canAddAttachments(leave, viewer) {
    return (
      viewer.is_admin ||
      this._ownerSubordinateOf(viewer, leave.owner_id) ||
      viewer.id === leave.owner_id
    )
  }

  static canRetract(leave, employment) {
    return this.isApproverOrAdmin(leave, employment)
  }

  static isApproverOrAdmin(leave, employment) {
    return this.canApprove(leave, employment) || employment.is_admin
  }

  static canAddComment(leave, commenter) {
    return (
      this.isApproverOrAdmin(leave, commenter) ||
      commenter.id === leave.owner_id ||
      commenter.isFollowerOf(leave.owner.getKey())
    )
  }

  static isFuture(leave) {
    const leaveDate = moment.utc(leave.from).startOf('day')
    const currentTime = moment()
      .utc(true)
      .startOf('day')
    return leaveDate.isAfter(currentTime)
  }

  static isLeaveCancelledOrRejected(leave) {
    return ['CANCELED', 'REJECTED'].includes(leave.status)
  }

  static _ownerSubordinateOf(employment, ownerKey) {
    return (
      !!employment.subordinates &&
      employment.subordinates.some(employment => employment.id === ownerKey)
    )
  }
}
