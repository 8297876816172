import Notification from '@/components/notifications/Notification'

export default class HolidayNotification extends Notification {
  constructor(notification) {
    super(notification)
  }

  // eslint-disable-next-line no-unused-vars
  navigate(router, query) {
    router.push(
      { name: 'holidays', query: { company: this.notification.data.company } },
      () => {}
    )
  }

  getType() {
    return 'upcoming_company_holiday_created'
  }

  getMessage() {
    return this.notification.data.message
  }
}
