import { first, maxBy } from 'lodash-es'
import Error from '@/models/overtime/drafting/Error'
import Breakdowns from '@/models/overtime/drafting/breakdowns/Breakdowns'
import OvertimeRequest from '@/models/overtime/drafting/OvertimeRequest'
import Employment from '@/models/employment/Employment'

export default class OvertimeRequests {
  constructor(draftedOvertimes, company) {
    this.mapMessages(draftedOvertimes)
    this.mapItems(draftedOvertimes, company)
    this.mapBreakdowns()
    this.updateEmploymentBreakdowns()
  }

  mapMessages(draftedOvertimes) {
    this.messages = {}
    this.messages.errors = draftedOvertimes.messages.errors.map(
      error => new Error(error)
    )
  }

  mapItems(draftedOvertimes, company) {
    this.items = draftedOvertimes.employments.map(
      employment =>
        new OvertimeRequest(
          new Employment(employment, company),
          new Breakdowns(employment.breakdowns).breakdowns
        )
    )
  }

  mapBreakdowns() {
    this.breakdowns = this.selectedDraftedOvertime.breakdowns.map(breakdown =>
      breakdown.clone()
    )
  }

  get errors() {
    return this.messages.errors
  }

  get datesOverlapOnDifferentCalendars() {
    return this.errors.some(error => error.isCrossBoundaries)
  }

  get requestingOutsideOfEmploymentPeriod() {
    return this.errors.some(error => error.isOutsideOfEmploymentPeriod)
  }

  get doesntHaveCalendarToRequestOvertime() {
    return this.errors.some(error => error.isCalendarNotSetup)
  }

  get minutesPerWorkingDay() {
    return this.selectedDraftedOvertime.owner.minutesPerWorkingDay
  }

  get allowanceUnit() {
    let dayUnitBreakdowns = this.selectedDraftedOvertime.breakdowns.some(
      breakdown => breakdown.unit === 'days'
    )

    return dayUnitBreakdowns ? 'days' : 'hours'
  }

  get selectedDraftedOvertime() {
    if (this.isSingleRequest) {
      return first(this.items)
    }

    return this.getSuitableEmployment
  }

  get isSingleRequest() {
    return this.items.length === 1
  }

  get getSuitableEmployment() {
    if (this.requestHasDayAllowanceEmployments) {
      return this.getSuitableDayAllowanceEmployment
    }

    return this.getSuitableHourlyAllowanceEmployment
  }

  get requestHasDayAllowanceEmployments() {
    return this.dayAllowanceEmployments.length > 0
  }

  get getSuitableDayAllowanceEmployment() {
    return this.getDayAllowanceEmploymentWithMaximumHoursPerWorkingDay
  }

  get getDayAllowanceEmploymentWithMaximumHoursPerWorkingDay() {
    return this.employmentWithMaximumHoursPerWorkingDay(
      this.dayAllowanceEmployments
    )
  }

  get getSuitableHourlyAllowanceEmployment() {
    return this.getHourlyAllowanceEmploymentWithMaximumHoursPerWorkingDay
  }

  get getHourlyAllowanceEmploymentWithMaximumHoursPerWorkingDay() {
    return this.employmentWithMaximumHoursPerWorkingDay(
      this.hourlyAllowanceEmployments
    )
  }

  get dayAllowanceEmployments() {
    return this.items.filter(
      draftedOvertime => draftedOvertime.owner.allowanceUnitIsDays
    )
  }

  get hourlyAllowanceEmployments() {
    return this.items.filter(
      draftedOvertime => !draftedOvertime.owner.allowanceUnitIsDays
    )
  }

  employmentWithMaximumHoursPerWorkingDay(draftedOvertimes) {
    return maxBy(draftedOvertimes, 'owner.minutesPerWorkingDay')
  }

  updateBreakdownUnit(newBreakdown) {
    let oldBreakdownIndex = this.breakdowns.findIndex(breakdown =>
      breakdown.date.isSame(newBreakdown.date)
    )

    this.breakdowns.splice(oldBreakdownIndex, 1)
    this.breakdowns = [...this.breakdowns, newBreakdown]
    this.updateEmploymentBreakdowns()
  }

  breakdownAmountChanged() {
    this.updateEmploymentBreakdowns()
  }

  updateEmploymentBreakdowns() {
    this.items.forEach(draftedOvertime => {
      draftedOvertime.breakdowns = this.breakdowns.map(breakdown =>
        breakdown.clone()
      )
    })
  }
}
