import Vue from 'vue'
import axios from 'axios'

const HTTP_STATUS_UNAUTHORIZED = 401
const HTTP_STATUS_AUTHENTICATION_EXPIRED = 419
const HTTP_STATUS_SERVICE_UNAVAILABLE = 503

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

http.defaults.withCredentials = true

http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const onError = error => {
  if (
    error.response &&
    error.response.status &&
    error.response.status === HTTP_STATUS_SERVICE_UNAVAILABLE
  ) {
    window.location = '/maintenance.html'

    return
  }

  return Promise.reject(error)
}

const onSuccess = response => response

http.interceptors.response.use(onSuccess, onError)

Vue.prototype.$http = http

export default http
export {
  HTTP_STATUS_UNAUTHORIZED,
  HTTP_STATUS_AUTHENTICATION_EXPIRED,
  HTTP_STATUS_SERVICE_UNAVAILABLE,
}
