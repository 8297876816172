<template>
  <BaseAlert title="Cancelled" class="tw-block" theme="neutral">
    {{ cancelledText }}
    <div v-if="canRetractCancel">
      <button
        class="tw-font-semibold tw-underline"
        data-cy="btn-undo-cancel"
        @click="$emit('retract')"
      >
        Undo Cancel
      </button>
    </div>
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
import LeavePermission from '@/components/requests/leave/LeavePermission'
import CancelledEvent from '@/components/requests/leave/events/CancelledEvent'

export default {
  name: 'CancelledAlert',

  components: { BaseAlert },

  props: {
    leave: {
      type: Object,
      required: true,
    },

    event: {
      type: CancelledEvent,
      required: true,
    },
  },

  computed: {
    cancelledText() {
      return this.event.asNotice(this.activeEmployment)
    },

    canRetractCancel() {
      return LeavePermission.canRetract(this.leave, this.activeEmployment)
    },
  },
}
</script>
