import moment from 'moment-timezone'

export default {
  computed: {
    subscription() {
      return this.activeCompany.subscription
    },

    isSubscribed() {
      return this.subscription !== null
    },

    hasLastSubscription() {
      return this.activeCompany.last_subscription !== null
    },

    hasProperSubscription() {
      return this.activeCompany.hasProperSubscription
    },

    activeEmploymentsCount() {
      return this.activeCompany.employments_count
    },

    isSubscriptionActive() {
      return (
        this.isSubscribed &&
        this.subscription.stripe_status === 'active' &&
        this.activeCompany.current_subscription.ends_at === null
      )
    },

    isCanceled() {
      return (
        this.isSubscribed &&
        this.activeCompany.current_subscription.ends_at !== null
      )
    },

    isPreviousCustomer() {
      return (
        !this.isSubscriptionActive &&
        this.activeCompany.last_subscription !== null
      )
    },

    isNewCustomer() {
      return !this.isSubscribed && this.activeCompany.last_subscription == null
    },

    isManuallyBilled() {
      return this.isSubscribed && this.activeCompany.manually_billed === true
    },

    hasPaymentIssue() {
      if (this.isSubscribed && this.subscription.stripe_status !== null) {
        return (
          this.subscription.stripe_status === 'incomplete' ||
          this.subscription.stripe_status === 'incomplete_expired' ||
          this.subscription.stripe_status === 'past_due' ||
          this.subscription.stripe_status === 'unpaid'
        )
      }
      return false
    },

    isInGracePeriod() {
      if (this.subscription) {
        return moment(this.subscription.ends_at).isSameOrAfter(
          moment().utc(true)
        )
      }
      return false
    },

    subscribedPlan() {
      if (!this.isSubscribed) return 'Free'

      return this.subscription.plan.name
    },

    numberOfSeats() {
      if (!this.isSubscribed) return 5
      return this.isSubscribed && this.subscription.quantity
    },

    createdAt() {
      if (!this.isSubscribed) return null
      return this.subscription.created_at
    },

    endsAt() {
      if (!this.isSubscribed) return null
      return this.subscription.ends_at
    },
  },
}
