<template>
  <div
    class="tw-border tw-border-blue-350 tw-justify-start tw-p-4 tw-flex tw-items-center"
  >
    <div
      class="tw-h-full tw-flex tw-flex-col tw-justify-between tw-text-base tw-text-gray-800"
    >
      <div class="tw-font-medium tw-text-gray-555">
        Change
      </div>

      <div class="tw-mt-2 tw-flex tw-items-center">
        <SvgIcon
          v-if="allowances.hasUnlimitedAllowances"
          name="bars-2"
          class="tw-w-6 tw-h-6 tw-p-1 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-purple-100 tw-text-purple-700"
        />

        <SvgIcon
          v-if="!allowances.hasUnlimitedAllowances"
          name="arrow-right"
          class="tw-w-6 tw-h-6 tw-p-1 tw-flex tw-items-center tw-justify-center tw-rounded-full"
          :class="iconClasses"
        />

        <div class="tw-mx-2" data-cy="changed-allowance-range">
          <span class="tw-font-semibold tw-text-xl tw-text-gray-880">
            <template v-if="allowances.hasUnlimitedAllowances">∅</template>

            <template v-else>{{
              minimumChangedAllowance | toFraction
            }}</template>
          </span>

          <span v-if="allowances.hasChangedAllowanceRange">
            <span> to</span>

            <span class="tw-font-semibold tw-text-xl tw-text-gray-880">
              {{ maximumChangedAllowance | toFraction }}
            </span>
          </span>

          <template v-if="!allowances.hasChangedAllowanceRange">
            {{ capitalizedAllowanceUnit | pluralize(minimumChangedAllowance) }}
          </template>

          <template v-if="allowances.hasChangedAllowanceRange">
            {{ capitalizedAllowanceUnit | pluralize(maximumChangedAllowance) }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormatNumbers from '@/mixins/FormatNumbers'
import { capitalize } from 'lodash-es'

export default {
  name: 'ChangedAllowance',

  mixins: [FormatNumbers],

  props: {
    allowances: {
      type: Object,
      required: true,
    },

    iconClasses: {
      type: String,
      required: true,
    },
  },

  computed: {
    maximumChangedAllowance() {
      return this.toFixed(this.allowances.maximumChangedAllowance, 2)
    },

    minimumChangedAllowance() {
      return this.toFixed(this.allowances.minimumChangedAllowance, 2)
    },

    capitalizedAllowanceUnit() {
      return capitalize(this.allowances.allowanceUnit)
    },
  },
}
</script>
