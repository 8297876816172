<template>
  <span
    class="tw-rounded-full tw-bg-gray-300 tw-text-sm tw-ml-3 tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center"
  >
    {{ count }}
  </span>
</template>

<script>
export default {
  name: 'CountLabel',

  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
}
</script>
