<template>
  <BaseAlert class="tw-mt-2 tw-px-3 tw-py-3" theme="danger">
    You are not allowed to request overtime which crosses the boundary of the
    leave allowance year. Please raise separate requests for this year and next
    year.
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'

export default {
  name: 'DatesOverlapOnDifferentCalendarsError',
  components: {
    BaseAlert,
  },
}
</script>
