var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overtime-breakdown-item"},[_c('OvertimeBreakdown',{attrs:{"minutes-per-working-day":_vm.minutesPerWorkingDay,"breakdown":_vm.breakdown},on:{"breakdown-unit-changed":_vm.breakdownUnitChanged,"breakdown-amount-changed":_vm.breakdownAmountChanged},scopedSlots:_vm._u([{key:"default",fn:function({
        overtimeUnits,
        selectedOvertimeUnit,
        changeAmount,
        changeOvertimeUnit,
        displayAmount,
      }){return [_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-space-x-20"},[_c('RequestBreakdownDate',{attrs:{"date":_vm.breakdown.date}}),_c('div',{staticClass:"tw-flex tw-items-center tw-space-x-2"},[_c('input',{staticClass:"form-control border-gray-350 tw-w-24",attrs:{"min":"0","step":"any","type":"number"},domProps:{"value":displayAmount},on:{"input":changeAmount}}),_c('VSelect',{staticClass:"tw-w-24",attrs:{"id":"overtime_unit_type","value":selectedOvertimeUnit,"options":overtimeUnits,"multiple":false,"searchable":false,"show-labels":false,"allow-empty":false,"track-by":"key","label":"label","placeholder":""},on:{"input":changeOvertimeUnit}})],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }