<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <TimelineView
      v-if="timeline.eventCount"
      :timeline="timeline"
      :focus="tabActive"
      type="overtime"
      @delete-comment="deleteComment"
      @restore-comment="restoreComment"
    />

    <CommentInput
      v-if="canAddComment"
      :focus="tabActive"
      @add-comment="addComment"
    />
  </div>
</template>

<script>
import ValidatesForm from '@/mixins/ValidatesForm'
import Timeline from '@/components/requests/overtime/events/Timeline'
import { Overtimes } from '@/api'
import CommentInput from '@/components/requests/CommentInput'
import TimelineView from '@/components/requests/timeline/TimelineView'
import Comments from '@/api/comments/Comments'

export default {
  name: 'OvertimeUpdatesTab',

  components: {
    TimelineView,
    CommentInput,
  },

  mixins: [ValidatesForm],

  props: {
    overtime: {
      type: Object,
      required: true,
    },

    timeline: {
      type: Timeline,
      required: true,
    },

    tabActive: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      comment: '',
      commenting: false,
    }
  },

  computed: {
    commentIsEmpty() {
      return this.comment.trim() === ''
    },

    canAddComment() {
      return !!this.overtime._links.find(link => link.rel === 'add_comments')
    },

    isValid() {
      if (this.commentIsEmpty) {
        return false
      }

      return this.comment.length < 2000
    },
  },

  methods: {
    handleCommentTypingStatus(e) {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 13 && this.isValid) {
        this.addComment()
      }
    },

    async addComment(comment) {
      this.commenting = true

      try {
        await Overtimes.saveComment(this.overtime, {
          company: this.activeCompany.id,
          text: comment,
        })

        this.$emit('comment-added')

        this.success('Comment saved successfully.')
      } catch ({ response: { data } }) {
        this.error(data.message)
      }

      this.commenting = false
    },

    async deleteComment(comment) {
      try {
        await Comments.delete(comment, {
          company: this.activeCompany.id,
        })

        this.$emit('comment-deleted')

        this.success('Comment deleted successfully.')
      } catch ({ response: { data } }) {
        this.error(data.message)
      }
    },

    async restoreComment(comment) {
      try {
        await Comments.restore(comment, {
          company: this.activeCompany.id,
        })

        this.$emit('comment-restored')

        this.success('Comment restored successfully.')
      } catch ({ response: { data } }) {
        this.error(data.message)
      }
    },
  },
}
</script>
