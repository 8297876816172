import { http } from './../../plugins'
import types from './../mutation-types'

const initialState = {
  list: [],
  current: null,
}

export const state = {
  ...initialState,
  initialState() {
    return initialState
  },
}

export const mutations = {
  [types.SET_ALLOWANCES](state, allowances) {
    state.list = allowances
  },

  [types.SET_CURRENT_ALLOWANCE](state, allowance) {
    state.current = allowance
  },
}

export const actions = {
  setCurrent({ commit }, allowance) {
    commit(types.SET_CURRENT_ALLOWANCE, allowance)
  },

  async all({ commit, rootState }) {
    const { data } = await http.get('allowance-types', {
      params: rootState.route.query,
    })

    commit(types.SET_ALLOWANCES, data)
  },

  async store({ commit }, payload) {
    const { data } = await http.post('allowance-types', payload)

    commit(types.SET_CURRENT_ALLOWANCE, data)
  },

  async update({ dispatch }, { allowance, payload }) {
    await http.put(`allowance-types/${allowance.id}`, payload)

    await dispatch('find', allowance.id)
  },

  async find({ commit, rootState }, id) {
    const { data } = await http.get(`allowance-types/${id}`, {
      params: rootState.route.query,
    })

    commit(types.SET_CURRENT_ALLOWANCE, data)
  },

  async addBreakdown({ dispatch }, { allowance, payload }) {
    await http.post('allowance-breakdown-types', payload)

    await dispatch('find', allowance.id)
  },

  async updateBreakdown({ dispatch }, { breakdown, payload }) {
    await http.put(`allowance-breakdown-types/${breakdown.id}`, payload)

    await dispatch('find', breakdown.allowance_type_id)
  },

  async removeBreakdown({ dispatch }, { breakdown, company }) {
    await http.delete(`allowance-breakdown-types/${breakdown.id}`, {
      data: { company_id: company.id },
    })

    await dispatch('find', breakdown.allowance_type_id)
  },
}
