<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <TimelineView
      :timeline="timeline"
      :focus="tabActive"
      type="leave"
      @delete-comment="deleteComment"
      @restore-comment="restoreComment"
    />

    <CommentInput
      v-if="leave.canAddComment(activeEmployment)"
      :focus="tabActive"
      @add-comment="addComment"
    />
  </div>
</template>

<script>
import Timeline from '@/components/requests/leave/events/Timeline.js'
import TimelineView from '@/components/requests/timeline/TimelineView'
import CommentInput from '@/components/requests/CommentInput'
import { Leaves } from '@/api'
import Leave from '@/models/leave/Leave'
import Comments from '@/api/comments/Comments'

export default {
  name: 'LeaveUpdatesTab',

  components: { CommentInput, TimelineView },

  props: {
    timeline: {
      type: Timeline,
      required: true,
    },

    leave: {
      type: Leave,
      required: true,
    },

    tabActive: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async addComment(comment) {
      await Leaves.addComment(this.leave.getKey(), {
        company: this.activeCompany.id,
        text: comment,
      })

      this.$emit('comment-added')

      this.success('Comment saved successfully.')
    },

    async deleteComment(comment) {
      try {
        await Comments.delete(comment, {
          company: this.activeCompany.id,
        })

        this.$emit('comment-deleted')

        this.success('Comment deleted successfully.')
      } catch ({ response: { data } }) {
        this.error(data.message)
      }
    },

    async restoreComment(comment) {
      try {
        await Comments.restore(comment, {
          company: this.activeCompany.id,
        })

        this.$emit('comment-restored')

        this.success('Comment restored successfully.')
      } catch ({ response: { data } }) {
        this.error(data.message)
      }
    },
  },
}
</script>
