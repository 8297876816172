<template>
  <div class="tw-flex items-center">
    <div class="tw-ml-2 tw-flex items-center">
      <UserAvatar
        v-for="user in filteredUsers"
        :key="user.id"
        :user="user"
        :show-tooltip="false"
        tooltip-placement="top"
        class="tw-border-2 tw-border-white tw--ml-2 tw-rounded-full"
      />
    </div>

    <button
      v-show="users.length && extraAndNonvisibleUserCount > 0"
      ref="target"
      class="extra-user-count tw-border-2 tw-border-white tw--ml-2 tw-flex tw-items-center tw-justify-center tw-bg-gray-102 tw-text-gray-660 tw-font-semibold tw-rounded-full"
      @click="$emit('show-more-users')"
    >
      +{{ extraAndNonvisibleUserCount }}
    </button>

    <div v-show="false" ref="content">
      <div class="tw-p-3">
        <p v-for="user in extraUsers" :key="user.id" class="tw-text-left">
          {{ user.full_name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { slice, reject, find } from 'lodash-es'
import tippy from 'tippy.js'

export default {
  name: 'UserAvatarList',

  props: {
    users: {
      type: Array,
      required: true,
    },

    nonvisibleUserCount: {
      type: Number,
      default: 0,
    },

    tooltipPlacement: {
      type: String,
      default: 'bottom',
    },
  },

  computed: {
    filteredUsers() {
      return slice(this.users, 0, 5)
    },

    extraUsers() {
      return reject(this.users, user =>
        find(this.filteredUsers, { id: user.id })
      )
    },

    extraAndNonvisibleUserCount() {
      return this.extraUsers.length + this.nonvisibleUserCount
    },
  },

  mounted() {
    if (!this.extraUsers.length) return

    this.tippy = tippy.one(this.$refs.target, {
      html: this.$refs.content.firstChild,
      placement: this.tooltipPlacement,
      distance: 1,
      interactiveBorder: 15,
      interactive: true,
      theme: 'dark',
      arrow: true,
      delay: [400, 0],
      onShow: this.hideOtherTooltips,
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },

  methods: {
    hideOtherTooltips() {
      ;[...document.querySelectorAll('.tippy-popper')].forEach(popper => {
        popper._tippy.hide()
      })
    },
  },
}
</script>

<style scoped>
.extra-user-count {
  width: 28px;
  height: 28px;
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: 12px;
}
</style>
