<template>
  <div
    class="tw-px-4 tw-py-2 tw-flex tw-items-center tw-justify-between tw-w-full tw-bg-white tw-rounded-lg tw-border tw-border-gray-220"
  >
    <template v-if="isEditBreakdown === false">
      <RequestBreakdownDate :date="breakdownDate" />

      <div class="tw-space-x-2 tw-flex tw-items-center tw-justify-end">
        <div
          v-if="breakdownDurationInAllowanceUnit"
          class="sm:tw-flex tw-text-gray-660 tw-font-medium"
        >
          {{ breakdownDurationInAllowanceUnit }}
          {{ allowanceUnit | pluralize(breakdownDurationInAllowanceUnit) }}
        </div>

        <div
          v-if="showEditButton"
          class="tw-border-l tw-pl-2 tw-py-1 tw-border-gray-220 tw-flex tw-items-center tw-justify-center"
        >
          <IconButton
            icon="edit-pencil-outline"
            :show-border="false"
            :width="6"
            :height="6"
            @click="isEditBreakdown = !isEditBreakdown"
          />
        </div>
      </div>
    </template>
    <template v-if="isEditBreakdown">
      <OvertimeBreakdownEditor
        :key="overtimeBreakdown.key"
        :breakdown="overtimeBreakdown"
        :minutes-per-working-day="minutesPerWorkingDay"
        @breakdown-unit-changed="breakdownUnitChanged"
        @breakdown-amount-changed="breakdownAmountChanged"
        @update-overtime-breakdown="updateOvertimeBreakdown"
        @close-breakdown-editor="closeBreakdownEditor"
      />
    </template>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import FormatNumbers from '@/mixins/FormatNumbers'
import IconButton from '@/components/IconButton'
import RequestBreakdownDate from '@/components/requests/RequestBreakdownDate'
import { round } from 'lodash-es'
import OvertimeBreakdownEditor from '@/components/requests/overtime/OvertimeBreakdownEditor'

export default {
  name: 'OvertimeBreakdownWrapper',

  components: { IconButton, RequestBreakdownDate, OvertimeBreakdownEditor },

  mixins: [FormatNumbers],

  props: {
    overtimeBreakdown: {
      type: Object,
      required: true,
    },

    minutesPerWorkingDay: {
      type: Number,
      required: true,
    },

    showEditButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isEditBreakdown: false,
      isShowEditButton: false,
    }
  },

  computed: {
    breakdownDate() {
      return moment(this.overtimeBreakdown.date)
    },

    breakdownDurationInAllowanceUnit() {
      return round(this.overtimeBreakdown.amount, 2)
    },

    allowanceUnit() {
      return this.overtimeBreakdown.unit
    },
  },

  methods: {
    breakdownUnitChanged(breakdown) {
      this.$emit('breakdown-unit-changed', breakdown)
    },

    breakdownAmountChanged(breakdown) {
      this.$emit('breakdown-amount-changed', breakdown)
    },

    updateOvertimeBreakdown(previousBreakdown) {
      this.isEditBreakdown = !this.isEditBreakdown
      this.$emit('update-overtime-breakdown', previousBreakdown)
    },

    closeBreakdownEditor(previousBreakdown) {
      this.isEditBreakdown = !this.isEditBreakdown
      this.$emit('close-breakdown-editor', previousBreakdown)
    },
  },
}
</script>
