<template>
  <slideout-panel />
</template>

<script>
export default {
  name: 'SlideOver',
}
</script>

<style lang="scss">
.slideout-panel {
  .slideout-wrapper {
    .slideout {
      @apply .flex;
      @apply .flex-col;

      > div {
        @apply .flex-1;
      }
    }
  }
}

@screen sm {
  .slideout-panel {
    .slideout-wrapper {
      .slideout {
        @apply .overflow-hidden;
      }
    }
  }
}
</style>
