import RequestPanel from '@/components/requests/RequestPanel.vue'

export default class RequestForm {
  constructor({ employments = [], success, failed }) {
    this.employments = [...employments]
    this.success = success
    this.failed = failed
  }

  async open(company, employment, period = []) {
    this.company = company

    if (!company.hasProperSubscription) {
      this.failed(
        'You currently have more employees than allowed by your plan. Would you like to upgrade your plan?',
        'Exceeded plan limit'
      )

      return
    }

    this.success({
      component: RequestPanel,
      openOn: 'right',
      width: 1100,
      props: {
        employee: { ...employment },
        period: [...period],
        employments: this.employments,
      },
    })
  }
}
