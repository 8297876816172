<template>
  <span>{{ detailsText }}</span>
</template>

<script>
import pluralize from 'pluralize'
import FormatNumbers from '@/mixins/FormatNumbers'

export default {
  name: 'OvertimeBreakdownDetailsText',

  mixins: [FormatNumbers],

  props: {
    breakdowns: {
      type: Array,
      required: true,
    },
  },

  computed: {
    detailsText: function() {
      let amountInDays = 0
      let amountInMinutes = 0
      this.breakdowns.forEach(breakdown => {
        if (breakdown.unit === 'days') {
          amountInDays += breakdown.amount
        } else {
          amountInMinutes += breakdown.amount * 60
        }
      })

      const amountInHours = Math.floor(amountInMinutes / 60)
      const remainingAmountsInMinutes = Math.floor(amountInMinutes % 60)

      let allowances = [
        { unit: 'days', amount: this.toFixed(amountInDays, 2) },
        { unit: 'hours', amount: this.toFixed(amountInHours, 2) },
        { unit: 'minutes', amount: remainingAmountsInMinutes },
      ]

      let textPhrases = []
      allowances.forEach(allowance => {
        if (allowance.amount) {
          return textPhrases.push(
            allowance.amount + ' ' + pluralize(allowance.unit, allowance.amount)
          )
        }
      })

      return textPhrases.length ? textPhrases.join(', ') : 0
    },
  },
}
</script>
