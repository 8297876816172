class ExpiringStorage {
  constructor() {
    this.localStorage = window.localStorage
  }

  get(key) {
    const cached = JSON.parse(this.localStorage.getItem(key))

    if (!cached) {
      return null
    }

    const expires = new Date(cached.expires)

    if (expires < new Date()) {
      this.localStorage.removeItem(key)
      return null
    }

    return cached.value
  }

  set(key, value, lifeTimeInMinutes = 60 * 24 * 365) {
    const currentTime = new Date().getTime()

    const expires = new Date(currentTime + lifeTimeInMinutes * 60000)

    this.localStorage.setItem(key, JSON.stringify({ value, expires }))
  }

  clear() {
    this.localStorage.clear()
  }
}

export default new ExpiringStorage()
