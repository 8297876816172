<template>
  <div class="tw-flex tw-flex-1 tw-flex-col">
    <div
      v-if="!isLeaveEditing"
      class="tw-mb-4 tw-pb-4 tw-space-x-6 tw-border-b tw-border-gray-220 tw-flex tw-items-center tw-justify-end"
    >
      <div class="tw-space-x-2 tw-items-center">
        <ToggleButton
          v-model="showHandover"
          :labels="true"
          color="#1da1f2"
          @change="onToggleDebounced"
        />
        <span class="tw-text-gray-770 tw-font-medium tw-text-base">
          Show Handover
        </span>
      </div>

      <div>
        <LeaveDetailsFilter
          container-class=".slideout"
          :selected-filters="{
            overlapScope: overlapScope,
            dayScope: dayScope,
            conflicts: conflicts,
          }"
          @change-filters="filterChanged"
        />
      </div>
    </div>

    <ScrollableContent v-if="!isLeaveEditing">
      <Loading
        loader="dots"
        :is-full-page="false"
        :active="showHandover && loading"
      />
      <div v-if="showHandover" class="tw-mb-3 tw-text-lg tw-font-medium">
        Before
        <Loading
          class="tw-relative tw-h-1"
          loader="dots"
          :is-full-page="false"
          :active="showHandover && beforeHandoverOverlaps.length === 0"
        />
      </div>
      <div>
        <HandoverBreakdown
          v-for="overlap in beforeHandoverOverlaps"
          :key="overlap.date"
          :overlaps="overlap"
          :timezone="leave.timezone"
          @show-overlap-leave-details="
            leaveKey => $emit('show-overlap-leave-details', leaveKey)
          "
        />
      </div>

      <div
        :class="{
          'tw-my-5 tw-p-4 tw-border tw-rounded-lg tw-bg-blue-200 tw-border-blue-350 tw-flex tw-flex-col': showHandover,
        }"
      >
        <div v-if="showHandover" class="tw-mb-3 tw-text-lg tw-font-medium">
          Requests
        </div>

        <div>
          <Loading loader="dots" :is-full-page="false" :active="loading" />
          <div class="tw-space-y-2">
            <LeaveBreakdown
              v-for="(breakdown, index) in leaveBreakdowns"
              :key="index"
              :breakdown="breakdown"
              :overlaps="overlapLeaves.overlaps"
              :timezone="leave.timezone"
              @show-overlap-leave-details="
                leaveKey => $emit('show-overlap-leave-details', leaveKey)
              "
            />
          </div>
        </div>

        <div class="tw-my-4 tw-space-y-3 tw-tw-flex tw-justify-end">
          <div
            class="tw-text-right tw-text-lg tw-font-semibold tw-text-gray-880"
            data-cy="leave-duration"
          >
            {{ leaveDuration }}
          </div>

          <div class="tw-text-right">
            <LeaveTypeLabel :leave="leave" />
          </div>
        </div>
      </div>

      <div v-if="showHandover" class="tw-mb-3 tw-text-lg tw-font-medium">
        After
        <Loading
          class="tw-relative tw-h-1"
          loader="dots"
          :is-full-page="false"
          :active="showHandover && afterHandoverOverlaps.length === 0"
        />
      </div>
      <div>
        <HandoverBreakdown
          v-for="overlap in afterHandoverOverlaps"
          :key="overlap.date"
          :overlaps="overlap"
          :timezone="leave.timezone"
          @show-overlap-leave-details="
            leaveKey => $emit('show-overlap-leave-details', leaveKey)
          "
        />
      </div>
    </ScrollableContent>

    <div v-if="isLeaveEditing" class="tw-flex tw-flex-1">
      <EditLeaveDetails
        :leave="leave"
        class="tw-flex tw-flex-1"
        @quit-edit-mode="$emit('quit-leave-edit-mode')"
        @has-leave-edit-errors="value => $emit('has-leave-edit-errors', value)"
        @show-overlap-leave-details="
          leaveKey => $emit('show-overlap-leave-details', leaveKey)
        "
        @leave-updated="$emit('leave-updated')"
      />
    </div>
  </div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import { ToggleButton } from 'vue-js-toggle-button'
import HandoverBreakdown from '@/components/requests/leave/HandoverBreakdown'
import LeaveBreakdown from '@/components/requests/leave/LeaveBreakdown'
import ValidatesForm from '@/mixins/ValidatesForm'
import LeaveDetailsFilter from '@/components/requests/leave/LeaveDetailsFilter'
import moment from 'moment'
import { Overlaps } from '@/api'
import LeaveTypeLabel from '@/components/requests/leave/LeaveTypeLabel'
import EditLeaveDetails from '@/components/requests/leave/EditLeaveDetails'
import Leave from '@/models/leave/Leave'
import ScrollableContent from '@/components/ScrollableContent'
import expiringStorage from '@/support/expiringStorage'
import Loading from 'vue-loading-overlay'
import { debounce } from 'lodash-es'
import RequestDuration from '@/components/requests/RequestDuration'
import OverlapCollection from '@/models/reporting/OverlapCollection'

export default {
  name: 'LeaveDetailsTab',

  components: {
    Loading,
    LeaveTypeLabel,
    LeaveDetailsFilter,
    LeaveBreakdown,
    HandoverBreakdown,
    ToggleButton,
    EditLeaveDetails,
    ScrollableContent,
  },

  mixins: [FormatDate, ValidatesForm],

  props: {
    leave: {
      type: Leave,
      required: true,
    },

    isLeaveEditing: {
      type: Boolean,
      required: true,
    },

    isTabActive: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      showHandover: false,
      overlapLeaves: new OverlapCollection(),
      overlapScope: '',
      dayScope: 'working_days',
      conflicts: false,
      loading: false,
    }
  },

  computed: {
    leaveDuration() {
      return new RequestDuration(this.leave.getLeaveDuration()).toString(
        'Total: '
      )
    },

    leaveBreakdowns() {
      let breakdowns = [...this.leave.getBreakdowns()]

      if (this.dayScope === 'working_days') {
        breakdowns = [
          ...breakdowns.filter(breakdown => breakdown.type === 'working_day'),
        ]
      }

      if (this.conflicts) {
        breakdowns = [
          ...breakdowns.filter(breakdown =>
            this.isLeaveLimitExceedsForDate(breakdown.from)
          ),
        ]
      }

      return breakdowns
    },

    beforeHandoverOverlaps() {
      if (this.showHandover) {
        return this.overlapLeaves.getBeforeHandOverOverlaps(this.leave)
      }

      return []
    },

    afterHandoverOverlaps() {
      if (this.showHandover) {
        return this.overlapLeaves.getAfterHandOverOverlaps(this.leave)
      }

      return []
    },

    toTime() {
      return {
        weekday: this.isoStringToWeekday(this.leave.to, this.leave.timezone),
        date: this.formatDateFromIsoToDayReadableNumberMonthYear(
          this.leave.to,
          this.leave.timezone
        ),
        time: this.formatDateFromIsoToHourMinutes(
          this.leave.to,
          this.leave.timezone
        ),
      }
    },
  },

  watch: {
    isTabActive(v) {
      if (!v) {
        this.$emit('quit-leave-edit-mode')
      }
    },
  },

  async mounted() {
    await this.getOverlapLeaves()

    this.setInitialDetailsFilters()
  },

  methods: {
    onToggleDebounced: debounce(function() {
      this.getOverlapLeaves()
    }, 300),

    async filterChanged(filters) {
      this.overlapScope = filters.overlapScope
      this.dayScope = filters.dayScope
      this.conflicts = filters.conflicts

      this.saveFilters(filters)

      this.loading = true
      await this.getOverlapLeaves()
      this.loading = false
    },

    saveFilters(filters) {
      expiringStorage.set('leave_details_filters', filters)
    },

    async getOverlapLeaves() {
      const from = moment
        .tz(this.leave.from, this.leave.timezone)
        .utc(true)
        .toISOString()
      const to = moment
        .tz(this.leave.to, this.leave.timezone)
        .utc(true)
        .toISOString()

      this.overlapLeaves = await Overlaps.get(this.leave.getOwnerKey(), {
        company_id: this.activeCompany.id,
        period: `${from}, ${to}`,
        'schedule-filter': this.dayScope,
        'handover-interval': this.showHandover ? 3 : 0,
        department:
          this.overlapScope === 'department'
            ? this.leave.owner?.department_id
            : null,
      })
    },

    isLeaveLimitExceedsForDate(date) {
      let overlaps = this.overlapLeaves.filterByDate(date)

      if (overlaps.length) {
        return overlaps[0].isLeaveLimitExceeded()
      }

      return false
    },

    setInitialDetailsFilters() {
      if (this.overlapLeaves.hasLeaveLimitExceeded()) {
        this.applyFilterFromOverlapLeave()
      } else {
        this.applySavedFilters()
      }
    },

    applyFilterFromOverlapLeave() {
      this.overlapScope = this.overlapLeaves.getOverlapScope()
    },

    applySavedFilters() {
      const filters = expiringStorage.get('leave_details_filters')

      if (filters) {
        this.overlapScope = filters.overlapScope
        this.dayScope = filters.dayScope
        this.conflicts = filters.conflicts
      }
    },
  },
}
</script>
