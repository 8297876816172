import Vue from 'vue'
import veeEn from 'vee-validate/dist/locale/en'
import {
  Validator,
  install as VeeValidate,
} from 'vee-validate/dist/vee-validate.minimal.esm.js'
import * as veeRules from 'vee-validate/dist/rules.esm'

/* Form Validation Config */
const config = {
  errorBagName: 'errors', // change if property conflicts.
  fieldsBagName: 'fields',
  delay: 0,
  locale: 'en',
  dictionary: null,
  strict: true,
  classes: false,
  classNames: {
    touched: 'touched', // the control has been blurred
    untouched: 'untouched', // the control hasn't been blurred
    valid: 'valid', // model is valid
    invalid: 'invalid', // model is invalid
    pristine: 'pristine', // control has not been interacted with
    dirty: 'dirty', // control has been interacted with
  },
  events: 'blur|change|input',
  inject: false,
  validity: false,
  aria: true,
}

Object.keys(veeRules).forEach(k => Validator.extend(k, veeRules[k]))

Validator.localize('en', veeEn)

Vue.use(VeeValidate, config)
