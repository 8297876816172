<template>
  <RequestFooterPanel
    :submit-button-disabled="submitButtonDisabled"
    :submit-button-loading="submitButtonLoading"
    :spinner-button-tooltip-message="spinnerButtonTooltipMessage"
    @send-request="$emit('send-overtime-request')"
    @cancel="$emit('hide')"
  >
    <!-- eslint-disable vue/no-v-html -->
    <template v-slot:microcopyText>
      <div data-cy="micro-copy-text" v-html="microcopyText"></div>
    </template>
    <!-- eslint-enable -->
  </RequestFooterPanel>
</template>

<script>
import RequestFooterPanel from '@/components/requests/RequestFooterPanel'

export default {
  name: 'OvertimeFooterPanel',

  components: { RequestFooterPanel },

  props: {
    submitButtonDisabled: {
      type: Boolean,
      default: false,
    },

    submitButtonLoading: {
      type: Boolean,
      default: false,
    },

    overtimeRequestApprovalMicrocopy: {
      type: Object,
      default: null,
    },

    spinnerButtonTooltipMessage: {
      type: String,
      default: '',
    },
  },

  computed: {
    microcopyText() {
      if (this.overtimeRequestApprovalMicrocopy === null) return ''

      let approvalTextArray = this.overtimeRequestApprovalMicrocopy.microcopy.split(
        '**'
      )

      let micrcopyText = approvalTextArray[0]
      if (approvalTextArray.length === 3) {
        micrcopyText +=
          `<span class="tw-text-gray-700 tw-font-semibold">` +
          approvalTextArray[1] +
          `</span>` +
          approvalTextArray[2]
      }

      return micrcopyText
    },
  },
}
</script>
