<template>
  <div
    class="no-data tw-px-2 tw-text-center tw-flex tw-items-center tw-justify-center tw-h-full"
  >
    <h4 class="tw-my-4 tw-text-lg tw-text-gray-500 tw-font-normal">
      {{ message }}
    </h4>
  </div>
</template>

<script>
export default {
  name: 'NoData',

  props: {
    message: {
      type: String,
      default: 'No Data Available',
    },
  },
}
</script>
