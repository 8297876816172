import OvertimeEvent from '@/components/requests/overtime/events/OvertimeEvent'

export default class OvertimeUpdatedEvent extends OvertimeEvent {
  constructor(event) {
    super(event)
  }

  asText(viewer) {
    return `
      **${this.getActorName(viewer)}** updated the request
    `
  }
}
