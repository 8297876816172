import LeaveEvent from '@/components/requests/leave/events/LeaveEvent'

export default class RequestedEvent extends LeaveEvent {
  constructor(event) {
    super(event)
  }

  asText(viewer) {
    if (this.viewerIsActor(viewer) || this.actorIsOwner()) {
      return `
        **${this.getActorName(viewer)}**
        requested leave
      `
    }

    return `
      **${this.getActorName(viewer)}**
      requested leave on behalf of ${this._getLeaveOwnerName(viewer)}
    `
  }
}
