export default class Error {
  constructor(error) {
    this.error = error
  }

  get isCrossBoundaries() {
    return this.error.code === 'calendar_boundaries_crossed'
  }

  get isOutsideOfEmploymentPeriod() {
    return (
      this.error.code === 'overtime_requesting_outside_of_employment_period'
    )
  }

  get isCalendarNotSetup() {
    return this.error.code === 'calendar_not_setup'
  }
}
