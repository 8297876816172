<template>
  <div
    v-if="check"
    ref="reference"
    data-cy="user-settings"
    class="tw-flex tw-items-center tw-cursor-pointer tw-no-underline tw-font-normal tw-leading-none tw-text-gray-800 tw-pl-6 tw-py-5"
  >
    <user-avatar :user="authUser" class="tw-mr-1"></user-avatar>
    <div v-show="false" ref="tooltip">
      <div class="tw-rounded tw-overflow-hidden" style="min-width: 200px;">
        <div class="tw-border-b tw-border-gray-300 tw-px-4 tw-pt-2">
          <div class="tw-flex tw-items-center tw-justify-start tw-relative">
            <span
              class="tw-pb-2 tw-text-gray-800 tw-text-thin tw-font-semibold tw-cursor-default"
              >Account</span
            >
            <button
              class="tw-opacity-50 hover:tw-opacity-100 modal-close tw-absolute tw-right-0 tw-top-0"
              @click="hideTippy"
            >
              <svg-icon name="close" style="width: 12px; height: 12px;" />
            </button>
          </div>
        </div>
        <div class="tw-px-4 tw-py-3 tw-flex tw-items-start tw-justify-start">
          <user-avatar :user="authUser" class="tw-mr-4 tw-mt-1"></user-avatar>
          <div class="tw-flex tw-flex-col tw-justify-start">
            <span
              class="tw-text-gray-800 tw-font-semibold tw-truncate tw-cursor-default"
              :title="fullName(authUser)"
              style="max-width: 130px;"
              >{{ fullName(authUser) }}</span
            >
            <span
              class="tw-text-gray-600 tw-truncate tw-cursor-default"
              :title="authUser.email"
              style="max-width: 130px;"
              >{{ authUser.email }}</span
            >
            <div>
              <router-link
                :to="{ name: 'my-profile' }"
                title="My Profile [alt+p]"
                class="tw-overflow-hidden"
                data-cy="btn-myprofile"
                @click.native="hideTippy"
              >
                <span
                  class="tw-font-normal tw-text-blue-500 tw-overflow-hidden tw-no-underline hover:tw-underline"
                  >Manage</span
                >
              </router-link>
            </div>
          </div>
        </div>
        <div class="tw-pb-2">
          <div class="tw-flex tw-flex-col tw-border-t">
            <a
              class="tw-cursor-pointer tw-no-underline hover:tw-bg-gray-300 tw-overflow-hidden tw-px-4 tw-py-3"
              data-cy="btn-log-out"
              @click="signout"
            >
              <span class="tw-text-gray-800">Log out</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tippy from 'tippy.js'
import HandleAuth from '@/mixins/HandleAuth'
import FormatEmploymentName from '@/mixins/FormatEmploymentName'

export default {
  name: 'UserCircle',

  mixins: [HandleAuth, FormatEmploymentName],
  computed: {
    ...mapGetters('auth', ['check']),
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.reference, {
      html: this.$refs.tooltip.firstChild,
      trigger: 'click',
      distance: -5,
      placement: 'bottom-end',
      offset: '0,8',
      interactive: true,
      theme: 'light',
      animation: 'scale',
      inertia: true,
      arrow: true,
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },

  methods: {
    hideTippy() {
      this.tippy.hide()
    },

    modalShortcut() {
      this.hideTippy()
      this.$modal.show('shortcut-modal')
    },

    modalHelp() {
      this.hideTippy()

      this.$router.push(
        {
          query: {
            ...this.$route.query,
            help: 'true',
          },
        },
        () => {}
      )
    },

    signout() {
      this.hideTippy()

      this.logout()
    },
  },
}
</script>
