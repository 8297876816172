import Days from '@/models/overtime/Days'
import Hours from '@/models/overtime/Hours'

export default class Breakdowns {
  constructor(breakdowns) {
    this.mapBreakdowns(breakdowns)
  }

  mapBreakdowns(breakdowns) {
    this.breakdowns = breakdowns.map(breakdown => {
      if (breakdown.unit === 'days') {
        return new Days(breakdown.date, breakdown.amount)
      }

      return new Hours(breakdown.date, breakdown.amount)
    })
  }
}
