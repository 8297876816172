<template>
  <BaseFooter>
    <div
      slot-scope="{
        privacyUrl,
        termsUrl,
        cookieUrl,
        currentYear,
        helpCenterUrl,
      }"
      class="tw-w-full"
    >
      <div
        class="tw-flex tw-justify-between tw-items-center tw-flex-wrap tw-px-4 tw-my-1"
      >
        <span
          class="tw-text-gray-900 tw-text-sm tw-leading-loose sm:tw-leading-normal"
        >
          Copyright &#169; {{ currentYear }} Leave Dates. All Rights Reserved.
        </span>

        <img
          src="./../assets/img/leavedates-whoop-strapline-horizontal.svg"
          class="leavedates-whoop-strapline-horizontal"
        />

        <div class="tw-text-sm tw-space-x-8">
          <BaseLink
            theme="darkerGray"
            class="tw-leading-loose sm:tw-leading-normal"
            :href="helpCenterUrl"
          >
            Help
          </BaseLink>

          <BaseLink
            theme="darkerGray"
            class="tw-leading-loose sm:tw-leading-normal"
            :href="cookieUrl"
          >
            Cookie Policy
          </BaseLink>

          <BaseLink
            theme="darkerGray"
            class="tw-leading-loose sm:tw-leading-normal"
            :href="termsUrl"
          >
            Our Terms of Use
          </BaseLink>

          <BaseLink
            theme="darkerGray"
            class="tw-leading-loose sm:tw-leading-normal"
            :href="privacyUrl"
          >
            Privacy
          </BaseLink>
        </div>
      </div>
    </div>
  </BaseFooter>
</template>

<script>
import BaseFooter from '@/components/BaseFooter'
import BaseLink from '@/components/BaseLink'

export default {
  name: 'AppFooter',

  components: { BaseFooter, BaseLink },
}
</script>
<style scoped>
.leavedates-whoop-strapline-horizontal {
  height: 28px;
}
</style>
