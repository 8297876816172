<template>
  <span
    class="tw-rounded-full tw-flex tw-items-center tw-justify-center tw-w-10 tw-h-10"
    :class="classes.background"
  >
    <SvgIcon :name="name" class="tw-w-5 tw-h-5" :class="classes.content" />
  </span>
</template>

<script>
const THEMES = {
  default: {
    background: ['tw-bg-gray-103'],
    content: ['tw-text-gray-660'],
  },

  gray: {
    background: ['tw-bg-gray-103'],
    content: ['tw-text-gray-660'],
  },
}

export default {
  name: 'BackgroundIcon',

  props: {
    theme: {
      type: String,
      default: 'default',
    },

    name: {
      type: String,
      default: 'check',
    },
  },

  computed: {
    classes() {
      return THEMES[this.theme]
    },
  },
}
</script>
