<template>
  <BaseAlert title="Pending approval" class="tw-block" theme="warning">
    {{ pendingText }}
  </BaseAlert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
import { Employments } from '@/api'
import OvertimeRequestedEvent from '@/components/requests/overtime/events/OvertimeRequestedEvent'
import OvertimeUpdatedEvent from '@/components/requests/overtime/events/OvertimeUpdatedEvent'
import Employment from '@/models/employment/Employment'

export default {
  name: 'RequestedAlert',

  components: { BaseAlert },

  props: {
    overtime: {
      type: Object,
      required: true,
    },

    event: {
      type: [OvertimeRequestedEvent, OvertimeUpdatedEvent],
      required: true,
    },
  },

  data() {
    return {
      overtimeOwner: null,
    }
  },

  computed: {
    pendingText() {
      if (this.overtimeOwner) {
        return `
          Overtime request was sent to
          ${this.overtimeOwner.superiorNames}
          for approval on
          ${this.event._createdAtText(this.activeEmployment)}
        `
      }

      return ''
    },
  },

  created() {
    this.fetchOvertimeOwner()
  },

  methods: {
    async fetchOvertimeOwner() {
      const { data } = await Employments.get(this.overtime.owner_id, {
        company_id: this.activeCompany.id,
      })

      this.overtimeOwner = new Employment(data)
    },
  },
}
</script>
