<template>
  <div class="tw-flex tw-flex-col tw-flex-grow">
    <ScrollableContent>
      <LeaveRequestBreakdown
        v-for="breakdown in breakdowns"
        :key="breakdown.key"
        class="tw-mb-3"
        :breakdown="breakdown"
        :show-custom-duration="showCustomDuration"
        :is-single-employment-request="isSingleEmploymentRequest"
        :employments="employments"
        @duration-selected="
          updatedBreakdown => $emit('duration-selected', updatedBreakdown)
        "
        @show-overlap-leave-details="$emit('show-overlap-leave-details')"
      />
    </ScrollableContent>
    <div class="tw-mt-4">
      <div class="tw-flex tw-flex-col tw-items-end tw-justify-end">
        <PortalTarget name="leave-request-breakdown-total-text" />

        <div class="tw-text-thin tw-text-gray-880">
          {{ leaveTitle }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeaveRequestBreakdown from '@/components/requests/leave/LeaveRequestBreakdown'
import ScrollableContent from '@/components/ScrollableContent'

export default {
  name: 'LeaveRequestBreakdowns',

  components: {
    ScrollableContent,
    LeaveRequestBreakdown,
  },

  props: {
    breakdowns: {
      type: Array,
      required: true,
    },

    leaveType: {
      type: Object,
      default: null,
    },

    isTabsVisible: {
      type: Boolean,
      default: false,
    },

    showCustomDuration: {
      type: Boolean,
      default: true,
    },

    isSingleEmploymentRequest: {
      type: Boolean,
      required: true,
    },

    employments: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    leaveTitle() {
      if (!this.leaveType) {
        return ''
      }

      return this.leaveType.name
    },
  },
}
</script>
