export default {
  dayReadableNumberMonthYear: {
    dateOnly: true,
    us: 'MMMM DD YYYY',
    row: 'DD MMMM YYYY',
  },

  numberDayNumberMonthYearWithSlashAndReadableDay: {
    dateOnly: true,
    us: 'MM/DD/YYYY ddd',
    row: 'DD/MM/YYYY ddd',
  },

  formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash: {
    dateOnly: true,
    us: 'MM/DD/YYYY',
    row: 'DD/MM/YYYY',
  },

  formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithDash: {
    dateOnly: true,
    us: 'MM-DD-YYYY',
    row: 'DD-MM-YYYY',
  },

  dayReadableDayNumberShortMonthYear: {
    dateOnly: true,
    us: 'ddd MMM DD YYYY',
    row: 'ddd DD MMM YYYY',
  },

  dayReadableShortDayNumberShortMonthYear: {
    dateOnly: true,
    us: 'MMM DD YYYY',
    row: 'DD MMM YYYY',
  },

  dayReadableShortDayNumberShortMonth: {
    dateOnly: true,
    us: 'MMM DD',
    row: 'DD MMM',
  },

  dayReadableShortDayNumberWithPlaceMonth: {
    dateOnly: true,
    us: 'MMMM Do',
    row: 'Do MMMM',
  },

  shortDateHumanReadable: {
    dateOnly: false,
    us: {
      in24Hour: 'MMM DD YYYY HH:mm',
      in12Hour: 'MMM DD YYYY h:mma',
    },
    row: {
      in24Hour: 'DD MMM YYYY HH:mm',
      in12Hour: 'DD MMM YYYY h:mma',
    },
  },

  shortDateHumanReadableWithAt: {
    dateOnly: false,
    us: {
      in24Hour: 'MMM DD YYYY [at] HH:mm',
      in12Hour: 'MMM DD YYYY [at] h:mma',
    },
    row: {
      in24Hour: 'DD MMM YYYY [at] HH:mm',
      in12Hour: 'DD MMM YYYY [at] h:mma',
    },
  },

  hourMinutes: {
    dateOnly: false,
    us: {
      in24Hour: 'HH:mm',
      in12Hour: 'h:mma',
    },
    row: {
      in24Hour: 'HH:mm',
      in12Hour: 'h:mma',
    },
  },
}
