import OvertimeEvent from '@/components/requests/overtime/events/OvertimeEvent'

export default class OvertimeCancelledEvent extends OvertimeEvent {
  constructor(event) {
    super(event)
  }

  asNotice(viewer) {
    return `
        Overtime request was cancelled by
        ${this.getActorName(viewer)}
        on
        ${this._createdAtText(viewer)}.
      `
  }

  asText(viewer) {
    return `
      **${this.getActorName(viewer)}**
      cancelled the request
    `
  }
}
