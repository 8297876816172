<template>
  <div aria-label="Employment leave allowances table">
    <div
      class="tw-border tw-border-b-0 tw-border-gray-220 tw-flex tw-rounded-t-lg tw-bg-gray-101"
    >
      <div
        class="tw-px-3 tw-py-4 tw-flex-grow tw-text-gray-440 tw-font-medium"
        style="width: 44%;"
      >
        Employee
      </div>

      <div
        class="tw-px-3 tw-py-4 tw-flex-grow tw-text-gray-440 tw-font-medium"
        style="width: 19%;"
      >
        Current
      </div>

      <div
        class="tw-px-3 tw-py-4 tw-flex-grow tw-text-gray-440 tw-font-medium"
        style="width: 19%;"
      >
        New
      </div>

      <div
        class="tw-px-3 tw-py-4 tw-flex-grow tw-text-gray-440 tw-font-medium"
        style="width: 22%;"
      >
        Change
      </div>
    </div>

    <div
      class="tw-border tw-border-gray-220 tw-divide-y tw-divide-gray-220 tw-rounded-b-lg tw-bg-white"
      data-cy="table-emp-overtime-allowances-data"
    >
      <template v-if="overtimeAllowances.length">
        <EmploymentOvertimeAllowancesTableRow
          v-for="allowance in overtimeAllowances"
          :key="allowance.employment.getKey()"
          :overtime-allowance="allowance"
        />
      </template>

      <div v-else class="tw-flex tw-items-center tw-justify-center">
        <NoData message="No employee allowances available" />
      </div>
    </div>
  </div>
</template>

<script>
import NoData from '@/components/NoData.vue'
import EmploymentOvertimeAllowancesTableRow from '@/components/requests/overtime/EmploymentOvertimeAllowancesTableRow'

export default {
  name: 'EmploymentOvertimeAllowancesTable',

  components: {
    NoData,
    EmploymentOvertimeAllowancesTableRow,
  },

  props: {
    overtimeAllowances: {
      type: Array,
      required: true,
    },
  },
}
</script>
