<template>
  <button
    class="tw-flex tw-items-center tw-justify-center tw-font-medium tw-leading-default tw-transition tw-transition-all tw-ease-in-out tw-duration-500 tw-rounded-lg"
    :class="classes"
    :data-cy="cypressAttribute"
    type="button"
    @click.stop="$emit('click')"
  >
    <SvgIcon
      v-if="leadingIcon"
      :name="leadingIcon"
      class="tw-w-4 tw-h-4 tw-mr-2"
    />

    <slot></slot>

    <SvgIcon
      v-if="trailingIcon"
      :name="trailingIcon"
      class="tw-w-4 tw-h-4 tw-ml-2"
    />
  </button>
</template>

<script>
const THEMES = {
  default: ['btn-blue tw-text-white'],

  white: ['btn-white tw-text-770'],

  indigo: ['btn-indigo tw-text-white'],
}

const SIZES = {
  default: ['tw-px-6', 'tw-py-3', 'tw-text-base'],

  sm: ['tw-px-3', 'tw-py-2', 'tw-text-sm'],
}

export default {
  name: 'BaseButton',

  props: {
    theme: {
      type: String,
      default: 'default',
    },

    size: {
      type: String,
      default: 'default',
    },

    leadingIcon: {
      type: String,
      default: '',
    },

    trailingIcon: {
      type: String,
      default: '',
    },
    cypressAttribute: {
      type: String,
      default: 'btn-base',
    },
  },

  computed: {
    themeClasses() {
      return THEMES[this.theme]
    },

    sizeClasses() {
      return SIZES[this.size]
    },

    classes() {
      return [...this.themeClasses, ...this.sizeClasses]
    },
  },
}
</script>
