<template>
  <button
    class="modal-close"
    data-cy="btn-close-modal"
    @click.stop="$emit('click')"
  >
    <SvgIcon name="close" class="tw-w-5 tw-h-5 tw-text-gray-555" />
  </button>
</template>

<script>
export default {
  name: 'CloseButton',
}
</script>
