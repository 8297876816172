import Vue from 'vue'
import Vuex from 'vuex'
import types from './mutation-types'
import createMutationsSharer from 'vuex-shared-mutations'

Vue.use(Vuex)

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/)

const modules = requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true
    }

    return { ...modules, [name]: module }
  }, {})

function resetState(state, moduleState) {
  const mState = state[moduleState]

  if (mState.initialState && typeof mState.initialState === 'function') {
    const initialState = mState.initialState()

    for (const key in initialState) {
      mState[key] = initialState[key]
    }
  }
}

export default new Vuex.Store({
  modules,

  plugins: [
    createMutationsSharer({
      predicate: [
        'resetStates',
        'auth/setAuthUser',
        'notifications/SET_NOTIFICATIONS',
      ],
    }),
  ],

  mutations: {
    [types.RESET_STATES](state) {
      for (const moduleState in state) {
        resetState(state, moduleState)
      }
    },
  },

  actions: {
    resetStates(context) {
      context.commit(types.RESET_STATES)
    },
  },
})
