<template>
  <Modal
    :classes="[
      'tw-shadow-md',
      'tw-bg-white',
      'tw-rounded-lg',
      'modal-overflow-visible',
    ]"
    :max-width="750"
    name="shortcut-modal"
    width="90%"
    height="auto"
    adaptive
    scrollable
  >
    <div class="tw-relative">
      <button
        class="tw-m-4 tw-opacity-50 hover:tw-opacity-100 modal-close tw-absolute tw-right-0"
        @click="$modal.hide('shortcut-modal')"
      >
        <SvgIcon name="close" class="tw-w-4 tw-h-4" />
      </button>
    </div>

    <div class="tw-p-6">
      <h2 class="tw-mb-6">Keyboard Shortcuts</h2>

      <div class="md:tw-flex tw-text-gray-700">
        <div class="tw-w-full tw-flex-1">
          <h4 class="tw-mb-4 tw-mt-4 tw-text-gray-700">Wall Chart</h4>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center tw-font-semibold"
              style="width: 26px;"
              >&#60;</span
            >
            <span>Switch calendar to previous month</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center tw-font-semibold"
              style="width: 26px;"
              >&#62;</span
            >
            <span>Switch calendar to next month</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 26px;"
              >d</span
            >
            <span>Day view</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 26px;"
              >w</span
            >
            <span>Week view</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 26px;"
              >m</span
            >
            <span>Month view</span>
          </div>
        </div>
        <div class="tw-w-full tw-flex-1">
          <h4 class="tw-mb-4 tw-mt-4 tw-text-gray-700">
            Main Menu Navigation
          </h4>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+1</span
            ><span>Summary</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+2</span
            ><span>Wall Chart</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+3</span
            ><span>My Year</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+4</span
            ><span>Reports</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >alt+n</span
            ><span>My Notifications</span>
          </div>
        </div>
      </div>

      <div class="md:tw-flex tw-text-gray-700">
        <div class="tw-w-full tw-flex-1">
          <h4 class="tw-mb-4 tw-mt-4 tw-text-gray-700">
            Settings Menu Navigation
          </h4>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+s</span
            ><span>Company</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+d</span
            ><span>Departments</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+a</span
            ><span>Allowances</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+l</span
            ><span>Leave Types</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+h</span
            ><span>Holidays</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+e</span
            ><span>Employees</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+c</span
            ><span>Calendars</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+m</span
            ><span>Limits</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+i</span
            ><span>Data Import</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+t</span
            ><span>Integrations</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >shift+b</span
            ><span>Billing/Upgrade</span>
          </div>
        </div>
        <div class="tw-w-full tw-flex-1">
          <h4 class="tw-mb-4 tw-mt-4 tw-text-gray-700">
            General Shortcuts
          </h4>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >alt+c</span
            ><span>Manage Companies</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >alt+p</span
            ><span>User Profile</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >alt+i</span
            ><span>Shortcuts</span>
          </div>
          <div class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center">
            <span
              class="tw-font-semibold tw-text-gray-800 tw-rounded tw-mr-4 tw-px-2 tw-py-1 bg-gradient-200 tw-border tw-border-gray-400 tw-text-center"
              style="width: 60px;"
              >alt+h</span
            ><span>Help</span>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'KeyboardShortcuts',
}
</script>
