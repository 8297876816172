<template>
  <div
    class="tw-px-8 tw-py-4 tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-350"
    data-cy="panel-header"
  >
    <slot></slot>

    <CloseButton @click="$emit('close-panel')" />
  </div>
</template>

<script>
import CloseButton from '@/components/CloseButton'

export default {
  name: 'PanelHeader',

  components: { CloseButton },
}
</script>
