import { Company } from '@/api'

export default class CalendarSettings {
  static async update(company, params) {
    return await Company.update(company, { calendar_settings: params })
  }

  static async delete(company) {
    return await Company.update(company, {
      calendar_settings: {
        manage_automatically: false,
      },
    })
  }
}
