import fetch from 'unfetch'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import Vue from 'vue'
import {
  HTTP_STATUS_AUTHENTICATION_EXPIRED,
  HTTP_STATUS_UNAUTHORIZED,
} from './http'

// XSRF token is required to make post requests to your Laravel backend
const authLink = setContext((_, { headers }) => {
  let token = RegExp('XSRF-TOKEN[^;]+').exec(document.cookie)
  token = decodeURIComponent(
    token ? token.toString().replace(/^[^=]+./, '') : ''
  )

  return {
    headers: {
      ...headers,
      'x-requested-with': 'XMLHttpRequest',
      'X-XSRF-TOKEN': token,
    },
  }
})

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_API_URL + '/graphql', // The graphql endpoint url
  credentials: 'include',
  fetch,
})

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    switch (networkError.response.status) {
      case HTTP_STATUS_UNAUTHORIZED:
      case HTTP_STATUS_AUTHENTICATION_EXPIRED:
        window.location = '/signin?reason=session_timeout'
    }
  }
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache,
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Vue.use(VueApollo)

export default apolloProvider
