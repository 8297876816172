import http from '@/plugins/http'

export default class Attachments {
  static save(payload) {
    return http.post(`leave-attachments`, payload, {
      'Content-Type': 'multipart/form-data',
    })
  }

  static async download(attachment, params) {
    return await http.get(`attachments/${attachment.id}`, {
      params: params,
      responseType: 'blob',
    })
  }

  static async upload(company, attachment, callback) {
    try {
      const signedURL = await this.getSignedURL(company.id)

      attachment = { ...attachment, id: signedURL.uuid }

      await http.put(signedURL.url, attachment.file, {
        headers: {
          'Content-Type': attachment.file.type,
        },
        onUploadProgress: progressEvent => callback(attachment, progressEvent),
      })

      return attachment
    } catch (e) {
      throw attachment
    }
  }

  static async getSignedURL(companyKey) {
    const { data } = await http.post('signed-storage-url', {
      company: companyKey,
    })

    return data
  }

  static async delete(attachment, params) {
    await http.delete(`attachments/${attachment.id}`, {
      params: params,
    })
  }
}
