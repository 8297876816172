<template>
  <div
    class="tw-text-lg tw-font-semibold tw-text-gray-880"
    data-cy="leave-text"
  >
    {{ durationText }}
    <span
      v-if="showTooltip"
      ref="durationTooltipIcon"
      class="tw-cursor-default"
    >
      <SvgIcon name="info-circle" class="tw-w-5 tw-h-5 tw-text-purple-500" />
    </span>

    <div v-if="showTooltip" v-show="false" ref="durationTooltip">
      <div class="tw-p-3 tw-w-48 tw-text-center">
        Duration for a multi-person leave request ignores schedules, and can be
        different for each employee.
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import RequestDuration from '@/components/requests/RequestDuration'

export default {
  name: 'LeaveRequestBreakdownTotalText',
  props: {
    minutesPerWorkingDay: {
      type: Number,
      required: true,
    },

    totalDurationsInUnits: {
      type: Object,
      required: true,
    },

    showTooltip: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    durationText() {
      const leaveDuration = {
        days: this.totalDurationsInUnits.days,
        hours: this.totalDurationsInUnits.hours,
        minutes: this.totalDurationsInUnits.minutes,
      }

      return new RequestDuration(leaveDuration).toString('Total: ')
    },
  },

  mounted() {
    if (this.showTooltip) {
      let tooltip = tippy.one(this.$refs.durationTooltipIcon, {
        html: this.$refs.durationTooltip.firstChild,
        theme: 'dark',
        arrow: true,
        interactive: true,
        placement: 'top',
        delay: [400, 0],
        distance: 1,
        interactiveBorder: 15,
      })

      this.$once('hook:beforeDestroy', () => {
        tooltip.destroy()
      })
    }
  },
}
</script>
