<template>
  <div
    class="tw-p-4 tw-space-x-4 tw-flex tw-items-center tw-border tw-border-gray-350 tw-rounded-lg tw-bg-gray-200"
    :class="attachmentClasses"
    data-cy="attachment"
  >
    <SvgIcon
      v-if="hasError"
      name="unknown"
      class="tw-text-red-900"
      :class="fileIconClasses"
    />

    <SvgIcon
      v-if="!hasError"
      :name="fileIcon"
      class="tw-text-gray-800"
      :class="fileIconClasses"
    />

    <div class="tw-flex-1 tw-space-y-2 tw-overflow-hidden">
      <div v-if="hasError">
        <div
          class="tw-text-lg tw-truncate tw-text-red-900 tw-font-medium tw-cursor-default"
          title="Upload failed, please try again"
        >
          Upload failed, please try again
        </div>
      </div>

      <div class="tw-flex tw-items-center">
        <div
          class="tw-mr-3 tw-flex-1 tw-text-lg tw-truncate"
          :class="attachmentNameClasses"
          title=""
          data-cy="attachment-name"
        >
          {{ attachment.name }}
        </div>

        <div
          v-if="isActive && successfullyUploaded"
          class="tw-w-5 tw-h-5 tw-flex tw-items-center tw-justify-center tw-rounded-full"
        >
          <SvgIcon class="tw-text-green-500" name="check-circle-solid" />
        </div>
      </div>

      <div v-if="hasError">
        <button
          class="tw-flex tw-text-red-900"
          type="button"
          title="Try again"
          @click="$emit('retry-upload')"
        >
          Try again
        </button>
      </div>

      <div v-if="isActive" class="tw-flex tw-items-center">
        <div class="tw-mr-3 tw-flex-1">
          <div class="tw-w-full tw-bg-gray-350 tw-rounded-full tw-h-2">
            <div
              class="tw-bg-green-500 tw-h-2 tw-rounded-full"
              :style="`width: ${attachment.progress}%`"
            ></div>
          </div>
        </div>

        <div>{{ uploadingProgress }}%</div>
      </div>

      <div
        v-if="!isActive && !hasError && canDownload && attachment.creator"
        class="tw-flex tw-items-center tw-text-gray-700"
      >
        <UserAvatar class="tw-mr-2" :user="attachment.creator" />

        {{ creatorName }} on

        <AttachmentDate class="tw-ml-1" :date="createdDate" />
      </div>
    </div>

    <div v-if="!isActive" class="tw-flex tw-items-center tw-space-x-5">
      <button
        v-if="!hasError && canDownload"
        class="tw-flex items-center tw-justify-center"
        type="button"
        title="Download"
        data-cy="download-attachment"
        @click="downloadAttachment"
      >
        <SvgIcon class="tw-w-5 tw-h-5 tw-text-gray-800" name="download" />
      </button>

      <button
        v-if="canDelete"
        class="tw-flex items-center tw-justify-center"
        type="button"
        data-cy="delete-attachment"
        @click="$emit('delete-attachment')"
      >
        <SvgIcon class="tw-w-5 tw-h-5 tw-text-gray-800" name="bin" />
      </button>
    </div>
  </div>
</template>

<script>
import prettyFileIcons from 'pretty-file-icons'
import FileSaver from 'file-saver'
import ValidatesForm from '@/mixins/ValidatesForm'
import moment from 'moment-timezone'
import AttachmentDate from '@/components/AttachmentDate'
import FormatNumbers from '@/mixins/FormatNumbers'

export default {
  name: 'BaseAttachment',

  components: { AttachmentDate },

  mixins: [ValidatesForm, FormatNumbers],

  props: {
    attachment: {
      type: Object,
      required: true,
    },

    canDownload: {
      type: Boolean,
      default: false,
    },

    canDelete: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    attachmentClasses() {
      if (this.hasError) {
        return ['tw-bg-red-100 tw-border-red-300']
      }

      if (this.isActive && this.successfullyUploaded) {
        return ['tw-bg-gray-200 tw-border-green-300']
      }

      return ['tw-bg-gray-200 tw-border-gray-350']
    },

    attachmentNameClasses() {
      if (this.hasError) {
        return ['tw-text-red-500']
      }

      return ['tw-text-gray-900']
    },

    fileIcon() {
      return prettyFileIcons.getIcon(this.attachment.name)
    },

    fileIconClasses() {
      if (this.canDownload) {
        return ['tw-w-8 tw-h-8']
      }

      return ['tw-w-6 tw-h-6']
    },

    createdDate() {
      return moment(this.attachment.updated_at)
    },

    uploadingProgress() {
      return this.toFixed(Number(this.attachment.progress))
    },

    successfullyUploaded() {
      return this.attachment.progress === 100
    },

    isActive() {
      return !!this.attachment.active
    },

    hasError() {
      return !!this.attachment.error
    },

    creatorName() {
      if (this.creatorIsActiveEmployment) {
        return 'You'
      }

      return this.attachment.creator.full_name
    },

    creatorIsActiveEmployment() {
      return this.attachment.creator.id === this.activeEmployment.id
    },
  },

  methods: {
    async downloadAttachment() {
      try {
        const { data } = await this.$http.get(
          `attachments/${this.attachment.id}`,
          {
            params: this.$route.query,
            responseType: 'blob',
          }
        )

        FileSaver.saveAs(
          new Blob([data], { type: this.attachment.mime_type }),
          this.attachment.name
        )
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },
  },
}
</script>
