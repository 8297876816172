import v from 'voca'

export default {
  methods: {
    fullName(employment) {
      return v.capitalize(employment.full_name)
    },

    compactFullName(employment) {
      const fullName = employment.full_name

      if (v.count(fullName) > 13) {
        return v(fullName)
          .words()
          .map(function(word, index) {
            if (index == 0) return word
            return v(word)
              .first()
              .capitalize()
          })
          .join(' ')
      }

      return v.capitalize(fullName)
    },
  },
}
