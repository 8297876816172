export default class RequestMicrocopy {
  constructor(draftRequest, requester) {
    this.owners = draftRequest.map(request => request.owner)
    this.requester = requester

    this._setSuperiors()
  }

  _setSuperiors() {
    let superiors = []
    for (const owner of this.owners) {
      for (let approver of owner.approvers) {
        if (!superiors.some(e => e.id === approver.id)) {
          superiors.push(approver)
        }
      }
    }

    this.superiors = superiors
  }

  _canRequestBeAutomaticallyApproved() {
    return (
      (this.requester.isAdmin() && this._isSolelySupervisedByRequester()) ||
      (this.requester.isApprover() && this._isRequesterSuperiorOfEveryOwner())
    )
  }

  _isRequesterSuperiorOfEveryOwner() {
    return this.owners.every(owner =>
      owner.approvers.some(approver => approver.id === this.requester.id)
    )
  }

  _isRequesterIsSuperiorOfSomeOwners() {
    return this.superiors.some(superior => superior.id === this.requester.id)
  }

  _superiorNames() {
    const lf = new Intl.ListFormat('en', { style: 'short' })

    return lf.format(this.superiors.map(s => s.full_name))
  }

  _isSolelySupervisedByRequester() {
    return this.owners.every(
      owner =>
        owner.approvers.length === 1 &&
        owner.approvers[0].id === this.requester.id
    )
  }
}
