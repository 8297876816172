import Notification from '@/components/notifications/Notification'

export default class ImportNotification extends Notification {
  constructor(notification) {
    super(notification)
  }

  // eslint-disable-next-line no-unused-vars
  navigate(router, query) {}

  getMessage() {
    return this.notification.data.message
  }

  getRecordsCount() {
    return this.notification.data.imported
  }

  getFailuresCount() {
    return this.notification.data.failures
  }

  getContent() {
    return this.notification.data.content
  }

  getType() {
    return 'import'
  }
}
