import OvertimeEvent from '@/components/requests/overtime/events/OvertimeEvent'

export default class OvertimeRequestedEvent extends OvertimeEvent {
  constructor(event) {
    super(event)
  }

  asText(viewer) {
    if (this.viewerIsActor(viewer) || this.actorIsOwner()) {
      return `
        **${this.getActorName(viewer)}**
        requested overtime
      `
    }

    return `
      **${this.getActorName(viewer)}**
      requested overtime on behalf of ${this._getOwnerName(viewer)}
    `
  }
}
