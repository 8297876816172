<template>
  <div class="tw-flex tw-flex-col tw-flex-1">
    <Loading
      class="tw-outline-none"
      :blur="null"
      loader="dots"
      :is-full-page="false"
      :active="isProcessing"
    />

    <div v-if="overtime && !loading" class="tw-flex tw-flex-col tw-flex-1">
      <PanelHeader @close-panel="closePanel">
        <div class="tw-flex tw-items-center">
          <BackgroundIcon name="clock-outline" />

          <span class="tw-font-medium tw-text-xl tw-ml-4">
            Overtime Request for

            <MyYearPageLink :employee="overtime.owner" :period="overtimePeriod">
              {{ overtime.owner.full_name }}
            </MyYearPageLink>
          </span>

          <RequestStatusLabel :status="overtime.status" class="tw-ml-3" />
        </div>
      </PanelHeader>

      <TabsWrapper
        ref="tabs"
        class="tw-px-8 tw-pt-5 tw-overflow-y-auto"
        @changed="handleTabChange"
      >
        <BaseTab
          id="summary"
          name="Summary"
          :is-large-footer-visible="shouldShowApprovedAlert"
        >
          <OvertimeSummaryTab
            :overtime="overtime"
            :timeline="timeline"
            :employment-allowances="employmentAllowances"
            :show-edit-button="canEditOvertime"
            :is-allowance-calculating="isProcessing"
            @comment-added="fetchOvertimeTimelineEvents"
            @show-more-details="changeTab('#updates')"
            @update-overtime-breakdown="updateOvertime"
          />
        </BaseTab>

        <BaseTab
          v-if="overtime.areAttachmentsVisible()"
          id="attachments"
          name="Attachments"
          :suffix="attachmentsSuffix"
          :is-large-footer-visible="shouldShowApprovedAlert"
        >
          <template #header>
            <RequestTabHeader header="Attachments" :count="attachmentsCount" />
          </template>

          <OvertimeAttachmentsTab
            :overtime="overtime"
            :unuploaded-attachments="unuploadedAttachments"
            :uploaded-attachments="uploadedAttachments"
            @save-attachment="saveAttachment"
            @attachment-removed="fetchOvertimeAndTimelineEvents"
            @attachment-count-decreased="attachmentsCount--"
          />
        </BaseTab>

        <BaseTab
          id="updates"
          name="Updates"
          :suffix="updatesSuffix"
          :is-large-footer-visible="shouldShowApprovedAlert"
        >
          <template #header>
            <RequestTabHeader header="Updates" :count="eventsCount" />
          </template>

          <OvertimeUpdatesTab
            v-if="hasTimeline"
            :overtime="overtime"
            :timeline="timeline"
            :tab-active="shouldScrollTimelineToBottom"
            @comment-added="fetchOvertimeTimelineEvents"
            @comment-deleted="fetchOvertimeTimelineEvents"
            @comment-restored="fetchOvertimeTimelineEvents"
          />
        </BaseTab>
      </TabsWrapper>

      <PanelFooter>
        <Component
          :is="alertComponent"
          v-if="alertComponent"
          class="tw-mb-4"
          :overtime="overtime"
          :event="timeline.getLatestOvertimeEvent()"
        />

        <div class="tw-flex tw-items-center tw-justify-end tw-mb-2">
          <SpinnerButton
            v-if="canApproveOvertime"
            :disabled="isProcessing"
            :loading="rejecting"
            :spinner-only="true"
            theme="red"
            type="button"
            data-cy="btn-decline-overtime"
            @click="rejectOvertime"
          >
            Decline
          </SpinnerButton>

          <SpinnerButton
            v-if="canApproveOvertime"
            :disabled="isProcessing"
            :loading="approving"
            :spinner-only="true"
            :spinner-classes="['tw-h-2 tw-w-2 tw-text-green-500']"
            class="tw-ml-3"
            theme="green"
            type="button"
            data-cy="btn-approve-overtime"
            @click="approveOvertime"
          >
            Approve
          </SpinnerButton>

          <SpinnerButton
            v-if="!canApproveOvertime && canCancelOvertime"
            :disabled="isProcessing"
            :loading="cancelling"
            :spinner-only="true"
            theme="red"
            type="button"
            data-cy="btn-cancel-overtime"
            @click="cancelOvertime"
          >
            Cancel Request
          </SpinnerButton>
        </div>

        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="!loading"
          class="tw-text-right tw-text-sm tw-text-gray-555"
          data-cy="micro-copy-text"
          v-html="microcopyText"
        />
        <!-- eslint-enable -->
      </PanelFooter>
    </div>
  </div>
</template>

<script>
import EventBus from '@/plugins/event-bus'
import BaseTab from '@/components/BaseTab'
import SpinnerButton from '@/components/SpinnerButton'
import PanelHeader from '@/components/PanelHeader'
import PanelFooter from '@/components/PanelFooter'
import TabsWrapper from '@/components/TabsWrapper'
import BackgroundIcon from '@/components/BackgroundIcon'
import OvertimeSummaryTab from '@/components/requests/overtime/OvertimeSummaryTab'
import OvertimeAttachmentsTab from '@/components/requests/overtime/OvertimeAttachmentsTab'
import OvertimeUpdatesTab from '@/components/requests/overtime/OvertimeUpdatesTab'
import ValidatesForm from '@/mixins/ValidatesForm'
import Loading from 'vue-loading-overlay'
import OvertimePermissions from '@/components/requests/overtime/mixins/OvertimePermissions'
import { AllowanceReport, Overtimes } from '@/api'
import RequestedAlert from '@/components/requests/overtime/RequestedAlert'
import ApprovedAlert from '@/components/requests/overtime/ApprovedAlert'
import CancelledAlert from '@/components/requests/overtime/CancelledAlert'
import DeclinedAlert from '@/components/requests/overtime/DeclinedAlert'
import RequestStatusLabel from '@/components/requests/RequestStatusLabel'
import MyYearPageLink from '@/components/MyYearPageLink'
import { omit } from 'lodash-es'
import RequestTabHeader from '@/components/requests/RequestTabHeader'
import OvertimeDetailsMicrocopy from '@/components/requests/overtime/OvertimeDetailsMicrocopy'
import CancelConfirmation from '@/components/requests/leave/CancelConfirmation'

export default {
  name: 'OvertimeDetailsPanel',

  components: {
    RequestStatusLabel,
    MyYearPageLink,
    Loading,
    BaseTab,
    SpinnerButton,
    PanelHeader,
    PanelFooter,
    TabsWrapper,
    BackgroundIcon,
    OvertimeSummaryTab,
    OvertimeAttachmentsTab,
    OvertimeUpdatesTab,
    RequestTabHeader,
  },

  mixins: [ValidatesForm, OvertimePermissions],

  props: {
    overtimeKey: {
      type: String,
      required: true,
    },

    activeTab: {
      type: String,
      default: '#summary',
    },
  },

  data() {
    return {
      overtime: null,
      timeline: null,
      calendars: [],
      employmentAllowances: null,
      loading: true,
      approving: false,
      cancelling: false,
      rejecting: false,
      updating: false,
      shouldScrollTimelineToBottom: false,
      attachmentsCount: 0,
      unuploadedAttachments: [],
      uploadedAttachments: [],
    }
  },

  computed: {
    isProcessing() {
      return (
        this.loading ||
        this.approving ||
        this.cancelling ||
        this.rejecting ||
        this.updating
      )
    },

    microcopyText() {
      const text = new OvertimeDetailsMicrocopy(
        this.overtime,
        this.activeEmployment,
        this.activeCompany
      ).microcopy.split('**')

      if (!text.length) {
        return ''
      }

      let microcopy = text[0]

      if (text[1]) {
        microcopy += `<span class="tw-text-gray-770 tw-font-medium">${text[1]}</span>`
      }

      return microcopy
    },

    overtimePeriod() {
      return this.overtime.getPeriod()
    },

    hasTimeline() {
      return this.timeline
    },

    shouldShowApprovedAlert() {
      return this.overtime.isApproved && !!this.timeline?.getApprovedEvent()
    },

    alertComponent() {
      const alertsMap = {
        isApproved: ApprovedAlert,
        isCancelled: CancelledAlert,
        isDeclined: DeclinedAlert,
      }

      if (this.isProcessing || !this.hasTimeline) {
        return null
      }

      if (this.overtime.isRequested && !this.canApproveOvertime) {
        return RequestedAlert
      }

      const alertState = Object.keys(alertsMap).find(
        state => this.overtime[state]
      )

      return alertState ? alertsMap[alertState] : null
    },

    attachmentsSuffix() {
      return `<span class='${this.suffixClasses}'>${this.attachmentsCount}</span>`
    },

    updatesSuffix() {
      return `<span class='${this.suffixClasses}'>${this.timeline?.eventCount}</span>`
    },

    suffixClasses() {
      return 'tw-text-sm tw-bg-gray-300 tw-ml-2 tw-py-1 tw-px-2 tw-rounded-full md:tw-inline-block'
    },

    eventsCount() {
      return this.timeline ? this.timeline.events.length : 0
    },
  },

  watch: {
    overtime(overtime) {
      this.attachmentsCount = overtime.attachments?.length
    },
  },

  async created() {
    await this.fetchOvertimeAndTimelineAndAllowances()
    this.loading = false

    this.changeTab(this.activeTab)
  },

  methods: {
    async fetchOvertimeAndTimelineAndAllowances() {
      await this.fetchOvertime()

      await Promise.all([
        this.fetchOvertimeTimelineEvents(),
        this.fetchEmploymentAllowances(),
      ])
    },

    closePanel() {
      this.$router.push({
        query: {
          ...omit(this.$route.query, ['overtime']),
        },
      })
    },

    async fetchOvertime() {
      this.overtime = await Overtimes.fetch(
        this.overtimeKey,
        this.activeCompany
      )
    },

    handleTabChange({ tab }) {
      this.shouldScrollTimelineToBottom = tab.hash === '#updates'
    },

    async fetchEmploymentAllowances() {
      try {
        this.employmentAllowances = (
          await AllowanceReport.getDetailsAllowances({
            company_id: this.activeCompany.id,
            period: this.overtime.getPeriod(),
            allowance_type: this.overtime.allowance_type_id,
            employee: this.overtime.owner.id,
            per_page: 1,
          })
        ).items()
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchOvertimeTimelineEvents() {
      this.timeline = await Overtimes.fetchTimeLineEvents(
        this.overtime,
        this.activeCompany.id
      )
    },

    async approveOvertime() {
      this.approving = true

      await Overtimes.approve(this.overtime, this.activeCompany.id)
      await this.fetchOvertimeAndTimelineAndAllowances()

      this.success('Overtime approved successfully.')
      EventBus.$emit('overtime-approved')

      this.approving = false
    },

    async updateOvertime(overtimeBreakdowns) {
      try {
        this.updating = true
        const breakdowns = overtimeBreakdowns.map(breakdown =>
          breakdown.toJson()
        )

        await Overtimes.update(this.overtime, this.activeCompany.id, breakdowns)
        await this.fetchOvertimeAndTimelineAndAllowances()

        this.success('Overtime updated successfully.')
        EventBus.$emit('overtime-updated')
        this.updating = false
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },

    async cancelOvertime() {
      const confirmation = new CancelConfirmation(
        this.overtime,
        this.activeEmployment
      ).message

      const confirmed = await this.confirm(
        confirmation.body,
        confirmation.title
      )

      if (!confirmed) return

      this.cancelling = true

      await Overtimes.cancel(this.overtime, this.activeCompany.id)
      await this.fetchOvertimeAndTimelineAndAllowances()

      this.success('Overtime cancelled successfully.')
      EventBus.$emit('overtime-cancelled')

      this.cancelling = false
    },

    async rejectOvertime() {
      const confirmation = new CancelConfirmation(
        this.overtime,
        this.activeEmployment
      ).message

      const confirmed = await this.confirm(
        confirmation.body,
        confirmation.title
      )

      if (!confirmed) return

      this.rejecting = true

      await Overtimes.cancel(this.overtime, this.activeCompany.id)
      await this.fetchOvertimeAndTimelineAndAllowances()

      this.success('Overtime declined successfully.')
      EventBus.$emit('overtime-rejected')

      this.rejecting = false
    },

    async saveAttachment(attachment) {
      this.attachmentsCount++
      try {
        await Overtimes.saveAttachment(this.overtime.id, {
          company_id: this.activeCompany.id,
          attachment_id: attachment.id,
          file_name: attachment.name,
        })

        this.uploadedAttachments.push(attachment)
      } catch ({ response }) {
        this.unuploadedAttachments.push(attachment)

        this.validateFromResponse(response)
      }

      this.fetchOvertimeAndTimelineEvents()
    },

    changeTab(tabHash) {
      this.$nextTick(() => this.$refs.tabs.selectTab(tabHash))
    },

    fetchOvertimeAndTimelineEvents() {
      this.fetchOvertime()
      this.fetchOvertimeTimelineEvents()
    },
  },
}
</script>
