<template>
  <div class="tw-flex tw-flex-col tw-flex-1 tw-overflow-hidden">
    <ScrollableContent ref="timelineWrapper">
      <div class="tw-space-y-3 tw-overflow-y-auto">
        <section
          v-for="dayEvents in groupedEvents"
          :key="dayEvents.date"
          class="tw-space-y-2"
        >
          <div
            class="tw-mb-4 tw-relative tw-flex tw-items-center tw-justify-end"
          >
            <div
              class="tw-absolute tw-w-full tw-border-t tw-border-gray-103"
            ></div>

            <div
              class="tw-px-3 tw-py-1 tw-font-medium tw-bg-gray-103 tw-rounded-full tw-z-10"
              data-cy="event-date"
            >
              {{ dayEvents.date }}
            </div>
          </div>

          <DynamicTimelineEvent
            v-for="timelineEvent in dayEvents.events"
            :key="timelineEvent.event.id"
            :class="'event' + timelineEvent.event.id"
            :type="type"
            :event="timelineEvent"
            cypress-attribute="event"
            @delete-comment="comment => $emit('delete-comment', comment)"
            @restore-comment="comment => $emit('restore-comment', comment)"
          />
        </section>
      </div>
    </ScrollableContent>
  </div>
</template>

<script>
import DynamicTimelineEvent from '@/components/requests/timeline/DynamicTimelineEvent'
import ScrollableContent from '@/components/ScrollableContent'

export default {
  name: 'TimelineView',

  components: { ScrollableContent, DynamicTimelineEvent },

  props: {
    timeline: {
      type: Object,
      required: true,
    },

    focus: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    groupedEvents() {
      return this.timeline.getEventsGroupedByDate()
    },
  },

  watch: {
    focus: {
      immediate: true,
      handler(value) {
        if (value) {
          this.focusToLastEvent()
        }
      },
    },

    timeline() {
      if (this.focus) {
        this.focusToLastEvent()
      }
    },
  },

  methods: {
    focusToLastEvent() {
      const timeout = setTimeout(() => {
        const lastEl = this.$el.querySelector(
          '.event' + this.timeline.getLastEvent().event.id
        )
        lastEl.scrollIntoView()
      }, 200)

      this.$once('hook:beforeDestroy', () => {
        clearTimeout(timeout)
      })
    },
  },
}
</script>
