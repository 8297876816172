import types from './../mutation-types'
import { Notifications } from '@/api'
import Notification from '@/components/notifications/Notification'

const initialState = {
  list: [],
}

export const state = {
  ...initialState,
  initialState() {
    return initialState
  },
}

export const getters = {
  notifications({ list }) {
    return list
  },

  unreadNotifications(state, { notifications }) {
    return notifications.filter(
      notification =>
        notification instanceof Notification && notification.isUnread()
    )
  },
}

export const mutations = {
  [types.SET_NOTIFICATIONS](state, notifications) {
    const list = notifications.filter(n => n instanceof Notification)

    if (list.length) {
      state.list = list
    }
  },
}

export const actions = {
  async all({ commit }) {
    try {
      const data = await Notifications.getAll()

      commit(types.SET_NOTIFICATIONS, data)
    } catch (e) {
      //
    }
  },

  async markAsRead({ dispatch }, notification) {
    if (notification.isRead()) return

    await Notifications.markAsRead(notification)

    await dispatch('all')
  },

  async markAsUnread({ dispatch }, notification) {
    await Notifications.markAsUnread(notification)

    await dispatch('all')
  },

  async markAllAsRead({ dispatch }) {
    await Notifications.markAllAsRead()

    await dispatch('all')
  },
}
