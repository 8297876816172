import { first, flatMap, omit, orderBy } from 'lodash-es'
import Info from '@/models/leave/drafting/Info'
import Error from '@/models/leave/drafting/Error'
import Warning from '@/models/leave/drafting/Warning'
import LeaveRequest from '@/models/leave/drafting/LeaveRequest'
import moment from 'moment/moment'

export default class SingleEmploymentLeaveRequest {
  constructor({ employments, messages, total_duration: totalDuration }) {
    this._setLeaveRequests(employments)
    this._setMessages(messages)
    this.totalDuration = totalDuration
  }

  _setLeaveRequests(employments) {
    employments = this._mergeOverlapLeaveErrors(employments)

    this.items = employments.map(employment => new LeaveRequest(employment))
  }

  _mergeOverlapLeaveErrors(employments) {
    employments[0].breakdowns.forEach(breakdown =>
      this._mergeOverlapLeaveError(breakdown)
    )

    return employments
  }

  _mergeOverlapLeaveError(breakdown) {
    const overlapErrorIndex = this._findOverlapErrorIndex(breakdown)

    if (overlapErrorIndex !== -1) {
      const leave = breakdown.messages.errors[overlapErrorIndex].leave

      breakdown.messages.errors[overlapErrorIndex] = omit(
        {
          ...breakdown.messages.errors[overlapErrorIndex],
          leaves: [leave],
        },
        'leave'
      )
    }
  }

  _findOverlapErrorIndex(breakdown) {
    return breakdown.messages.errors.findIndex(
      error => error.code === 'overlap_with_existing_leave'
    )
  }

  _setMessages(messages) {
    this._setErrors(messages.errors)
    this._setWarnings(messages.warnings)
    this._setInformation(messages.info)
  }

  _setErrors(errors) {
    this.errors = errors.map(error => new Error(error))
  }

  _setWarnings(warnings) {
    this.warnings = warnings.map(warning => new Warning(warning))
  }

  _setInformation(info) {
    this.information = info.map(info => new Info(info))
  }

  get isSingleRequest() {
    return this.items.length === 1
  }

  get breakdowns() {
    return this.items[0].breakdowns
  }

  get showCustomDuration() {
    return true
  }

  get employments() {
    return this.items.map(draftLeave => draftLeave.owner)
  }

  getBreakdownsToRequest() {
    return this.items.map(draftedLeave => draftedLeave.getBreakdownsToRequest())
  }

  generateBreakdownsToRedraft(pickedBreakdowns) {
    return this._generateBreakdownsForOwnersUsingPickedBreakdowns(
      pickedBreakdowns
    )
  }

  get all() {
    return this.items
  }

  get containPastDates() {
    return this.warnings.some(warning => warning.isPastDates)
  }

  get doesntHaveCalendarToRequestLeave() {
    return this.errors.some(error => error.isCalendarNotSetup)
  }

  get datesOverlapOnDifferentCalendars() {
    return this.errors.some(error => error.isCrossBoundaries)
  }

  get hasRemainingAllowancesExceeded() {
    return this.errors.some(error => error.isAllowanceInsufficient)
  }

  get hasUnlimitedAllowances() {
    return this.information.some(info => info.isAllowanceUnlimited)
  }

  get selectedLeaveTypeDoesntHaveAllowances() {
    return this.information.some(info => info.isAllowanceNotSetupForLeaveType)
  }

  get containsHoursPerWorkingDayExceededWarning() {
    return this.breakdowns.some(breakdown =>
      breakdown.warnings.some(warning => warning.isHoursPerWorkingDayExceeded)
    )
  }

  get containsLeaveLimitReachedWarning() {
    return this.breakdowns.some(breakdown =>
      breakdown.warnings.some(warning => warning.isLeaveLimitReached)
    )
  }

  get overlapWithExistingLeave() {
    return this.breakdowns.some(breakdown =>
      breakdown.errors.some(error => error.isOverlapWithExistingLeave)
    )
  }

  findMinimumFromTime(pickedBreakdowns) {
    const breakdown = this._findMinimumBreakdownTimeUsingBreakdownsForOwners(
      pickedBreakdowns
    )

    return this._makeTime(breakdown.from)
  }

  _findMinimumBreakdownTimeUsingBreakdownsForOwners(pickedBreakdowns) {
    const ownersWithBreakdowns = this._generateBreakdownsForOwnersUsingPickedBreakdowns(
      pickedBreakdowns
    )

    const breakdowns = this._getAllBreakdownsWithoutOwners(ownersWithBreakdowns)

    return first(this._orderBreakdownFromTimeInAscending(breakdowns))
  }

  _orderBreakdownFromTimeInAscending(breakdowns) {
    return this._orderBreakdowns(breakdowns, 'from', 'asc')
  }

  findMaximumToTime(pickedBreakdowns) {
    const breakdown = this._findMaximumBreakdownTimeUsingBreakdownsForOwners(
      pickedBreakdowns
    )

    return this._makeTime(breakdown.to)
  }

  _findMaximumBreakdownTimeUsingBreakdownsForOwners(pickedBreakdowns) {
    const ownersWithBreakdowns = this._generateBreakdownsForOwnersUsingPickedBreakdowns(
      pickedBreakdowns
    )

    const breakdowns = this._getAllBreakdownsWithoutOwners(ownersWithBreakdowns)

    return first(this._orderBreakdownToTimeInDescending(breakdowns))
  }

  _orderBreakdownToTimeInDescending(breakdowns) {
    return this._orderBreakdowns(breakdowns, 'to', 'desc')
  }

  _getAllBreakdownsWithoutOwners(ownersWithBreakdowns) {
    return flatMap(ownersWithBreakdowns, 'breakdowns')
  }

  _orderBreakdowns(breakdowns, field, order) {
    return orderBy(breakdowns, [field], [order])
  }

  _makeTime(time) {
    return moment.utc(time)
  }

  get totalSelectedDurationsInUnits() {
    return this.totalDuration
  }

  _generateBreakdownsForOwnersUsingPickedBreakdowns(pickedBreakdowns) {
    return this.items.map(draftedLeave =>
      this._generateSuitableBreakdown(pickedBreakdowns, draftedLeave)
    )
  }

  _generateSuitableBreakdown(pickedBreakdowns, draftedLeave) {
    return {
      owner_id: draftedLeave.owner.getKey(),
      breakdowns: draftedLeave.breakdowns.map(breakdown => {
        const pickedBreakdown = pickedBreakdowns.find(pickedBreakdown =>
          pickedBreakdown.isSameDate(breakdown.date)
        )

        const duration = pickedBreakdown.activeDuration

        return {
          type: breakdown.type,
          ...duration.toJson(),
        }
      }),
    }
  }
}
