<script>
import moment from 'moment-timezone'
import documentationUrls from '@/documentations/documentation-urls'

export default {
  name: 'BaseFooter',

  computed: {
    privacyUrl() {
      return documentationUrls.privacyUrl
    },

    termsUrl() {
      return documentationUrls.termsUrl
    },

    cookieUrl() {
      return documentationUrls.cookieUrl
    },

    currentYear() {
      return moment().format('YYYY')
    },

    helpCenterUrl() {
      return documentationUrls.helpCenterUrl
    },
  },

  render() {
    return this.$scopedSlots.default({
      privacyUrl: this.privacyUrl,
      termsUrl: this.termsUrl,
      cookieUrl: this.cookieUrl,
      currentYear: this.currentYear,
      helpCenterUrl: this.helpCenterUrl,
    })
  },
}
</script>
