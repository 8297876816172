<template>
  <div class="overtime-breakdown-item">
    <OvertimeBreakdown
      :minutes-per-working-day="minutesPerWorkingDay"
      :breakdown="breakdown"
      @breakdown-unit-changed="breakdownUnitChanged"
      @breakdown-amount-changed="breakdownAmountChanged"
    >
      <template
        v-slot:default="{
          overtimeUnits,
          selectedOvertimeUnit,
          changeAmount,
          changeOvertimeUnit,
          displayAmount,
        }"
      >
        <div class="tw-flex tw-items-center tw-justify-between tw-space-x-20">
          <RequestBreakdownDate :date="breakdown.date" />

          <div class="tw-flex tw-items-center tw-space-x-2">
            <input
              :value="displayAmount"
              min="0"
              step="any"
              class="form-control border-gray-350 tw-w-24"
              type="number"
              @input="changeAmount"
            />

            <VSelect
              id="overtime_unit_type"
              :value="selectedOvertimeUnit"
              class="tw-w-24"
              :options="overtimeUnits"
              :multiple="false"
              :searchable="false"
              :show-labels="false"
              :allow-empty="false"
              track-by="key"
              label="label"
              placeholder=""
              @input="changeOvertimeUnit"
            >
            </VSelect>
          </div>
        </div>
      </template>
    </OvertimeBreakdown>
  </div>
</template>

<script>
import vSelect from 'vue-multiselect'
import RequestBreakdownDate from '@/components/requests/RequestBreakdownDate'
import OvertimeBreakdown from '@/components/requests/overtime/OvetimeBreakdown'

export default {
  name: 'OvertimeBreakdownInput',

  components: {
    VSelect: vSelect,
    RequestBreakdownDate,
    OvertimeBreakdown,
  },

  props: {
    breakdown: {
      type: Object,
      required: true,
    },

    minutesPerWorkingDay: {
      type: Number,
      required: true,
    },
  },

  methods: {
    breakdownUnitChanged(breakdown) {
      this.$emit('breakdown-unit-changed', breakdown)
    },

    breakdownAmountChanged(breakdown) {
      this.$emit('breakdown-amount-changed', breakdown)
    },
  },
}
</script>
