export default class OvertimeDetailsMicrocopy {
  constructor(overtime, viewer, company) {
    this.overtime = overtime
    this.viewer = viewer
    this.company = company
  }

  get microcopy() {
    if (this.overtime.isRequested && this.overtime.canApprove()) {
      return `Approving this request will amend ${this.company.toilTitle} for **${this.overtime.owner.full_name}**`
    }

    if (
      (this.overtime.isRequested || this.overtime.isApproved) &&
      this.overtime.canCancel()
    ) {
      return this._makeCancelText()
    }

    return ''
  }

  _makeCancelText() {
    if (this._ownerIsViewer()) {
      return `Cancelling this request won’t entitle you to any ${this.company.toilTitle}`
    }

    return `Cancelling this request will remove ${this.company.toilTitle} for **${this.overtime.owner.full_name}**`
  }

  _ownerIsViewer() {
    return this.overtime.owner.id === this.viewer.id
  }
}
